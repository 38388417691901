"use strict";

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

require("core-js/modules/es.array.concat");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var api = _interopRequireWildcard(require("@/api/contact"));

var _utils = require("@/helpers/utils");

var _adminListMixin = _interopRequireDefault(require("@/mixins/adminListMixin"));

var _DialogDetail = _interopRequireDefault(require("@/components/DialogDetail"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'ContactList',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  components: {
    DialogDetail: _DialogDetail.default
  },
  // 6. Composition
  // --
  mixins: [_adminListMixin.default],
  // 7. Interface (interface du composant)
  // --
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      api: api,
      columns: {
        agence_name: {
          key: 0,
          prop: 'agence_name',
          label: 'Agence',
          width: 200
        },
        lastname: {
          key: 1,
          prop: 'lastname',
          label: 'Nom',
          width: 200,
          dialog: {
            is_strong: true
          }
        },
        firstname: {
          key: 2,
          prop: 'firstname',
          label: 'Prénom',
          width: 300,
          dialog: {
            is_strong: true
          }
        },
        mail: {
          key: 3,
          prop: 'mail',
          label: 'Mail',
          width: 300
        },
        phone: {
          key: 4,
          prop: 'phone',
          label: 'Tél.',
          width: 200
        },
        type: {
          key: 5,
          prop: 'type',
          label: 'Type',
          datatable: {
            is_specific: true
          },
          dialog: {
            format: _utils.getLabelContactType
          }
        }
      },
      filters: [{
        value: '',
        prop: ['lastname', 'firstname', 'mail', 'phone', 'type']
      }]
    };
  },
  // 9. Évènements
  // --
  // 10. Propriétés non réactives
  // --
  methods: {
    getHandleDeleteMessage: function getHandleDeleteMessage(index, row) {
      console.log('[ContactList] getHandleDeleteMessage');
      return "Voulez-vous supprimer le contact ".concat(row.firstname, " ").concat(row.lastname, " ?");
    }
  } // 11. Rendu
  // --

};
exports.default = _default;