"use strict";

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

require("core-js/modules/es.array.filter");

require("core-js/modules/es.array.for-each");

require("core-js/modules/es.function.name");

require("core-js/modules/web.dom-collections.for-each");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var api = _interopRequireWildcard(require("@/api/societe"));

var apiUser = _interopRequireWildcard(require("@/api/user"));

var _adminListMixin = _interopRequireDefault(require("@/mixins/adminListMixin"));

var _DialogDetail = _interopRequireDefault(require("@/components/DialogDetail"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'SocieteList',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  components: {
    DialogDetail: _DialogDetail.default
  },
  // 6. Composition
  // --
  mixins: [_adminListMixin.default],
  // 7. Interface (interface du composant)
  // --
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      api: api,
      columns: {
        logo: {
          key: 0,
          prop: 'logo',
          label: 'Logo',
          width: 250,
          datatable: {
            is_specific: true
          },
          dialog: {
            hidden: true
          }
        },
        logo_small: {
          key: 1,
          prop: 'logo_small',
          label: 'Logo (petite taille)',
          width: 200,
          datatable: {
            is_specific: true
          },
          dialog: {
            hidden: true
          }
        },
        name: {
          key: 2,
          prop: 'name',
          label: 'Nom',
          dialog: {
            is_strong: true
          }
        },
        shortname: {
          key: 3,
          prop: 'shortname',
          label: 'Sigle'
        },
        actif: {
          key: 4,
          prop: 'actif',
          label: 'Actif'
        },
        nbusers: {
          key: 5,
          prop: 'nbusers',
          label: 'Nb Utilisateurs'
        }
      },
      users: []
    };
  },
  // 9. Évènements
  // --
  // 10. Propriétés non réactives
  // --
  methods: {
    getHandleDeleteMessage: function getHandleDeleteMessage(index, row) {
      console.log('[SocieteList] getHandleDeleteMessage');
      return "Voulez-vous supprimer la soci\xE9t\xE9 ".concat(row.name, " ?");
    },
    loadData: function loadData() {
      var _this = this;

      console.log('[SocieteList] loadData');
      this.$loader(true);
      this.data = [];
      this.users = [];
      apiUser.getAll().then(function (results) {
        _this.users = results;

        _this.api.getAll().then(function (results) {
          results.forEach(function (row) {
            row.nbusers = _this.nbUsers(row.id);
            row.actif = row.actif === 1 ? '✓' : '✗';

            _this.data.push(row);
          });
          _this.dataLoaded = true; // this.data.total = results.length

          _this.$loader(false);
        }).catch(function (error) {
          _this.$message(error);

          _this.$loader(false);
        });
      });
    },
    nbUsers: function nbUsers(idSociete) {
      return this.users.filter(function (x) {
        return x.id_societe === idSociete;
      }).length;
    }
  } // 11. Rendu
  // --

};
exports.default = _default;