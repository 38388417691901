"use strict";

var _interopRequireWildcard = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.number.constructor");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/objectSpread2"));

var _vuex = require("vuex");

var validate = _interopRequireWildcard(require("@/helpers/validate"));

var apiUser = _interopRequireWildcard(require("@/api/user"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'UserProfil',
  data: function data() {
    var _this = this;

    var validatePwd2 = function validatePwd2(rule, value, callback) {
      var err = validate.ConfirmPassword(_this.form2Data.pwd1, value);

      if (err) {
        callback(err);
      }

      callback();
    };

    return {
      form1Data: {
        firstname: '',
        lastname: ''
      },
      form2Data: {
        pwd1: '',
        pwd2: ''
      },

      /*
      descForm1: {
        firstname: { label: 'Prénom', type: 'string', required: true, min: 3, max: 50, message: 'Longueur entre 3 et 50 caractères' },
        lastname: { label: 'Nom', type: 'string', required: true, min: 3, max: 50, message: 'Longueur entre 3 et 50 caractères' }
      },
      */
      dialogForm1Visible: false,
      dialogForm2Visible: false,
      rules1: {
        firstname: [{
          required: true,
          min: 3,
          max: 50,
          message: 'Longueur entre 3 et 50 caractères',
          trigger: 'change'
        }],
        lastname: [{
          required: true,
          min: 3,
          max: 50,
          message: 'Longueur entre 3 et 50 caractères',
          trigger: 'change'
        }]
      },
      rules2: {
        pwd1: [{
          required: true,
          validator: validate.Password,
          trigger: 'change'
        }],
        pwd2: [{
          required: true,
          validator: validatePwd2,
          trigger: 'change'
        }]
      }
    };
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['user'])), {}, {
    notification: {
      get: function get() {
        return Number(this.user.notification_mail) || 0;
      },
      set: function set(value) {
        this.user.notification_mail = value;
      }
    }
  }),
  methods: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapMutations)('user', ['SET_USER'])), {}, {
    resetFieldsForm1: function resetFieldsForm1() {
      this.$refs.dialogForm1.resetFields();
      this.dialogForm1Visible = false;
    },
    resetFieldsForm2: function resetFieldsForm2() {
      this.$refs.dialogForm2.resetFields();
      this.dialogForm2Visible = false;
    },
    validateForm1: function validateForm1() {
      var _this2 = this;

      this.$refs.dialogForm1.validate(function (valid) {
        if (valid) {
          var data = {
            firstname: _this2.form1Data.firstname,
            lastname: _this2.form2Data.lastname
          };
          apiUser.update(_this2.user.id, data).then(function (results) {
            _this2.$message('Mise à jour effectuée avec succès');

            var user = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, _this2.user), {}, {
              firstname: _this2.form1Data.firstname,
              lastname: _this2.form1Data.lastname
            });

            _this2.SET_USER(user);

            _this2.resetFieldsForm1();
          }).catch(function (error) {
            _this2.$message(error);
          });
          _this2.dialogForm1Visible = false;
        }
      });
    },
    validateForm2: function validateForm2() {
      var _this3 = this;

      this.$refs.dialogForm2.validate(function (valid) {
        if (valid) {
          var data = {
            password: _this3.form2Data.pwd1
          };
          apiUser.update(_this3.user.id, data).then(function (results) {
            _this3.$message('Mise à jour du mot de passe effectuée avec succès');

            _this3.resetFieldsForm2();
          }).catch(function (error) {
            _this3.$message(error);
          });
          _this3.dialogForm2Visible = false;
        }
      });
    },
    handleChange: function handleChange() {
      var _this4 = this;

      var data = {
        notification_mail: this.notification
      };
      apiUser.update(this.user.id, data).then(function (results) {
        _this4.$message('Mise à jour effectuée avec succès');
      }).catch(function (error) {
        _this4.$message(error);
      });
    },
    handleDisplayForm1: function handleDisplayForm1() {
      this.dialogForm1Visible = true;
      this.form1Data = {
        firstname: this.user.firstname,
        lastname: this.user.lastname
      };
    },
    handleDisplayForm2: function handleDisplayForm2() {
      this.dialogForm2Visible = true;
    }
  })
};
exports.default = _default;