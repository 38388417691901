var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("button-return"),
      _c(
        "el-row",
        { staticStyle: { margin: "10px 0 10px 0" } },
        [
          _c("el-col", { attrs: { span: 10 } }, [
            _c("h1", [_vm._v(_vm._s(_vm.title))])
          ]),
          _c(
            "el-col",
            { staticStyle: { "text-align": "right" }, attrs: { span: 14 } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-check",
                    size: "mini",
                    type: "primary"
                  },
                  on: { click: _vm.validateForm }
                },
                [_vm._v(" Enregistrer ")]
              ),
              _c(
                "el-button",
                { attrs: { size: "mini" }, on: { click: _vm.resetForm } },
                [_vm._v(" Reset ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-form",
        {
          ref: "ElForm",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "220px",
            size: "mini"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Société", prop: "id_societe" } },
            [
              _c("societe-select-list", {
                key: "id_societe_" + _vm.formData.id_societe,
                attrs: { "id-societe-selected": _vm.formData.id_societe },
                on: {
                  "update:idSocieteSelected": function($event) {
                    return _vm.$set(_vm.formData, "id_societe", $event)
                  },
                  "update:id-societe-selected": function($event) {
                    return _vm.$set(_vm.formData, "id_societe", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Prénom", prop: "firstname" } },
            [
              _c("el-input", {
                attrs: { autocomplete: "off", maxlength: "50", type: "string" },
                model: {
                  value: _vm.formData.firstname,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "firstname", $$v)
                  },
                  expression: "formData.firstname"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Nom", prop: "lastname" } },
            [
              _c("el-input", {
                attrs: { autocomplete: "off", maxlength: "50", type: "string" },
                model: {
                  value: _vm.formData.lastname,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "lastname", $$v)
                  },
                  expression: "formData.lastname"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Email", prop: "email" } },
            [
              _c(
                "el-input",
                {
                  directives: [
                    {
                      name: "mask",
                      rawName: "v-mask",
                      value: { alias: "email" },
                      expression: "{ alias: 'email' }"
                    }
                  ],
                  staticStyle: { width: "500px" },
                  attrs: {
                    autocomplete: "off",
                    maxlength: "256",
                    type: "string"
                  },
                  model: {
                    value: _vm.formData.email,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "email", $$v)
                    },
                    expression: "formData.email"
                  }
                },
                [
                  _c("i", {
                    staticClass: "el-input__icon el-icon-message",
                    attrs: { slot: "prefix" },
                    slot: "prefix"
                  })
                ]
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Actif", prop: "enabled" } },
            [
              _c("el-switch", {
                attrs: {
                  "active-value": Number(1),
                  "inactive-value": Number(0),
                  "active-color": "#13ce66",
                  "inactive-color": "#ff4949",
                  "active-text": "Oui",
                  "inactive-text": "Non"
                },
                model: {
                  value: _vm.formData.enabled,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "enabled", $$v)
                  },
                  expression: "formData.enabled"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Rôle", prop: "key_role" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    autocomplete: "off",
                    clearable: "",
                    filterable: "",
                    placeholder: "Selection",
                    "value-key": "formData.key_role"
                  },
                  model: {
                    value: _vm.formData.key_role,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "key_role", $$v)
                    },
                    expression: "formData.key_role"
                  }
                },
                _vm._l(_vm.roles, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.key_role }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c("el-divider"),
          _c(
            "el-form-item",
            { attrs: { label: "Mot de passe", prop: "pwd1" } },
            [
              _c(
                "el-input",
                {
                  ref: "pwd1",
                  attrs: {
                    type: _vm.passwordType,
                    autocomplete: "off",
                    maxlength: "50",
                    tabindex: "1"
                  },
                  model: {
                    value: _vm.formData.pwd1,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "pwd1", $$v)
                    },
                    expression: "formData.pwd1"
                  }
                },
                [
                  _c("i", {
                    staticClass: "el-input__icon el-icon-key",
                    attrs: { slot: "prefix" },
                    slot: "prefix"
                  })
                ]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.genPassword }
                },
                [_vm._v(" Générer ")]
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Confirmation mot de passe", prop: "pwd2" } },
            [
              _c(
                "el-input",
                {
                  attrs: {
                    type: _vm.passwordType,
                    autocomplete: "off",
                    tabindex: "2",
                    maxlength: "50"
                  },
                  model: {
                    value: _vm.formData.pwd2,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "pwd2", $$v)
                    },
                    expression: "formData.pwd2"
                  }
                },
                [
                  _c("i", {
                    staticClass: "el-input__icon el-icon-key",
                    attrs: { slot: "prefix" },
                    slot: "prefix"
                  })
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }