"use strict";

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.object.to-string");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard"));

var defaultActiveMenu = '/dashboard';
var routes = [{
  path: '/intervention',
  name: 'Intervention',
  order: 22,
  meta: {
    title: 'Gestion des interventions',
    icon: 'site'
  },
  redirect: {
    name: 'InterventionModule'
  },
  hidden: true,
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/layout'));
    });
  },
  children: [// INTERVENTIONS
  // ----------------
  // Liste des Interventions
  {
    path: 'list',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/app/intervention/InterventionModule'));
      });
    },
    name: 'InterventionModule',
    meta: {
      title: 'Liste des Interventions',
      activeMenu: defaultActiveMenu
    }
  }, // Fiche d'une Intervention
  {
    path: ':interventionId(\\d+)',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/app/intervention/InterventionIndex'));
      });
    },
    name: 'InterventionIndex',
    redirect: {
      name: 'InterventionView'
    },
    meta: {
      title: 'Fiche d\'une intervention',
      activeMenu: defaultActiveMenu
    },
    children: [// Contenu par défaut
    {
      path: '',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/app/intervention/InterventionView'));
        });
      },
      name: 'InterventionView',
      meta: {
        title: 'Vue détaillée d\'une intervention',
        activeMenu: defaultActiveMenu
      }
    }
    /*
    // Liste des Documents d'une intervention
    {
      path: 'documents',
      hidden: true,
      component: () => import('@/views/app/document/DocumentModule'),
      name: 'DocumentInterventionModule',
      meta: { title: 'Documents d\'une intervention', activeMenu: defaultActiveMenu }
    },
    // Liste des Suivis d'Intervention
    {
      path: 'suivi',
      hidden: true,
      component: () => import('@/views/app/intervention/InterventionSuiviList'),
      name: 'InterventionSuiviList',
      meta: { title: 'Suivis d\'intervention', activeMenu: defaultActiveMenu }
    }
    */
    ]
  }]
}];
var _default = routes;
exports.default = _default;