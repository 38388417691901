var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", [
        _vm._v(
          "Ticket " +
            _vm._s(
              _vm.ticket.case_referenceid ? _vm.ticket.case_referenceid : "n/c"
            ) +
            " - " +
            _vm._s(_vm.ticket.case_status) +
            " - " +
            _vm._s(_vm.ticket.case_stage)
        )
      ]),
      _c(
        "el-card",
        { staticClass: "app-bloc-info" },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { xs: 24, md: 2 } }, [
                        _c("strong", [_vm._v("Référence ID")])
                      ]),
                      _c("el-col", { attrs: { xs: 24, md: 22 } }, [
                        _c("strong", [
                          _vm._v(_vm._s(_vm.ticket.case_referenceid))
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { xs: 24, md: 2 } }, [
                        _c("strong", [_vm._v("Créé le")])
                      ]),
                      _c("el-col", { attrs: { xs: 24, md: 22 } }, [
                        _c("strong", [
                          _c("i", { staticClass: "el-icon-date" }),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("toDateTime")(
                                  _vm.ticket.case_createddate
                                )
                              )
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { xs: 24, md: 2 } }, [
                        _c("strong", [_vm._v("Assigné à")])
                      ]),
                      _c("el-col", { attrs: { xs: 24, md: 22 } }, [
                        _c("strong", [_vm._v(_vm._s(_vm.ticket.user_assigned))])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { xs: 24, md: 2 } }, [
                        _vm._v("Description")
                      ]),
                      _c("el-col", { attrs: { xs: 24, md: 22 } }, [
                        _vm._v(_vm._s(_vm.ticket.case_description))
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { xs: 24, md: 2 } }, [
                        _vm._v("Note")
                      ]),
                      _c("el-col", { attrs: { xs: 24, md: 22 } }, [
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.ln2br(_vm.ticket.case_problemnote)
                            )
                          }
                        })
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { xs: 24, md: 2 } }, [
                        _vm._v("Statut")
                      ]),
                      _c("el-col", { attrs: { xs: 24, md: 22 } }, [
                        _vm._v(_vm._s(_vm.ticket.case_status))
                      ])
                    ],
                    1
                  ),
                  _vm.ticket.porte
                    ? _c(
                        "el-row",
                        [
                          _c("el-col", { attrs: { xs: 24, md: 2 } }, [
                            _vm._v("Porte")
                          ]),
                          _c("el-col", { attrs: { xs: 24, md: 22 } }, [
                            _vm._v(_vm._s(_vm.ticket.porte.door_matricule))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { xs: 24, md: 2 } }, [
                        _vm._v("Nb historiques")
                      ]),
                      _c("el-col", { attrs: { xs: 24, md: 22 } }, [
                        _vm._v(_vm._s(_vm.ticket.nb_historiques))
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { xs: 24, md: 2 } }, [
                        _vm._v("Nb documents")
                      ]),
                      _c("el-col", { attrs: { xs: 24, md: 22 } }, [
                        _vm._v(_vm._s(_vm.ticket.nb_documents))
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }