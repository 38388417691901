"use strict";

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

require("core-js/modules/es.function.name");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var api = _interopRequireWildcard(require("@/api/agence"));

var _utils = require("@/helpers/utils");

var _adminListMixin = _interopRequireDefault(require("@/mixins/adminListMixin"));

var _DialogDetail = _interopRequireDefault(require("@/components/DialogDetail"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'AgenceList',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  components: {
    DialogDetail: _DialogDetail.default
  },
  // 6. Composition
  // --
  mixins: [_adminListMixin.default],
  // 7. Interface (interface du composant)
  // --
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      api: api,
      columns: {
        name: {
          key: 0,
          prop: 'name',
          label: 'Nom',
          width: 200,
          dialog: {
            is_strong: true
          }
        },
        chan_description: {
          key: 1,
          prop: 'chan_description',
          label: 'Description',
          width: 200
        },
        address: {
          key: 2,
          prop: 'address',
          label: 'Addresse',
          datatable: {
            is_specific: true
          },
          dialog: {
            is_html: true,
            format: _utils.ln2br
          }
        },
        opening_time: {
          key: 3,
          prop: 'opening_time',
          label: 'Horaires',
          datatable: {
            is_specific: true
          },
          dialog: {
            is_html: true,
            format: _utils.ln2br
          }
        },
        nb_contacts: {
          key: 4,
          prop: 'nb_contacts',
          label: 'Nb contacts',
          width: 150
        }
      },
      filters: [{
        value: '',
        prop: ['name', 'chan_description']
      }]
    };
  },
  // 9. Évènements
  // --
  // 10. Propriétés non réactives
  // --
  methods: {
    getHandleDeleteMessage: function getHandleDeleteMessage(index, row) {
      console.log('[SocieteList] getHandleDeleteMessage');
      return "Voulez-vous supprimer l'agence ".concat(row.name, " ?");
    }
  } // 11. Rendu
  // --

};
exports.default = _default;