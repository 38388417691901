// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n", ""]);
// Exports
exports.locals = {
	"LogoBg": "#2b2f3a",
	"LogoText": "#fff",
	"NavbarBg": "#545c64",
	"NavbarMenuText": "#ffffff",
	"SidebarWidth": "210px",
	"SidebarMenuBg": "#1a1a1a",
	"SidebarMenuHover": "#dcfa00",
	"SidebarMenuText": "#fff",
	"SidebarMenuTextHover": "#1a1a1a",
	"SidebarMenuActiveText": "#dcfa00",
	"SidebarSubMenuBg": "#3a3a3a",
	"SidebarSubMenuHover": "#dcfa00",
	"SidebarSubMenuText": "#3a3a3a",
	"SidebarSubMenuTextHover": "#3a3a3a",
	"SidebarSubMenuActiveText": "#dcfa00"
};
module.exports = exports;
