"use strict";

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.find");

require("core-js/modules/es.array.for-each");

require("core-js/modules/es.array.includes");

require("core-js/modules/es.array.map");

require("core-js/modules/es.array.some");

require("core-js/modules/es.object.keys");

require("core-js/modules/es.object.values");

require("core-js/modules/es.string.includes");

require("core-js/modules/web.dom-collections.for-each");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _typeof2 = _interopRequireDefault(require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/typeof"));

var _objectSpread2 = _interopRequireDefault(require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/objectSpread2"));

var _vuex = require("vuex");

var _utils = require("@/helpers/utils");

var _SiteList = _interopRequireDefault(require("../site/SiteList"));

var _PorteAllList = _interopRequireDefault(require("../porte/PorteAllList"));

var _InterventionAllList = _interopRequireDefault(require("../intervention/InterventionAllList"));

var _DocumentAllList = _interopRequireDefault(require("../document/DocumentAllList"));

var _jsCookie = _interopRequireDefault(require("js-cookie"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'SearchModule',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  components: {
    SiteList: _SiteList.default,
    PorteAllList: _PorteAllList.default,
    InterventionAllList: _InterventionAllList.default,
    DocumentAllList: _DocumentAllList.default
  },
  // 6. Composition
  // --
  // 7. Interface
  // --
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      dataLoaded: false,
      externalsFilters: {
        porte: {},
        site: {},
        intervention: {},
        document: {}
      },
      filters: {
        // Site :
        //   - Libellé
        //   - N° de compte comptable / compteur payeur
        //   - Code postal
        //   - Ville
        site: {
          libelle: '',
          numero: '',
          cp: '',
          ville: ''
        },
        // Porte :
        //   - Matricule
        //   - Type
        //   - Marque
        //   - Lieu
        porte: {
          matricule: '',
          type: '',
          marque: '',
          lieu: ''
        },
        // Intervention :
        //   - numéro de dossier
        //   - Type
        intervention: {
          numero: '',
          type: '',
          typePorte: '',
          dateDebut: '',
          dateFin: ''
        },
        // Document :
        //   - Numéro : dans le nom du fichier
        //   - Type
        document: {
          numero: '',
          type: '',
          dateDebut: '',
          dateFin: ''
        }
      },
      isSearching: false,
      partialsDataToLoad: {
        site: false,
        porte: false,
        intervention: false,
        document: false
      },
      showFilters: true
    };
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['user'])), (0, _vuex.mapGetters)('crm', ['getPorteMarqueList', 'getPorteTypeList', 'getPorteLieuList', 'getInterventionTypeList', 'getDocumentTypeList'])), {}, {
    filterExists: function filterExists() {
      var _this = this;

      var props = Object.keys(this.filters);
      var filterExists = false;
      props.some(function (prop) {
        filterExists = _this.checkFilterExistsByProp(prop);
        return filterExists === true;
      });
      return filterExists;
    },
    // Filtre global par Marque de porte
    filterPorteMarque: function filterPorteMarque() {
      return this.buildSelectList({
        data: this.getPorteMarqueList,
        text: 'door_marque',
        value: 'door_marque'
      });
    },
    // Filtre global par Type de porte
    filterPorteType: function filterPorteType() {
      return this.buildSelectList({
        data: this.getPorteTypeList,
        text: 'door_type',
        value: 'door_type'
      });
    },
    // Filtre global par Lieu d'installation de porte
    filterPorteLieu: function filterPorteLieu() {
      return this.buildSelectList({
        data: this.getPorteLieuList,
        text: 'addr_city',
        value: 'addr_city'
      });
    },
    // Filtre global par Type d'intervention
    filterInterventionType: function filterInterventionType() {
      return this.buildSelectList({
        data: this.getInterventionTypeList,
        text: 'oppo_service',
        value: 'oppo_service_value'
      });
    },
    // Filtre global par Type de document
    filterDocumentType: function filterDocumentType() {
      return this.buildSelectList({
        data: this.getDocumentTypeList,
        text: 'libr_type',
        value: 'libr_type_value'
      });
    }
  }),
  // 9. Évènements
  // --
  beforeMount: function beforeMount() {
    this.cleanSearchDatas(); // Données stockées dans le cookie

    var cookieData = _jsCookie.default.getJSON('global.search.filters') || null; // Récupération des filtres depuis un cookie

    if (cookieData) {
      console.log('cookieFilters', cookieData.filters, (0, _typeof2.default)(cookieData.filters));
      this.filters = cookieData.filters;
      this.handleFilterData();
    }
  },
  mounted: function mounted() {
    this.loadPorteMarqueList();
    this.loadPorteTypeList();
    this.loadPorteLieuList();
    this.loadInterventionTypeList();
    this.loadDocumentTypeList();
    this.forceSiteReloading();
  },
  beforeRouteLeave: function beforeRouteLeave(to, from, next) {
    // Suppression du cookie mémorisant les filtres
    // if (['VenteCreance.Synthese.CreancesIndex'].includes(from.name)) {
    // Cookies.remove('global.search.filtersValues', { path: '' })
    // }
    next();
  },
  watch: {
    'filterExists': function filterExists(newVal, oldVal) {
      if (oldVal === true && newVal === false) {
        this.dataLoaded = false;
        this.initPartialsDataToLoad();
      }
    }
  },
  // 10. Propriétés non réactives
  // --
  methods: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapActions)('crm', [// 'getAllDataByFilters',
  'loadPorteMarqueList', 'loadPorteTypeList', 'loadPorteLieuList', 'loadInterventionTypeList', 'loadDocumentTypeList', 'cleanSearchDatas', 'forceSiteReloading'])), {}, {
    buildSelectList: function buildSelectList(payload) {
      var data = payload.data,
          text = payload.text,
          value = payload.value;

      if (data.length === 0 || !text || !value) {
        return [];
      }

      var list = [];
      data.forEach(function (row) {
        // Initialisation des valeurs du filtre
        if (!list.find(function (x) {
          return x.value === row[value];
        })) {
          var item = {
            text: row[text] ? row[text] : 'n/c',
            value: row[value]
          };
          list.push(item);
        }
      });
      list.sort(_utils.sortSelectItems);
      return list;
    },
    checkFilterExistsByProp: function checkFilterExistsByProp(prop) {
      var filterExists = false;

      if (Object.keys(this.filters).includes(prop)) {
        var filters = Object.values(this.filters[prop]).map(function (item) {
          return {
            value: item
          };
        });
        filters.forEach(function (x, index) {
          // console.log(index + '# typeof', x.value, typeof x.value)
          switch ((0, _typeof2.default)(x.value)) {
            case 'string':
              if (x.value !== null && x.value !== '') {
                filterExists = true;
              }

              break;

            case 'number':
              if (x.value !== null && x.value > 0) {
                filterExists = true;
              }

              break;

            case 'object':
              if (x.value && x.value.length > 0) {
                filterExists = true;
              }

              break;
          }
        });
      }

      return filterExists;
    },
    initFiltersByProp: function initFiltersByProp(prop) {
      var _this2 = this;

      if (Object.keys(this.filters).includes(prop)) {
        Object.keys(this.filters[prop]).forEach(function (x, index) {
          _this2.filters[prop][x] = '';
        });
      }
    },
    handleShowFilters: function handleShowFilters() {
      this.showFilters = !this.showFilters;
    },
    handleFilterDataTest: function handleFilterDataTest(event) {
      console.log('ENTER !!!', event);
    },
    handleFilterData: function handleFilterData() {
      console.log('[SearchModule] Rechercher : Filtrer les données'); // Réinitialisation de la visibilité des modules

      this.initPartialsDataToLoad(); // Tous filtres confondus

      var user = this.user; // Filtres pour les portes

      if (this.checkFilterExistsByProp('porte')) {
        this.partialsDataToLoad.porte = true;
        this.externalsFilters.porte = (0, _objectSpread2.default)({
          user: user
        }, this.filters.porte);
      } // Filtres pour les sites


      if (this.checkFilterExistsByProp('site')) {
        this.partialsDataToLoad.site = true;
        this.externalsFilters.site = (0, _objectSpread2.default)({
          user: user
        }, this.filters.site);
      } // Filtres pour les interventions


      if (this.checkFilterExistsByProp('intervention')) {
        this.partialsDataToLoad.intervention = true;
        this.externalsFilters.intervention = (0, _objectSpread2.default)({
          user: user
        }, this.filters.intervention);
      } // Filtres pour les documents


      if (this.checkFilterExistsByProp('document')) {
        this.partialsDataToLoad.document = true;
        this.externalsFilters.document = (0, _objectSpread2.default)({
          user: user
        }, this.filters.document);
      }

      this.dataLoaded = Object.values(this.partialsDataToLoad).some(function (item) {
        return item === true;
      }); // Mémorisation des filtres dans un cookie

      var filters = this.filters;

      _jsCookie.default.set('global.search.filters', {
        filters: filters
      }, {
        path: ''
      });
    },
    handleInitFilters: function handleInitFilters() {
      this.initFiltersByProp('porte');
      this.initFiltersByProp('site');
      this.initFiltersByProp('intervention');
      this.initFiltersByProp('document');

      _jsCookie.default.remove('global.search.filters', {
        path: ''
      });
    },
    initPartialsDataToLoad: function initPartialsDataToLoad() {
      var _this3 = this;

      Object.keys(this.partialsDataToLoad).map(function (prop) {
        _this3.partialsDataToLoad[prop] = false;
      }); // this.dataLoaded = false
    }
  }) // 11. Rendu
  // --

};
exports.default = _default;