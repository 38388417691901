var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", [
        _vm._v(
          "Porte " +
            _vm._s(_vm.porte.door_matricule ? _vm.porte.door_matricule : "") +
            " - " +
            _vm._s(_vm.porte.door_name ? _vm.porte.door_name : "")
        )
      ]),
      _c(
        "el-card",
        { staticClass: "app-bloc-info" },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { md: 12, xs: 24 } },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { xs: 24, md: 8 } }, [
                        _vm._v("Matricule")
                      ]),
                      _c("el-col", { attrs: { xs: 24, md: 16 } }, [
                        _vm._v(
                          _vm._s(
                            _vm.porte.door_matricule
                              ? _vm.porte.door_matricule
                              : ""
                          )
                        )
                      ])
                    ],
                    1
                  ),
                  _vm.porte.site
                    ? _c(
                        "el-row",
                        [
                          _c("el-col", { attrs: { xs: 24, md: 8 } }, [
                            _vm._v("Société")
                          ]),
                          _c("el-col", { attrs: { xs: 24, md: 16 } }, [
                            _vm._v(
                              _vm._s(
                                _vm.porte.site.comp_name
                                  ? _vm.porte.site.comp_name
                                  : ""
                              )
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { xs: 24, md: 8 } }, [
                        _vm._v("Marque")
                      ]),
                      _c("el-col", { attrs: { xs: 24, md: 16 } }, [
                        _vm._v(
                          _vm._s(
                            _vm.porte.door_marque ? _vm.porte.door_marque : ""
                          )
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { xs: 24, md: 8 } }, [
                        _vm._v("Configuration")
                      ]),
                      _c("el-col", { attrs: { xs: 24, md: 16 } }, [
                        _vm._v(
                          _vm._s(
                            _vm.porte.door_config ? _vm.porte.door_config : ""
                          )
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { xs: 24, md: 8 } }, [
                        _vm._v("Finition")
                      ]),
                      _c("el-col", { attrs: { xs: 24, md: 16 } }, [
                        _vm._v(
                          _vm._s(
                            _vm.porte.door_finition
                              ? _vm.porte.door_finition
                              : ""
                          )
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { xs: 24, md: 8 } }, [
                        _vm._v("Etiquette présente")
                      ]),
                      _c("el-col", { attrs: { xs: 24, md: 16 } }, [
                        _vm.porte.door_label === "Y"
                          ? _c(
                              "span",
                              { staticStyle: { color: "darkgreen" } },
                              [_vm._v("✓")]
                            )
                          : _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("✘")
                            ])
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { md: 12, xs: 24 } },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { xs: 24, md: 8 } }, [
                        _vm._v("Nom")
                      ]),
                      _c("el-col", { attrs: { xs: 24, md: 16 } }, [
                        _vm._v(
                          _vm._s(_vm.porte.door_name ? _vm.porte.door_name : "")
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { xs: 24, md: 8 } }, [
                        _vm._v("Famille")
                      ]),
                      _c("el-col", { attrs: { xs: 24, md: 16 } }, [
                        _vm._v(
                          _vm._s(
                            _vm.porte.door_famille ? _vm.porte.door_famille : ""
                          )
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { xs: 24, md: 8 } }, [
                        _vm._v("Type de mécanisme")
                      ]),
                      _c("el-col", { attrs: { xs: 24, md: 16 } }, [
                        _vm._v(
                          _vm._s(_vm.porte.door_type ? _vm.porte.door_type : "")
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { xs: 24, md: 8 } }, [
                        _vm._v("Vantaux")
                      ]),
                      _c("el-col", { attrs: { xs: 24, md: 16 } }, [
                        _vm._v(
                          _vm._s(_vm.porte.door_vtx ? _vm.porte.door_vtx : "")
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { xs: 24, md: 8 } }, [
                        _vm._v("Code RAL")
                      ]),
                      _c("el-col", { attrs: { xs: 24, md: 16 } }, [
                        _vm._v(
                          _vm._s(_vm.porte.coderal ? _vm.porte.coderal : "")
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { xs: 24, md: 8 } }, [
                        _vm._v("Année de fabrication")
                      ]),
                      _c("el-col", { attrs: { xs: 24, md: 16 } }, [
                        _vm._v(
                          _vm._s(
                            _vm.porte.door_productionyear
                              ? _vm.porte.door_productionyear
                              : ""
                          )
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }