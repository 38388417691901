var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dataLoaded
    ? _c(
        "div",
        [
          _c("h1", [_vm._v("Gestion des sociétés")]),
          _c(
            "el-row",
            { attrs: { slot: "tool", gutter: 10 }, slot: "tool" },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-circle-plus-outline",
                        size: "mini",
                        type: "primary"
                      },
                      on: {
                        click: function($event) {
                          return _vm.$router.push({ name: "SocieteNew" })
                        }
                      }
                    },
                    [_vm._v(" Nouvelle société ")]
                  ),
                  _vm.multipleSelection.length > 0
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-delete",
                            size: "mini",
                            type: "danger"
                          },
                          on: { click: _vm.handleDeleteSelection }
                        },
                        [_vm._v(" Supprimer la sélection ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "data-tables",
            {
              ref: "dataTable",
              attrs: {
                data: _vm.data,
                layout: _vm.datatable.layout,
                "pagination-props": _vm.datatable.paginationProps,
                "table-props": Object.assign({}, _vm.datatable.tableProps, {
                  defaultSort: { prop: "name", order: "ascending" }
                }),
                total: _vm.total
              },
              on: {
                "row-click": function($event) {
                  if ($event.target !== $event.currentTarget) {
                    return null
                  }
                  return _vm.handleCurrentChange($event)
                },
                "selection-change": _vm.handleSelectionChange
              }
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "45" }
              }),
              _c("el-table-column", {
                attrs: { width: "70" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("el-button", {
                            attrs: { icon: "el-icon-delete", type: "text" },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.handleDelete(scope.$index, scope.row)
                              }
                            }
                          }),
                          _c("el-button", {
                            attrs: { icon: "el-icon-edit", type: "text" },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.$router.push({
                                  name: "SocieteEdit",
                                  params: { id: scope.row.id }
                                })
                              }
                            }
                          })
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  419316882
                )
              }),
              _c("el-table-column", {
                key: _vm.columns.logo.key,
                attrs: {
                  label: _vm.columns.logo.label,
                  prop: _vm.columns.logo.prop,
                  width: _vm.colWidth("logo"),
                  sortable: "custom"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          scope.row.logo
                            ? _c(
                                "div",
                                [
                                  _c("el-image", {
                                    staticStyle: { height: "50px" },
                                    attrs: { src: scope.row.logo, fit: "fill" }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  4109322483
                )
              }),
              _c("el-table-column", {
                key: _vm.columns.logo_small.key,
                attrs: {
                  label: _vm.columns.logo_small.label,
                  prop: _vm.columns.logo_small.prop,
                  width: _vm.colWidth("logo_small"),
                  sortable: "custom"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          scope.row.logo_small
                            ? _c(
                                "div",
                                [
                                  _c("el-image", {
                                    staticStyle: {
                                      width: "50px",
                                      height: "50px"
                                    },
                                    attrs: {
                                      src: scope.row.logo_small,
                                      fit: "fill"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2577842606
                )
              }),
              _vm._l(_vm.genericColumns, function(item) {
                return _c("el-table-column", {
                  key: item.key,
                  attrs: {
                    label: item.label,
                    prop: item.prop,
                    width: _vm.colWidth(item.prop),
                    sortable: "custom"
                  }
                })
              })
            ],
            2
          ),
          _c("dialog-detail", {
            attrs: { data: _vm.dialogDetail, row: _vm.row }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }