var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 24, lg: { span: 12, offset: 6 } } },
            [
              _c(
                "el-row",
                { staticStyle: { margin: "10px 0 10px 0" } },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 8, lg: 12, align: "left" } },
                    [_c("h1", [_vm._v(_vm._s(_vm.title))])]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 16, lg: 12, align: "right" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-check",
                            size: "mini",
                            type: "primary"
                          },
                          on: { click: _vm.validateForm }
                        },
                        [_vm._v(" Enregistrer ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: { click: _vm.resetForm }
                        },
                        [_vm._v(" Reset ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form",
                {
                  ref: "ElForm",
                  attrs: {
                    model: _vm.formData,
                    "label-position": _vm.windowWidth < 992 ? "top" : "left",
                    "label-width": "250px",
                    size: "mini",
                    rules: _vm.rules
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "form-item",
                      attrs: { label: "Type de demande", prop: "type_demande" }
                    },
                    [
                      _c("ticket-type-demande-select-list", {
                        key: "type_demande_" + _vm.formData.type_demande,
                        attrs: { "selected-value": _vm.formData.type_demande },
                        on: {
                          "update:selectedValue": function($event) {
                            return _vm.$set(
                              _vm.formData,
                              "type_demande",
                              $event
                            )
                          },
                          "update:selected-value": function($event) {
                            return _vm.$set(
                              _vm.formData,
                              "type_demande",
                              $event
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("el-input", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showField.autreTypeDemande,
                            expression: "showField.autreTypeDemande"
                          }
                        ],
                        attrs: {
                          autocomplete: "off",
                          clearable: "",
                          maxlength: "255",
                          placeholder: "Précisez ...",
                          _style: "width:250px",
                          type: "string"
                        },
                        model: {
                          value: _vm.formData.autre_type_demande,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "autre_type_demande", $$v)
                          },
                          expression: "formData.autre_type_demande"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showField.porteId,
                          expression: "showField.porteId"
                        }
                      ],
                      staticClass: "form-item",
                      attrs: { label: "Porte", prop: "porte_id" }
                    },
                    [
                      _c("porte-select-list", {
                        key: "porte_" + _vm.formData.porte_id,
                        attrs: {
                          "id-porte-selected": _vm.formData.porte_id,
                          "id-company": _vm.formData.primary_company_id
                        },
                        on: {
                          "update:idPorteSelected": function($event) {
                            return _vm.$set(_vm.formData, "porte_id", $event)
                          },
                          "update:id-porte-selected": function($event) {
                            return _vm.$set(_vm.formData, "porte_id", $event)
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Titre", prop: "titre" } },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "40",
                          placeholder: "Titre du ticket",
                          "show-word-limit": "",
                          _style: "width:750px"
                        },
                        model: {
                          value: _vm.formData.titre,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "titre", $$v)
                          },
                          expression: "formData.titre"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Description", prop: "description" } },
                    [
                      _c("el-input", {
                        attrs: {
                          autosize: { minRows: 10 },
                          maxlength: "1000",
                          placeholder: "Description détaillée",
                          "show-word-limit": "",
                          type: "textarea",
                          _style: "width:750px"
                        },
                        model: {
                          value: _vm.formData.description,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "description", $$v)
                          },
                          expression: "formData.description"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "fichier" } },
                    [
                      _c("input", {
                        ref: "fichierUploader",
                        staticStyle: { display: "none" },
                        attrs: { type: "file" },
                        on: { change: _vm.onFileChange }
                      }),
                      _c("div", { staticClass: "el-upload__tip" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("helpdesk.file.file_with_limited_weight", {
                              weight:
                                _vm.CONST.LIBRARY.MAX_UPLOAD_SIZE /
                                  1024 /
                                  1000 +
                                " Mo"
                            })
                          )
                        )
                      ]),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            size: "mini",
                            disabled: _vm.formData.fichier != null
                          },
                          on: {
                            click: function($event) {
                              return _vm.$refs.fichierUploader.click()
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "el-icon-document-add" }),
                          _vm._v(" Choisir un fichier ")
                        ]
                      ),
                      _vm.formData.fichier
                        ? _c(
                            "div",
                            { staticClass: "app-upload-info" },
                            [
                              _c("i", { staticClass: "el-icon-document" }),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.formData.fichier
                                      ? _vm.formData.fichier.name +
                                          " (" +
                                          _vm.fileSize +
                                          ")"
                                      : ""
                                  ) +
                                  " "
                              ),
                              _c(
                                "el-button",
                                {
                                  staticClass:
                                    "app-btn-delete-file el-button--danger",
                                  on: { click: _vm.onFileRemove }
                                },
                                [_c("i", { staticClass: "el-icon-close" })]
                              )
                            ],
                            1
                          )
                        : _c("span", { staticClass: "app-upload-info" }, [
                            _vm._v(" Aucun fichier choisi ")
                          ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }