"use strict";

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.object.to-string");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard"));

var routes = [{
  path: '/admin/sys-sqllog',
  name: 'AdminSysSqllog',
  order: 110,
  meta: {
    title: 'Logs Sql',
    icon: 'file'
  },
  redirect: '/admin/sys_sqllog',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/layout/empty'));
    });
  },
  children: [{
    path: '',
    // path: '/admin/sys_sqllog',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/admin/sys_sqllog/SysSqlLogList'));
      });
    },
    name: 'SysSqlLogList',
    meta: {
      title: 'Logs SQL',
      activeMenu: '/admin/sys-sqllog'
    }
  }]
}];
var _default = routes;
exports.default = _default;