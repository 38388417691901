"use strict";

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.object.to-string");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard"));

var routes = [{
  path: '/admin/agence',
  name: 'AdminAgence',
  order: 50,
  meta: {
    title: 'Gestion des agences',
    icon: 'agence'
  },
  redirect: {
    name: 'AgenceList'
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/layout/empty'));
    });
  },
  children: [{
    path: '',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/admin/agence/AgenceList'));
      });
    },
    name: 'AgenceList',
    meta: {
      title: 'Agences',
      activeMenu: '/admin/agence'
    }
  }, {
    path: 'new',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/admin/agence/AgenceNew'));
      });
    },
    name: 'AgenceNew',
    meta: {
      title: 'Ajouter une agence',
      activeMenu: '/admin/agence'
    }
  }, {
    path: 'edit/:id(\\d+)',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/admin/agence/AgenceEdit'));
      });
    },
    name: 'AgenceEdit',
    meta: {
      title: 'Modifier une agence',
      activeMenu: '/admin/agence'
    }
  }]
}];
var _default = routes;
exports.default = _default;