"use strict";

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var api = _interopRequireWildcard(require("@/api/sys_sqllog"));

var utils = _interopRequireWildcard(require("@/helpers/utils"));

var _adminListMixin = _interopRequireDefault(require("@/mixins/adminListMixin"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'SysSqlLogList',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  // 6. Composition
  // --
  mixins: [_adminListMixin.default],
  // 7. Interface (interface du composant)
  // --
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      api: api,
      columns: {
        data1: {
          key: 0,
          prop: 'data1',
          label: 'Méthode',
          width: 125,
          datatable: {
            is_specific: true
          }
        },
        data2: {
          key: 1,
          prop: 'data2',
          label: 'Url'
        },
        data3: {
          key: 2,
          prop: 'data3',
          label: 'Status',
          width: 125
        },
        data4: {
          key: 3,
          prop: 'data4',
          label: 'Sql'
        },
        data5: {
          key: 4,
          prop: 'data5',
          label: 'Utilisateur'
        },
        date: {
          key: 5,
          prop: 'date',
          label: 'Date',
          datatable: {
            is_specific: true
          }
        }
      },
      filters: [{
        value: '',
        prop: ['data1', 'data2', 'data3', 'data4', 'data5', 'date']
      }]
    };
  },
  // 9. Évènements
  // --
  // 10. Propriétés non réactives
  // --
  methods: {
    deleteAll: function deleteAll() {
      var _this = this;

      this.$confirm('Voulez-vous supprimer tous les enregistrements ?', 'Confirmer la suppression', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Annuler',
        type: 'warning'
      }).then(function () {
        _this.$loader(true);

        _this.api.deleteAll().then(function (results) {
          _this.$message('Suppression effectuée avec succès');

          _this.loadData();

          _this.$loader(false);
        }).catch(function (error) {
          _this.$loader(false);

          _this.$message(error);
        });
      }).catch(function (err) {
        if (err === 'cancel') {
          console.info("Bouton 'annuler' cliqué");
        } else {
          console.log('err', err);
        }
      });
    },
    exportCsv: function exportCsv() {
      utils.exportCsv(this.data);
    }
  }
};
exports.default = _default;