"use strict";

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAllByCaseId = getAllByCaseId;
exports.getById = getById;

var _request = _interopRequireDefault(require("@/helpers/request"));

function getAllByCaseId(caseId) {
  return (0, _request.default)({
    url: '/crm-case-progress/case/' + caseId,
    method: 'get'
  });
}

function getById(id) {
  return (0, _request.default)({
    url: '/crm-cases/' + id,
    method: 'get'
  });
}
/*
export function update (id, data) {
  return request({
    url: '/crm-case-progress/' + id,
    method: 'put',
    data
  })
}

export function insert (data) {
  return request({
    url: '/crm-case-progress',
    method: 'post',
    data
  })
}
*/