"use strict";

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.includes");

require("core-js/modules/es.string.includes");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _StatInfosItem = _interopRequireDefault(require("./StatInfosItem"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'StatInfos',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  // 6. Composition
  // --
  components: {
    StatInfosItem: _StatInfosItem.default
  },
  // 7. Interface
  // --
  props: {
    stat: {
      type: Object
    },
    scrollableItems: {
      type: Array,
      default: function _default() {
        return ['sites', 'portes', 'interventions', 'documents', 'tickets'];
      }
    }
  },
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {};
  },
  computed: {
    dynamicStats: function dynamicStats() {
      var stats = []; // Sites

      if (Object.prototype.hasOwnProperty.call(this.stat, 'nbSites')) {
        stats.push({
          key: 'site',
          title: 'Sites',
          icon: 'site',
          anchor: 'anchor-table-site',
          nb: this.stat.nbSites,
          isScrollable: this.scrollableItems.includes('sites')
        });
      } // Portes


      if (Object.prototype.hasOwnProperty.call(this.stat, 'nbPortes')) {
        stats.push({
          key: 'porte',
          title: 'Portes',
          icon: 'porte',
          anchor: 'anchor-table-porte',
          nb: this.stat.nbPortes,
          isScrollable: this.scrollableItems.includes('portes')
        });
      } // Interventions


      if (Object.prototype.hasOwnProperty.call(this.stat, 'nbInterventions')) {
        stats.push({
          key: 'intervention',
          title: 'Interventions',
          icon: 'intervention',
          anchor: 'anchor-table-intervention',
          nb: this.stat.nbInterventions,
          isScrollable: this.scrollableItems.includes('interventions')
        });
      } // Documents


      if (Object.prototype.hasOwnProperty.call(this.stat, 'nbDocuments')) {
        stats.push({
          key: 'document',
          title: 'Documents',
          icon: 'document',
          anchor: 'anchor-table-document',
          nb: this.stat.nbDocuments,
          isScrollable: this.scrollableItems.includes('documents')
        });
      } // Ticket


      if (Object.prototype.hasOwnProperty.call(this.stat, 'nbTickets')) {
        stats.push({
          key: 'ticket',
          title: 'Tickets',
          icon: 'ticket',
          anchor: 'anchor-table-ticket',
          nb: this.stat.nbTickets,
          isScrollable: this.scrollableItems.includes('tickets')
        });
      } // Suivi


      if (Object.prototype.hasOwnProperty.call(this.stat, 'nbTicketHistoriques')) {
        stats.push({
          key: 'historique-suivi',
          title: 'Historiques',
          icon: 'suivi',
          anchor: 'anchor-table-ticket-historique',
          nb: this.stat.nbTicketHistoriques,
          isScrollable: this.scrollableItems.includes('tickets-historiques')
        });
      } // Suivi


      if (Object.prototype.hasOwnProperty.call(this.stat, 'nbInterventionSuivis')) {
        stats.push({
          key: 'suivi',
          title: 'Suivi',
          icon: 'suivi',
          anchor: 'anchor-table-intervention-suivi',
          nb: this.stat.nbInterventionSuivis,
          isScrollable: this.scrollableItems.includes('interventions-suivis')
        });
      }

      return stats;
    }
  } // 9. Évènements
  // --
  // 10. Propriétés non réactives
  // --
  // 11. Rendu
  // --

};
exports.default = _default2;