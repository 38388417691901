var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ctn-search-btn" },
    [
      _c("el-button", {
        attrs: {
          title: "Recherche",
          type: "text",
          size: "medium",
          icon: "el-icon-search"
        },
        on: {
          click: function($event) {
            $event.preventDefault()
            return _vm.$router.push({ name: "UserSearch" })
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }