import { render, staticRenderFns } from "./SiteModule.vue?vue&type=template&id=89b2183a&scoped=true&"
import script from "./SiteModule.vue?vue&type=script&lang=js&"
export * from "./SiteModule.vue?vue&type=script&lang=js&"
import style0 from "./SiteModule.vue?vue&type=style&index=0&id=89b2183a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89b2183a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/lacroix_dev/extranet/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('89b2183a')) {
      api.createRecord('89b2183a', component.options)
    } else {
      api.reload('89b2183a', component.options)
    }
    module.hot.accept("./SiteModule.vue?vue&type=template&id=89b2183a&scoped=true&", function () {
      api.rerender('89b2183a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/app/site/SiteModule.vue"
export default component.exports