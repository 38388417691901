"use strict";

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

require("core-js/modules/es.function.name");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var api = _interopRequireWildcard(require("@/api/permission"));

var _adminListMixin = _interopRequireDefault(require("@/mixins/adminListMixin"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'PermissionList',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  // 6. Composition
  // --
  mixins: [_adminListMixin.default],
  // 7. Interface (interface du composant)
  // --
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      api: api,
      columns: {
        name: {
          key: 0,
          prop: 'name',
          label: 'Nom'
        }
      }
    };
  },
  // 9. Évènements
  // --
  // 10. Propriétés non réactives
  // --
  methods: {
    getHandleDeleteMessage: function getHandleDeleteMessage(index, row) {
      console.log('[PermissionList] getHandleDeleteMessage');
      return "Voulez-vous supprimer la permission ".concat(row.name, " ?");
    }
  } // 11. Rendu
  // --

};
exports.default = _default;