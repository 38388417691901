var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h1", [_vm._v("CRM : Companies")]),
      _c(
        "el-row",
        { attrs: { slot: "tool", gutter: 10 }, slot: "tool" },
        [
          _c(
            "el-col",
            {
              staticStyle: { "white-space": "nowrap" },
              attrs: { align: "right", span: 24 }
            },
            [
              _c("el-input", {
                staticStyle: { width: "150px" },
                attrs: {
                  clearable: "",
                  placeholder: "Rechercher",
                  size: "mini"
                },
                model: {
                  value: _vm.filters[0].value,
                  callback: function($$v) {
                    _vm.$set(_vm.filters[0], "value", $$v)
                  },
                  expression: "filters[0].value"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "data-tables",
        {
          ref: "dataTable",
          attrs: {
            data: _vm.data,
            filters: _vm.filters,
            layout: _vm.datatable.layout,
            "pagination-props": _vm.datatable.paginationProps,
            "table-props": Object.assign({}, _vm.datatable.tableProps, {
              defaultSort: { prop: "comp_name", order: "ascending" }
            }),
            total: _vm.total
          },
          on: {
            "row-click": function($event) {
              if ($event.target !== $event.currentTarget) {
                return null
              }
              return _vm.handleCurrentChange($event)
            },
            "selection-change": _vm.handleSelectionChange
          }
        },
        _vm._l(_vm.genericColumns, function(item) {
          return _c("el-table-column", {
            key: item.key,
            attrs: {
              label: item.label,
              prop: item.prop,
              width: _vm.colWidth(item.prop),
              sortable: "custom"
            }
          })
        }),
        1
      ),
      _c("dialog-detail", { attrs: { data: _vm.dialogDetail, row: _vm.row } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }