"use strict";

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/objectSpread2"));

var _vuex = require("vuex");

var _SiteList = _interopRequireDefault(require("./SiteList"));

var _exportModuleMixin = _interopRequireDefault(require("@/mixins/exportModuleMixin"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { EventBus } from '@/helpers/event-bus'
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'SiteModule',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  components: {
    SiteList: _SiteList.default
  },
  // 6. Composition
  // --
  mixins: [_exportModuleMixin.default],
  // 7. Interface
  // --
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      dataLoaded: false,
      mixinEvents: {
        export: 'SITE_EXPORT',
        exported: 'SITE_EXPORTED'
      },
      modalExport: {
        content: 'L\'export des données est en cours ... Merci de patientez.',
        title: 'Export de données',
        visible: false
      }
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['user'])),
  // 9. Évènements
  // --
  mounted: function mounted() {
    /*
    EventBus.$on('SITE_LIST_RELOAD', payload => {
      console.log('[SiteModule] Listening to Event \'SITE_LIST_RELOAD\'')
      this.loadData(payload)
    })
    // Chargement des données
    this.loadData({ page: 1, pageSize: 20 })
    */
  },
  beforeDestroy: function beforeDestroy() {// EventBus.$off('SITE_LIST_RELOAD')
  },
  // 10. Propriétés non réactives
  // --
  methods: {
    handleCommand: function handleCommand(command) {
      this.handleExport(command);
    },
    onExportDataSite: function onExportDataSite(params) {
      console.log('params', params);

      switch (params.step) {
        case 1:
          this.modalExport = {
            content: 'Récupération des données à exporter en cours...',
            title: 'Export de données : étape 1/2',
            visible: true
          };
          break;

        case 2:
          this.modalExport = {
            content: 'Génération du fichier d\'export en cours...',
            title: 'Export de données : étape 2/2',
            visible: true
          };

          if (params.format === 'pdf') {
            this.modalExport.content += '<br><em>Cette étape peut durer plusieurs minutes en fonction du nombre d\'enregistrements à exporter.</em>';
          }

          break;

        case 3:
          this.modalExport.visible = false;
          break;
      }
    }
  } // 11. Rendu
  // --

};
exports.default = _default;