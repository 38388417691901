var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("button-return"),
      _c(
        "el-row",
        { staticStyle: { margin: "10px 0 10px 0" } },
        [
          _c("el-col", { attrs: { span: 10 } }, [
            _c("h1", [_vm._v(_vm._s(_vm.title))])
          ]),
          _c(
            "el-col",
            { staticStyle: { "text-align": "right" }, attrs: { span: 14 } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-check",
                    size: "mini",
                    type: "primary"
                  },
                  on: { click: _vm.validateForm }
                },
                [_vm._v(" Enregistrer ")]
              ),
              _c(
                "el-button",
                { attrs: { size: "mini" }, on: { click: _vm.resetForm } },
                [_vm._v(" Reset ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-form",
        {
          ref: "ElForm",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "250px",
            size: "mini"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Agence", prop: "agence_id" } },
            [
              _c("agence-select-list", {
                key: "id_agence_" + _vm.formData.agence_id,
                attrs: { "id-agence-selected": _vm.formData.agence_id },
                on: {
                  "update:idAgenceSelected": function($event) {
                    return _vm.$set(_vm.formData, "agence_id", $event)
                  },
                  "update:id-agence-selected": function($event) {
                    return _vm.$set(_vm.formData, "agence_id", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Nom", prop: "lastname" } },
            [
              _c("el-input", {
                staticStyle: { width: "250px" },
                attrs: {
                  autocomplete: "off",
                  clearable: "",
                  maxlength: "255",
                  type: "string"
                },
                model: {
                  value: _vm.formData.lastname,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "lastname", $$v)
                  },
                  expression: "formData.lastname"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Prénom", prop: "firstname" } },
            [
              _c("el-input", {
                staticStyle: { width: "250px" },
                attrs: {
                  autocomplete: "off",
                  clearable: "",
                  maxlength: "255",
                  type: "string"
                },
                model: {
                  value: _vm.formData.firstname,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "firstname", $$v)
                  },
                  expression: "formData.firstname"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Mail", prop: "mail" } },
            [
              _c("el-input", {
                staticStyle: { width: "250px" },
                attrs: {
                  autocomplete: "off",
                  clearable: "",
                  maxlength: "255",
                  type: "string"
                },
                model: {
                  value: _vm.formData.mail,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "mail", $$v)
                  },
                  expression: "formData.mail"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Téléphone", prop: "phone" } },
            [
              _c("el-input", {
                staticStyle: { width: "250px" },
                attrs: {
                  autocomplete: "off",
                  clearable: "",
                  maxlength: "255",
                  type: "string"
                },
                model: {
                  value: _vm.formData.phone,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "phone", $$v)
                  },
                  expression: "formData.phone"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Type", prop: "type" } },
            [
              _c("contact-type-select-list", {
                key: "type_" + _vm.formData.type,
                attrs: { "selected-value": _vm.formData.type },
                on: {
                  "update:selectedValue": function($event) {
                    return _vm.$set(_vm.formData, "type", $event)
                  },
                  "update:selected-value": function($event) {
                    return _vm.$set(_vm.formData, "type", $event)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }