var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: { title: "Détail", visible: _vm.data.visible },
      on: {
        "update:visible": function($event) {
          return _vm.$set(_vm.data, "visible", $event)
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "app-bloc-info" },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _vm._l(_vm.data.columns.generic, function(item) {
                    return _c(
                      "el-row",
                      { key: item.key },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: _vm.data.colWidth[0] } },
                          [
                            item.dialog && item.dialog.is_strong
                              ? [_c("strong", [_vm._v(_vm._s(item.label))])]
                              : [_vm._v(_vm._s(item.label))]
                          ],
                          2
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: _vm.data.colWidth[1] } },
                          [
                            item.dialog && item.dialog.is_strong
                              ? [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.row[item.prop]))
                                  ])
                                ]
                              : [_vm._v(_vm._s(_vm.row[item.prop]))]
                          ],
                          2
                        )
                      ],
                      1
                    )
                  }),
                  _vm._l(_vm.data.columns.specific, function(item) {
                    return _c(
                      "el-row",
                      { key: item.key },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: _vm.data.colWidth[0] } },
                          [
                            item.dialog && item.dialog.is_strong
                              ? [_c("strong", [_vm._v(_vm._s(item.label))])]
                              : [_vm._v(_vm._s(item.label))]
                          ],
                          2
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: _vm.data.colWidth[1] } },
                          [
                            item.dialog
                              ? [
                                  item.dialog.is_strong
                                    ? [
                                        _c("strong", [
                                          item.dialog.is_html
                                            ? _c("span", {
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    item.dialog.format(
                                                      _vm.row[item.prop]
                                                    )
                                                  )
                                                }
                                              })
                                            : _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    item.dialog.format(
                                                      _vm.row[item.prop]
                                                    )
                                                  )
                                                )
                                              ])
                                        ])
                                      ]
                                    : [
                                        item.dialog.is_html
                                          ? _c("span", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  item.dialog.format(
                                                    _vm.row[item.prop]
                                                  )
                                                )
                                              }
                                            })
                                          : _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  item.dialog.format(
                                                    _vm.row[item.prop]
                                                  )
                                                )
                                              )
                                            ])
                                      ]
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      ],
                      1
                    )
                  })
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }