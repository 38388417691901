"use strict";

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAll = getAll;
exports.getById = getById;
exports.update = update;
exports.insert = insert;
exports.deleteById = deleteById;
exports.bulkDelete = bulkDelete;

var _request = _interopRequireDefault(require("@/helpers/request"));

function getAll() {
  return (0, _request.default)({
    url: '/contact',
    method: 'get'
  });
}

function getById(id) {
  return (0, _request.default)({
    url: '/contact/' + id,
    method: 'get'
  });
}

function update(id, data) {
  return (0, _request.default)({
    url: '/contact/' + id,
    method: 'put',
    data: data
  });
}

function insert(data) {
  return (0, _request.default)({
    url: '/contact',
    method: 'post',
    data: data
  });
}

function deleteById(id) {
  return (0, _request.default)({
    url: '/contact/' + id,
    method: 'delete'
  });
}

function bulkDelete(ids) {
  return (0, _request.default)({
    url: '/contact/' + ids,
    method: 'delete'
  });
}