"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _library = require("@/helpers/library");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'DocumentInfos',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  // 6. Composition
  // --
  // 7. Interface
  // --
  props: {
    document: {
      type: Object
    }
  },
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {};
  },
  // 9. Évènements
  // --
  // 10. Propriétés non réactives
  // --
  methods: {
    handleLinkToFile: function handleLinkToFile(row) {
      window.open((0, _library.getDocumentIdUri)(row.libr_libraryid), '_LIBRARY');
    }
  } // 11. Rendu
  // --

};
exports.default = _default;