"use strict";

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var apiUser = _interopRequireWildcard(require("@/api/user"));

var _UserForm = _interopRequireDefault(require("./components/UserForm"));

//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'ParametreEdit',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  components: {
    UserForm: _UserForm.default
  },
  // 6. Composition
  // --
  // 7. Interface
  // --
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      action: 'edit',
      dataLoaded: false,
      formData: {},
      title: 'Modifier un utilisateur'
    };
  },
  // 9. Évènements
  // --
  created: function created() {
    this.loadData();
  },
  // 10. Propriétés non réactives
  // --
  methods: {
    loadData: function loadData() {
      var _this = this;

      this.$loader(true);
      apiUser.getById(this.$route.params.id).then(function (results) {
        _this.formData = results[0];
        _this.dataLoaded = true;
      }).catch(function (err) {
        if (err) {
          console.log('err', err);

          _this.$message(err);
        } else {
          // Retour à la liste
          _this.$router.push({
            name: 'UserList'
          });
        }
      }).then(function (_) {
        _this.$loader(false);
      });
    }
  } // 11. Rendu
  // --

};
exports.default = _default;