"use strict";

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.object.to-string");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard"));

var defaultActiveMenu = '/dashboard';
var routes = [{
  path: '/ticket',
  name: 'Ticket',
  order: 25,
  meta: {
    title: 'Gestion des tickets',
    icon: 'site'
  },
  redirect: {
    name: 'TicketModule'
  },
  hidden: true,
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/layout'));
    });
  },
  children: [// TICKETS
  // ----------------
  // Liste des Tickets
  {
    path: 'list',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/app/ticket/TicketModule'));
      });
    },
    name: 'TicketModule',
    meta: {
      title: 'Liste des Tickets',
      activeMenu: defaultActiveMenu
    }
  }, // Fiche d'un Ticket
  {
    path: ':ticketId(\\d+)',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/app/ticket/TicketIndex'));
      });
    },
    name: 'TicketIndex',
    redirect: {
      name: 'TicketView'
    },
    meta: {
      title: 'Fiche d\'un ticket',
      activeMenu: defaultActiveMenu
    },
    children: [// Contenu par défaut
    {
      path: '',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/app/ticket/TicketView'));
        });
      },
      name: 'TicketView',
      meta: {
        title: 'Vue détaillée d\'un ticket',
        activeMenu: defaultActiveMenu
      }
    }, // Liste des Historiques Tickets
    {
      path: 'historique',
      hidden: true,
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/app/ticket/TicketHistoriqueList'));
        });
      },
      name: 'TicketHistoriqueList',
      meta: {
        title: 'Historiques de Tickets',
        activeMenu: defaultActiveMenu
      }
    }]
  }, // Création d'un Ticket
  {
    path: 'new',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/app/ticket/TicketNew'));
      });
    },
    name: 'TicketNew',
    meta: {
      title: 'Création d\'un ticket',
      activeMenu: defaultActiveMenu
    }
  }]
}];
var _default = routes;
exports.default = _default;