"use strict";

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.object.to-string");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard"));

var defaultActiveMenu = '/dashboard';
var routes = [{
  path: '/site',
  name: 'Site',
  order: 20,
  meta: {
    title: 'Gestion des sites',
    icon: 'site'
  },
  redirect: {
    name: 'SiteModule'
  },
  hidden: true,
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/layout'));
    });
  },
  children: [// SITES
  // ----------------
  // Liste des sites
  {
    path: 'list',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/app/site/SiteModule'));
      });
    },
    name: 'SiteModule',
    meta: {
      title: 'Liste des sites',
      activeMenu: defaultActiveMenu
    }
  }, // Fiche d'un site
  {
    path: ':siteId(\\d+)',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/app/site/SiteIndex'));
      });
    },
    name: 'SiteIndex',
    redirect: {
      name: 'SiteView'
    },
    meta: {
      title: 'Fiche d\'un site',
      activeMenu: defaultActiveMenu
    },
    children: [// Contenu par défaut
    {
      path: '',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/app/site/SiteView'));
        });
      },
      name: 'SiteView',
      meta: {
        title: 'Vue détaillée d\'un site',
        activeMenu: defaultActiveMenu
      }
    }, // Liste des Portes d'un site
    {
      path: 'portes',
      hidden: true,
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/app/porte/PorteModule'));
        });
      },
      name: 'PorteSiteModule',
      meta: {
        title: 'Portes d\'un site',
        activeMenu: defaultActiveMenu
      }
    }, // Liste des Interventions d'un site
    {
      path: 'interventions',
      hidden: true,
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/app/intervention/InterventionModule'));
        });
      },
      name: 'InterventionSiteModule',
      meta: {
        title: 'Interventions d\'un site',
        activeMenu: defaultActiveMenu
      }
    }, // Liste des Documents d'un site
    {
      path: 'documents',
      hidden: true,
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/app/document/DocumentModule'));
        });
      },
      name: 'DocumentSiteModule',
      meta: {
        title: 'Documents d\'un site',
        activeMenu: defaultActiveMenu
      }
    }, // Liste des Tickets d'un site
    {
      path: 'tickets',
      hidden: true,
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/app/ticket/TicketModule'));
        });
      },
      name: 'TicketSiteModule',
      meta: {
        title: 'Tickets d\'un site',
        activeMenu: defaultActiveMenu
      }
    }, // Création d'un Ticket
    {
      path: 'ticket-new',
      hidden: true,
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/app/ticket/TicketNew'));
        });
      },
      name: 'TicketSiteNew',
      meta: {
        title: 'Création d\'un ticket',
        activeMenu: defaultActiveMenu
      }
    }]
  }]
}];
var _default = routes;
exports.default = _default;