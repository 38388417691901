"use strict";

require("core-js/modules/es.array.concat");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getDocumentFichierUri = getDocumentFichierUri;
exports.getDocumentIdUri = getDocumentIdUri;

var _auth = require("@/helpers/auth");

function getDocumentFichierUri(filename) {
  var withBaseUrl = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  var uri = '';

  if (withBaseUrl === true) {
    uri += process.env.VUE_APP_API_PUBLIC_FOLDER;
  }

  uri += "/crm-library/by-filename/".concat(filename);
  console.log('[helper -> getDocumentFichierUri] uri: ', uri);
  return uri;
}

function getDocumentIdUri(id) {
  var withBaseUrl = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  var uri = '';

  if (withBaseUrl === true) {
    // uri += process.env.VUE_APP_BASE_API
    uri += process.env.VUE_APP_API_PUBLIC_FOLDER;
  }

  uri += "/crm-library/file/".concat(id, "?token=").concat((0, _auth.getToken)());
  console.log('[helper -> getDocumentIdUri] uri: ', uri);
  return uri;
}