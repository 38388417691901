"use strict";

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.find");

require("core-js/modules/es.array.for-each");

require("core-js/modules/es.array.includes");

require("core-js/modules/es.array.index-of");

require("core-js/modules/es.array.map");

require("core-js/modules/es.array.some");

require("core-js/modules/es.number.constructor");

require("core-js/modules/es.object.keys");

require("core-js/modules/es.string.includes");

require("core-js/modules/web.dom-collections.for-each");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/objectSpread2"));

var _vuex = require("vuex");

var _utils = require("@/helpers/utils");

var _datatableMixin = _interopRequireDefault(require("@/mixins/datatableMixin"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'TicketHistoriqueList',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  components: {},
  // 6. Composition
  // --
  mixins: [_datatableMixin.default],
  // 7. Interface
  // --
  props: {
    limit: {
      type: Number,
      required: false,
      default: null
    },
    showFilters: {
      type: Boolean,
      required: false,
      default: true
    },
    showColumnFilters: {
      type: Boolean,
      required: false,
      default: false
    },
    hideReturnBtn: {
      type: Boolean,
      required: false,
      default: false
    },
    hideSeeAllBtn: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      columns: {
        case_createddate: {
          key: 0,
          prop: 'case_createddate',
          label: 'Créé le',
          width: 150
        },
        case_problemnote: {
          key: 1,
          prop: 'case_problemnote',
          label: 'Problème'
        },
        case_progressnote: {
          key: 2,
          prop: 'case_progressnote',
          label: 'Note de suivi'
        },
        agence: {
          key: 3,
          prop: 'agence',
          label: 'Agence',
          width: 125
        },
        case_stage: {
          key: 4,
          prop: 'case_stage',
          label: 'Étape',
          width: 175
        },
        case_status: {
          key: 5,
          prop: 'case_status',
          label: 'Statut',
          width: 150
        },
        case_duration: {
          key: 6,
          prop: 'case_duration',
          label: 'Durée',
          width: 100
        }
      },
      filters: [{
        value: '',
        prop: ['case_createddate', 'case_problemnote', 'case_progressnote', 'case_duration'],
        filterFn: function filterFn(row, filter) {
          return Object.keys(row).some(function (prop) {
            switch (prop) {
              default:
                return String(row[prop]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1;
              // return false
            }
          });
        }
      }, // Agence
      {
        value: '',
        prop: ['agence'],
        filterFn: function filterFn(row, filter) {
          return filter.value.includes(row.agence.chan_description);
        }
      }, // Étape (case_stage et case_stage_value)
      {
        value: '',
        prop: ['case_stage'],
        filterFn: function filterFn(row, filter) {
          return filter.value.includes(row.case_stage_value);
        }
      }, // Statut (case_status et case_status_value)
      {
        value: '',
        prop: ['case_status'],
        filterFn: function filterFn(row, filter) {
          return filter.value.includes(row.case_status_value);
        }
      }],
      filterAgence: [],
      filterStage: [],
      filterStatus: [],
      sortMethod: {
        case_progressnote: function case_progressnote(a, b) {
          return (0, _utils.sortDefault)(a, b);
        },
        case_problemnote: function case_problemnote(a, b) {
          return (0, _utils.sortDefault)(a, b);
        },
        case_stage: function case_stage(a, b) {
          return (0, _utils.sortDefault)(a, b);
        },
        case_status: function case_status(a, b) {
          return (0, _utils.sortDefault)(a, b);
        },
        case_duration: function case_duration(a, b) {
          return (0, _utils.sortDefault)(a, b);
        },
        agence: function agence(a, b) {
          return (0, _utils.sortDefault)(a, b, 'chan_description');
        }
      }
    };
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)('crm', ['getTicketHistoriquesBy'])), {}, {
    data: function data() {
      var data = this.getTicketHistoriquesBy(this.$route.params.ticketId, 'ticket', {
        limit: this.limit
      }); // ---
      // Initialisation des valeurs des filtres de colonnes

      var filterAgence = [];
      var filterStage = [];
      var filterStatus = [];
      data.forEach(function (row) {
        // Initialisation des valeurs du filtre pour la colonne "agence"
        if (!filterAgence.find(function (x) {
          return x.value === row.agence.chan_description;
        })) {
          var item = {
            text: row.agence.chan_description ? row.agence.chan_description : 'n/c',
            value: row.agence.chan_description
          };
          filterAgence.push(item);
        } // Initialisation des valeurs du filtre pour la colonne "catégorie"


        if (!filterStage.find(function (x) {
          return x.value === row.case_stage_value;
        })) {
          var _item = {
            text: row.case_stage ? row.case_stage : 'n/c',
            value: row.case_stage_value
          };
          filterStage.push(_item);
        } // Initialisation des valeurs du filtre pour la colonne "statut"


        if (!filterStatus.find(function (x) {
          return x.value === row.case_status_value;
        })) {
          var _item2 = {
            text: row.case_status ? row.case_status : 'n/c',
            value: row.case_status_value
          };
          filterStatus.push(_item2);
        }
      }); // Tri alphabétique

      filterAgence.sort(_utils.sortSelectItems);
      filterStage.sort(_utils.sortSelectItems);
      filterStatus.sort(_utils.sortSelectItems); // Assignation des filtres

      this.setFilter('agence', filterAgence);
      this.setFilter('case_stage', filterStage);
      this.setFilter('case_status', filterStatus);
      return data;
    }
  }),
  // 9. Évènements
  // --
  mounted: function mounted() {
    // Chargement des données
    this.loadData();
  },
  // 10. Propriétés non réactives
  // --
  methods: {
    // ...mapActions('crm', [
    //   'getListByUser'
    // ]),
    ln2br: function ln2br(value) {
      return (0, _utils.ln2br)(value);
    },
    loadData: function loadData() {
      this.dataLoaded = true;
    },
    // loadUserData () {
    //   this.dataLoaded = false
    //   this.$loader(true)
    //   this.$store.dispatch('crm/getListByUser', this.user.id)
    //   // this.getListByUser(this.user.id)
    //     .then(_ => {
    //       console.log('Liste des sites de l\'utilisateur chargée')
    //       this.dataLoaded = true
    //       this.$loader(false)
    //     })
    //     .catch(err => {
    //       console.error(err)
    //       this.$loader(false)
    //     })
    // },
    //
    // loadAllData () {
    //   this.dataLoaded = false
    //   this.$loader(true)
    //   this.$store.dispatch('crm/getAll')
    //     .then(_ => {
    //       console.log('Liste de tous les sites chargée')
    //       this.dataLoaded = true
    //       this.$loader(false)
    //     })
    //     .catch(err => {
    //       console.error(err)
    //       this.$loader(false)
    //     })
    // },
    setFilter: function setFilter(prop, filter) {
      switch (prop) {
        case 'agence':
          this.filterAgence = filter;
          break;

        case 'case_status':
          this.filterStatus = filter;
          break;

        case 'case_stage':
          this.filterStage = filter;
          break;
      }
    },
    handleFilter: function handleFilter(value, row, column) {
      var property = column.property;

      switch (property) {
        case 'agence':
          return value.includes(row.agence.chan_description);

        case 'case_stage':
          return value.includes(row.case_stage_value);

        case 'case_status':
          return value.includes(row.case_status_value);

        default:
          return row[property] === value;
      }
    },
    getSummaries: function getSummaries(param) {
      var columns = param.columns,
          data = param.data;
      var sums = [];
      columns.forEach(function (column, index) {
        if (column.property === 'case_createddate') {
          sums[index] = 'Total';
          return;
        }

        if (!['case_duration'].includes(column.property)) {
          sums[index] = '';
          return;
        }

        var values = data.map(function (item) {
          return Number(item[column.property]);
        });

        if (['case_duration'].includes(column.property)) {
          sums[index] = (0, _utils.toDuration)((0, _utils.getTotal)(values));
        } else {
          sums[index] = (0, _utils.getTotal)(values);
        }
      });
      return sums;
    }
  } // 11. Rendu
  // --

};
exports.default = _default;