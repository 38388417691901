var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dataLoaded
    ? _c(
        "div",
        [
          !_vm.hideReturnBtn
            ? _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { icon: "el-icon-arrow-left", size: "mini" },
                          on: {
                            click: function($event) {
                              return _vm.$router.go(-1)
                            }
                          }
                        },
                        [_vm._v("Retour")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "data-tables",
            {
              ref: "dataTable",
              attrs: {
                data: _vm.data,
                filters: _vm.filters,
                layout: _vm.datatable.layout,
                "pagination-props": _vm.datatable.paginationProps,
                "sort-method": _vm.sortMethod,
                "table-props": Object.assign({}, _vm.datatable.tableProps, {
                  showSummary: false,
                  summaryMethod: _vm.getSummaries,
                  defaultExpandAll: false,
                  defaultSort: { prop: "case_createddate", order: "descending" }
                }),
                total: _vm.total
              },
              on: {
                "row-click": function($event) {
                  if ($event.target !== $event.currentTarget) {
                    return null
                  }
                  return _vm.handleCurrentChange($event)
                }
              }
            },
            [
              _vm.showFilters
                ? _c(
                    "el-row",
                    { attrs: { slot: "tool", gutter: 10 }, slot: "tool" },
                    [
                      _c(
                        "fieldset",
                        {
                          staticStyle: {
                            margin: "0 0 20px 0",
                            border: "1px solid #EBEEF5"
                          }
                        },
                        [
                          _c("legend", { staticStyle: { padding: "20px" } }, [
                            _vm._v(" Filtres ")
                          ]),
                          _c(
                            "el-col",
                            {
                              staticClass: "filter-item",
                              staticStyle: { "white-space": "nowrap" },
                              attrs: { align: "left", xs: 24, sm: 6, lg: 4 }
                            },
                            [
                              _c("div", [_vm._v("Agence :")]),
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    "collapse-tags": "",
                                    multiple: "",
                                    placeholder: "Filtrer par agence",
                                    size: "mini"
                                  },
                                  model: {
                                    value: _vm.filters[1].value,
                                    callback: function($$v) {
                                      _vm.$set(_vm.filters[1], "value", $$v)
                                    },
                                    expression: "filters[1].value"
                                  }
                                },
                                _vm._l(_vm.filterAgence, function(item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.text,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            {
                              staticClass: "filter-item",
                              staticStyle: { "white-space": "nowrap" },
                              attrs: { align: "left", xs: 24, sm: 6, lg: 4 }
                            },
                            [
                              _c("div", [_vm._v("Étape :")]),
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    "collapse-tags": "",
                                    multiple: "",
                                    placeholder: "Filtrer par étape",
                                    size: "mini"
                                  },
                                  model: {
                                    value: _vm.filters[2].value,
                                    callback: function($$v) {
                                      _vm.$set(_vm.filters[2], "value", $$v)
                                    },
                                    expression: "filters[2].value"
                                  }
                                },
                                _vm._l(_vm.filterStage, function(item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.text,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            {
                              staticClass: "filter-item",
                              staticStyle: { "white-space": "nowrap" },
                              attrs: { align: "left", xs: 24, sm: 6, lg: 4 }
                            },
                            [
                              _c("div", [_vm._v("Statut :")]),
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    "collapse-tags": "",
                                    multiple: "",
                                    placeholder: "Filtrer par statut",
                                    size: "mini"
                                  },
                                  model: {
                                    value: _vm.filters[3].value,
                                    callback: function($$v) {
                                      _vm.$set(_vm.filters[3], "value", $$v)
                                    },
                                    expression: "filters[3].value"
                                  }
                                },
                                _vm._l(_vm.filterStatus, function(item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.text,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            {
                              staticClass: "filter-item",
                              staticStyle: { "white-space": "nowrap" },
                              attrs: {
                                align: _vm.windowWidth < 768 ? "left" : "right",
                                xs: 24,
                                sm: 6,
                                lg: 12
                              }
                            },
                            [
                              _c("div", [
                                _c(
                                  "span",
                                  { staticClass: "hidden-sm-and-up" },
                                  [_vm._v("Recherche (toutes colonnes) :")]
                                ),
                                _c("br")
                              ]),
                              _c("el-input", {
                                attrs: {
                                  clearable: "",
                                  placeholder: "Rechercher",
                                  size: "mini"
                                },
                                model: {
                                  value: _vm.filters[0].value,
                                  callback: function($$v) {
                                    _vm.$set(_vm.filters[0], "value", $$v)
                                  },
                                  expression: "filters[0].value"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e(),
              _c("el-table-column", {
                attrs: { type: "expand", fixed: "left" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          scope.row.case_problemnote
                            ? _c("div", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.columns["case_problemnote"].label
                                    ) +
                                    " : "
                                ),
                                _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.ln2br(scope.row.case_problemnote)
                                    )
                                  }
                                })
                              ])
                            : _vm._e(),
                          scope.row.case_progressnote
                            ? _c("div", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.columns["case_progressnote"].label
                                    ) +
                                    " : "
                                ),
                                _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.ln2br(scope.row.case_progressnote)
                                    )
                                  }
                                })
                              ])
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2157112386
                )
              }),
              _c("el-table-column", {
                key: _vm.columns["case_createddate"].key,
                attrs: {
                  label: _vm.columns["case_createddate"].label,
                  prop: _vm.columns["case_createddate"].prop,
                  width: _vm.colWidth("case_createddate"),
                  sortable: "custom"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm._f("toDateTime")(
                                  scope.row["case_createddate"]
                                )
                              )
                            )
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  939612389
                )
              }),
              _c("el-table-column", {
                key: _vm.columns["agence"].key,
                attrs: {
                  filters: _vm.showColumnFilters ? _vm.filterAgence : undefined,
                  "filter-method": _vm.showColumnFilters
                    ? _vm.handleFilter
                    : undefined,
                  label: _vm.columns["agence"].label,
                  prop: _vm.columns["agence"].prop,
                  width: _vm.colWidth("agence"),
                  sortable: "custom"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          scope.row.agence
                            ? _c("div", [
                                _vm._v(
                                  _vm._s(scope.row.agence.chan_description)
                                )
                              ])
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3400037533
                )
              }),
              _c("el-table-column", {
                key: _vm.columns["case_stage"].key,
                attrs: {
                  filters: _vm.showColumnFilters ? _vm.filterStage : undefined,
                  "filter-method": _vm.showColumnFilters
                    ? _vm.handleFilter
                    : undefined,
                  label: _vm.columns["case_stage"].label,
                  prop: _vm.columns["case_stage"].prop,
                  width: _vm.colWidth("case_stage"),
                  sortable: "custom"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("div", [_vm._v(_vm._s(scope.row["case_stage"]))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1508527702
                )
              }),
              _c("el-table-column", {
                key: _vm.columns["case_status"].key,
                attrs: {
                  filters: _vm.showColumnFilters ? _vm.filterStatus : undefined,
                  "filter-method": _vm.showColumnFilters
                    ? _vm.handleFilter
                    : undefined,
                  label: _vm.columns["case_status"].label,
                  prop: _vm.columns["case_status"].prop,
                  width: _vm.colWidth("case_status"),
                  sortable: "custom"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("div", [_vm._v(_vm._s(scope.row["case_status"]))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1775655398
                )
              }),
              _c("el-table-column", {
                key: _vm.columns["case_duration"].key,
                attrs: {
                  label: _vm.columns["case_duration"].label,
                  prop: _vm.columns["case_duration"].prop,
                  width: _vm.colWidth("case_duration"),
                  sortable: "custom"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm._f("toSuiviDuration")(
                                  scope.row.case_duration
                                )
                              )
                            )
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  280884200
                )
              })
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              staticClass: "dialog",
              attrs: { title: "Détail", visible: _vm.dialogDetail.visible },
              on: {
                "update:visible": function($event) {
                  return _vm.$set(_vm.dialogDetail, "visible", $event)
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "app-bloc-info" },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: _vm.dialogDetailWidth[0] } },
                                [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.columns["case_createddate"].label
                                      )
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.dialogDetailWidth[1] } },
                                [
                                  _c("strong", [
                                    _c("i", { staticClass: "el-icon-date" }),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("toDateTime")(
                                            _vm.row["case_createddate"]
                                          )
                                        )
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: _vm.dialogDetailWidth[0] } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.columns["case_problemnote"].label
                                    )
                                  )
                                ]
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.dialogDetailWidth[1] } },
                                [
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.ln2br(_vm.row.case_problemnote)
                                      )
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: _vm.dialogDetailWidth[0] } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.columns["case_progressnote"].label
                                    )
                                  )
                                ]
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.dialogDetailWidth[1] } },
                                [
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.ln2br(_vm.row.case_progressnote)
                                      )
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          ),
                          _vm.row.agence
                            ? _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    {
                                      attrs: { span: _vm.dialogDetailWidth[0] }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.columns["agence"].label)
                                      )
                                    ]
                                  ),
                                  _c(
                                    "el-col",
                                    {
                                      attrs: { span: _vm.dialogDetailWidth[1] }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.row.agence.chan_description)
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: _vm.dialogDetailWidth[0] } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.columns["case_stage"].label)
                                  )
                                ]
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.dialogDetailWidth[1] } },
                                [_vm._v(_vm._s(_vm.row["case_stage"]))]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: _vm.dialogDetailWidth[0] } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.columns["case_status"].label)
                                  )
                                ]
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.dialogDetailWidth[1] } },
                                [_vm._v(_vm._s(_vm.row["case_status"]))]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: _vm.dialogDetailWidth[0] } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.columns["case_duration"].label)
                                  )
                                ]
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.dialogDetailWidth[1] } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("toSuiviDuration")(
                                        _vm.row.case_duration
                                      )
                                    )
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }