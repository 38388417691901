"use strict";

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Item = _interopRequireDefault(require("../../../layout/components/Sidebar/Item"));

var _IconBase = _interopRequireDefault(require("../../../components/IconBase"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'StatInfosItem',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  // 6. Composition
  // --
  components: {
    Item: _Item.default,
    IconBase: _IconBase.default
  },
  // 7. Interface
  // --
  props: {
    stat: {
      type: Object
    }
  },
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      span: {
        xs: 12,
        sm: 6,
        lg: 3
      }
    };
  },
  computed: {
    dynamicIcons: function dynamicIcons() {
      var width;
      var height;

      if (this.windowWidth < 768) {
        width = 60;
        height = 60;
      } else if (this.windowWidth < 992) {
        width = 80;
        height = 80;
      } else {
        width = 100;
        height = 100;
      }

      return {
        big: {
          width: width,
          height: height
        }
      };
    }
  } // 9. Évènements
  // --
  // 10. Propriétés non réactives
  // --
  // 11. Rendu
  // --

};
exports.default = _default;