"use strict";

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _CrmChannelList = _interopRequireDefault(require("./CrmChannelList"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'CrmChannelModule',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  components: {
    CrmChannelList: _CrmChannelList.default
  },
  // 6. Composition
  // --
  // 7. Interface
  // --
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      /* ---------------------------------------
       *  Mode d'affichage
       * ---------------------------------------
       *
       *   - list : liste des companies (CRM)
       *
       */
      modeView: 'list'
    };
  },
  computed: {
    /* ---------------------------------------
     *  Mode saisie
     * ---------------------------------------
     *
     *   - false si modeView = 'list'
     *
     */
    modeEdit: function modeEdit() {
      return this.modeView !== 'list';
    },

    /* ---------------------------------------
     *  Afficher le bouton de retour
     * ---------------------------------------
     *
     *   - si on est sur un formulaire (création, édition)
     *     OU
     *   - si on consulte les factures d'une commande
     *
     */
    showBtnReturn: function showBtnReturn() {
      return this.modeEdit === true;
    }
  },
  // 9. Évènements
  // --
  // 10. Propriétés non réactives
  // --
  methods: {} // 11. Rendu
  // --

};
exports.default = _default;