var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dataLoaded
    ? _c(
        "div",
        [
          !_vm.hideReturnBtn
            ? _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { icon: "el-icon-arrow-left", size: "mini" },
                          on: {
                            click: function($event) {
                              return _vm.$router.go(-1)
                            }
                          }
                        },
                        [_vm._v("Retour")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "data-tables",
            {
              ref: "dataTable",
              attrs: {
                data: _vm.data,
                filters: _vm.filters,
                layout: _vm.datatable.layout,
                "pagination-props": _vm.datatable.paginationProps,
                "sort-method": _vm.sortMethod,
                "table-props": Object.assign({}, _vm.datatable.tableProps, {
                  showSummary: true,
                  summaryMethod: _vm.getSummaries,
                  defaultSort: { prop: "oppo_opened", order: "descending" }
                }),
                total: _vm.total
              },
              on: {
                "row-click": function($event) {
                  if ($event.target !== $event.currentTarget) {
                    return null
                  }
                  return _vm.handleCurrentChange($event)
                }
              }
            },
            [
              _vm.showFilters
                ? _c(
                    "el-row",
                    { attrs: { slot: "tool", gutter: 10 }, slot: "tool" },
                    [
                      _c(
                        "fieldset",
                        {
                          staticStyle: {
                            margin: "0 0 20px 0",
                            border: "1px solid #EBEEF5"
                          }
                        },
                        [
                          _c("legend", { staticStyle: { padding: "20px" } }, [
                            _vm._v(" Filtres ")
                          ]),
                          _c(
                            "el-col",
                            {
                              staticClass: "filter-item",
                              staticStyle: { "white-space": "nowrap" },
                              attrs: { align: "left", xs: 24, sm: 8, lg: 3 }
                            },
                            [
                              _c("div", [_vm._v("Agence :")]),
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    "collapse-tags": "",
                                    multiple: "",
                                    placeholder: "Filtrer par agence",
                                    size: "mini"
                                  },
                                  model: {
                                    value: _vm.filters[1].value,
                                    callback: function($$v) {
                                      _vm.$set(_vm.filters[1], "value", $$v)
                                    },
                                    expression: "filters[1].value"
                                  }
                                },
                                _vm._l(_vm.filterAgence, function(item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.text,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            {
                              staticClass: "filter-item",
                              staticStyle: { "white-space": "nowrap" },
                              attrs: { align: "left", xs: 24, sm: 8, lg: 3 }
                            },
                            [
                              _c("div", [_vm._v("Catégorie :")]),
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    "collapse-tags": "",
                                    multiple: "",
                                    placeholder: "Filtrer par catégorie",
                                    size: "mini"
                                  },
                                  model: {
                                    value: _vm.filters[2].value,
                                    callback: function($$v) {
                                      _vm.$set(_vm.filters[2], "value", $$v)
                                    },
                                    expression: "filters[2].value"
                                  }
                                },
                                _vm._l(_vm.filterCategorie, function(item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.text,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            {
                              staticClass: "filter-item",
                              staticStyle: { "white-space": "nowrap" },
                              attrs: { align: "left", xs: 24, sm: 8, lg: 3 }
                            },
                            [
                              _c("div", [_vm._v("Étape :")]),
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    "collapse-tags": "",
                                    multiple: "",
                                    placeholder: "Filtrer par statut",
                                    size: "mini"
                                  },
                                  model: {
                                    value: _vm.filters[3].value,
                                    callback: function($$v) {
                                      _vm.$set(_vm.filters[3], "value", $$v)
                                    },
                                    expression: "filters[3].value"
                                  }
                                },
                                _vm._l(_vm.filterStage, function(item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.text,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            {
                              staticClass: "filter-item",
                              staticStyle: { "white-space": "nowrap" },
                              attrs: { align: "left", xs: 24, sm: 8, lg: 3 }
                            },
                            [
                              _c("div", [_vm._v("Du :")]),
                              _c("el-date-picker", {
                                attrs: {
                                  format: _vm.dateFormat,
                                  clearable: "",
                                  placeholder: "",
                                  size: "mini",
                                  type: "date",
                                  "value-format": "yyyy-MM-dd",
                                  "picker-options": { firstDayOfWeek: 1 }
                                },
                                on: { change: _vm.handleChangeDateMin },
                                model: {
                                  value: _vm.filters[4].value,
                                  callback: function($$v) {
                                    _vm.$set(_vm.filters[4], "value", $$v)
                                  },
                                  expression: "filters[4].value"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            {
                              staticClass: "filter-item",
                              staticStyle: { "white-space": "nowrap" },
                              attrs: { align: "left", xs: 24, sm: 8, lg: 3 }
                            },
                            [
                              _c("div", [_vm._v("au :")]),
                              _c("el-date-picker", {
                                attrs: {
                                  format: _vm.dateFormat,
                                  clearable: "",
                                  placeholder: "",
                                  size: "mini",
                                  type: "date",
                                  "value-format": "yyyy-MM-dd",
                                  "picker-options": { firstDayOfWeek: 1 }
                                },
                                on: { change: _vm.handleChangeDateMax },
                                model: {
                                  value: _vm.filters[5].value,
                                  callback: function($$v) {
                                    _vm.$set(_vm.filters[5], "value", $$v)
                                  },
                                  expression: "filters[5].value"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            {
                              staticClass: "filter-item",
                              staticStyle: { "white-space": "nowrap" },
                              attrs: {
                                align:
                                  _vm.windowWidth < 1200 ? "left" : "right",
                                xs: 24,
                                sm: 8,
                                lg: 9
                              }
                            },
                            [
                              _c("div", [
                                _c(
                                  "span",
                                  { staticClass: "hidden-sm-and-up" },
                                  [_vm._v("Recherche (toutes colonnes) :")]
                                ),
                                _c("br")
                              ]),
                              _c("el-input", {
                                attrs: {
                                  clearable: "",
                                  placeholder: "Rechercher",
                                  size: "mini"
                                },
                                model: {
                                  value: _vm.filters[0].value,
                                  callback: function($$v) {
                                    _vm.$set(_vm.filters[0], "value", $$v)
                                  },
                                  expression: "filters[0].value"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e(),
              _vm.total > 0
                ? _c(
                    "div",
                    [
                      _c("el-table-column", {
                        key: _vm.columns["oppo_opened"].key,
                        attrs: {
                          label: _vm.columns["oppo_opened"].label,
                          prop: _vm.columns["oppo_opened"].prop,
                          width: _vm.colWidth("oppo_opened"),
                          sortable: "custom"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("toDateTime")(
                                          scope.row["oppo_opened"]
                                        )
                                      )
                                    )
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          997730672
                        )
                      }),
                      _c("el-table-column", {
                        key: _vm.columns["oppo_reference"].key,
                        attrs: {
                          label: _vm.columns["oppo_reference"].label,
                          prop: _vm.columns["oppo_reference"].prop,
                          width: _vm.colWidth("oppo_reference"),
                          sortable: "custom"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("div", [
                                    _vm._v(_vm._s(scope.row["oppo_reference"]))
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3101576525
                        )
                      }),
                      _c("el-table-column", {
                        key: _vm.columns["oppo_description"].key,
                        attrs: {
                          label: _vm.columns["oppo_description"].label,
                          prop: _vm.columns["oppo_description"].prop,
                          "show-overflow-tooltip": true,
                          width: _vm.colWidth("oppo_description"),
                          sortable: "custom"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(scope.row["oppo_description"])
                                    )
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          503770752
                        )
                      }),
                      _c("el-table-column", {
                        key: _vm.columns["door_matricule"].key,
                        attrs: {
                          label: _vm.columns["door_matricule"].label,
                          prop: _vm.columns["door_matricule"].prop,
                          width: _vm.colWidth("door_matricule"),
                          sortable: "custom"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  scope.row.door_matricule
                                    ? _c("div", [
                                        _vm._v(_vm._s(scope.row.door_matricule))
                                      ])
                                    : scope.row.porte
                                    ? _c("div", [
                                        _vm._v(
                                          _vm._s(scope.row.porte.door_matricule)
                                        )
                                      ])
                                    : _vm._e()
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2239530552
                        )
                      }),
                      _c("el-table-column", {
                        key: _vm.columns.addr_city.key,
                        attrs: {
                          label: _vm.columns.addr_city.label,
                          prop: _vm.columns.addr_city.prop,
                          width: _vm.colWidth("addr_city"),
                          sortable: "custom"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("div", [
                                    _vm._v(_vm._s(scope.row.addr_city))
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1324310842
                        )
                      }),
                      _c("el-table-column", {
                        key: _vm.columns["agence"].key,
                        attrs: {
                          filters: _vm.showColumnFilters
                            ? _vm.filterAgence
                            : undefined,
                          "filter-method": _vm.showColumnFilters
                            ? _vm.handleFilter
                            : undefined,
                          label: _vm.columns["agence"].label,
                          prop: _vm.columns["agence"].prop,
                          width: _vm.colWidth("agence"),
                          sortable: "custom"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  scope.row.agence
                                    ? _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            scope.row.agence.chan_description
                                          )
                                        )
                                      ])
                                    : _vm._e()
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3400037533
                        )
                      }),
                      _c("el-table-column", {
                        key: _vm.columns["oppo_service"].key,
                        attrs: {
                          filters: _vm.showColumnFilters
                            ? _vm.filterCategorie
                            : undefined,
                          "filter-method": _vm.showColumnFilters
                            ? _vm.handleFilter
                            : undefined,
                          label: _vm.columns["oppo_service"].label,
                          prop: _vm.columns["oppo_service"].prop,
                          width: _vm.colWidth("oppo_service"),
                          sortable: "custom"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("div", [
                                    _vm._v(_vm._s(scope.row["oppo_service"]))
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1872118107
                        )
                      }),
                      _c("el-table-column", {
                        key: _vm.columns["oppo_stage"].key,
                        attrs: {
                          filters: _vm.showColumnFilters
                            ? _vm.filterStage
                            : undefined,
                          "filter-method": _vm.showColumnFilters
                            ? _vm.handleFilter
                            : undefined,
                          label: _vm.columns["oppo_stage"].label,
                          prop: _vm.columns["oppo_stage"].prop,
                          width: _vm.colWidth("oppo_stage"),
                          sortable: "custom"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("div", [
                                    _vm._v(_vm._s(scope.row["oppo_stage"]))
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3627632034
                        )
                      }),
                      _c("el-table-column", {
                        key: _vm.columns["nb_documents"].key,
                        attrs: {
                          label: _vm.columns["nb_documents"].label,
                          prop: _vm.columns["nb_documents"].prop,
                          width: _vm.colWidth("nb_documents"),
                          sortable: "custom"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("div", [
                                    _vm._v(_vm._s(scope.row["nb_documents"]))
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          69658134
                        )
                      }),
                      _c("el-table-column", {
                        key: _vm.columns["nb_suivis"].key,
                        attrs: {
                          label: _vm.columns["nb_suivis"].label,
                          prop: _vm.columns["nb_suivis"].prop,
                          width: _vm.colWidth("nb_suivis"),
                          sortable: "custom"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("div", [
                                    _vm._v(_vm._s(scope.row["nb_suivis"]))
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1556359113
                        )
                      }),
                      _c("el-table-column", {
                        key: 20,
                        attrs: {
                          width: _vm.windowWidth < 768 ? 25 : 100,
                          align: "right",
                          fixed: "right"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        icon: "el-icon-files",
                                        title: "Détail",
                                        type: "text"
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.handleDetail(scope.row)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "hidden-xs-only" },
                                        [_vm._v("Détail")]
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3993761660
                        )
                      })
                    ],
                    1
                  )
                : _c("div", [_vm._v("Aucune donnée disponible.")])
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              staticClass: "dialog",
              attrs: { title: "Détail", visible: _vm.dialogDetail.visible },
              on: {
                "update:visible": function($event) {
                  return _vm.$set(_vm.dialogDetail, "visible", $event)
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "app-bloc-info" },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: _vm.dialogDetailWidth[0] } },
                                [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(_vm.columns["oppo_opened"].label)
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.dialogDetailWidth[1] } },
                                [
                                  _c("strong", [
                                    _c("i", { staticClass: "el-icon-date" }),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("toDateTime")(
                                            _vm.row["oppo_opened"]
                                          )
                                        )
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: _vm.dialogDetailWidth[0] } },
                                [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.columns["oppo_reference"].labelLong
                                      )
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.dialogDetailWidth[1] } },
                                [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.row["oppo_reference"]))
                                  ])
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: _vm.dialogDetailWidth[0] } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.columns["oppo_description"].label
                                    )
                                  )
                                ]
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.dialogDetailWidth[1] } },
                                [_vm._v(_vm._s(_vm.row["oppo_description"]))]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: _vm.dialogDetailWidth[0] } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.columns["door_matricule"].label)
                                  )
                                ]
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.dialogDetailWidth[1] } },
                                [
                                  _vm.row.door_matricule
                                    ? [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.row.door_matricule) +
                                            " "
                                        )
                                      ]
                                    : _vm.row.porte
                                    ? [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.row.porte.door_matricule
                                            ) +
                                            " "
                                        )
                                      ]
                                    : _vm._e()
                                ],
                                2
                              )
                            ],
                            1
                          ),
                          _vm.row.porte
                            ? _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    {
                                      attrs: { span: _vm.dialogDetailWidth[0] }
                                    },
                                    [_vm._v("Porte")]
                                  ),
                                  _c(
                                    "el-col",
                                    {
                                      attrs: { span: _vm.dialogDetailWidth[1] }
                                    },
                                    [_vm._v(_vm._s(_vm.row.porte.door_name))]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: _vm.dialogDetailWidth[0] } },
                                [_vm._v("Lieu d'installation")]
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.dialogDetailWidth[1] } },
                                [_vm._v(_vm._s(_vm.row.addr_city))]
                              )
                            ],
                            1
                          ),
                          _vm.row.agence
                            ? _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    {
                                      attrs: { span: _vm.dialogDetailWidth[0] }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.columns["agence"].label)
                                      )
                                    ]
                                  ),
                                  _c(
                                    "el-col",
                                    {
                                      attrs: { span: _vm.dialogDetailWidth[1] }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.row.agence.chan_description)
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: _vm.dialogDetailWidth[0] } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.columns["oppo_service"].label)
                                  )
                                ]
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.dialogDetailWidth[1] } },
                                [_vm._v(_vm._s(_vm.row["oppo_service"]))]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: _vm.dialogDetailWidth[0] } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.columns["oppo_stage"].label)
                                  )
                                ]
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.dialogDetailWidth[1] } },
                                [_vm._v(_vm._s(_vm.row["oppo_stage"]))]
                              )
                            ],
                            1
                          ),
                          _vm.row.oppo_service_value === "ctrtagence"
                            ? [
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          span: _vm.dialogDetailWidth[0]
                                        }
                                      },
                                      [_vm._v("Date visite n°1 :")]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          span: _vm.dialogDetailWidth[1]
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-date"
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("toDate")(
                                                _vm.row.oppo_datevisite1
                                              )
                                            )
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          span: _vm.dialogDetailWidth[0]
                                        }
                                      },
                                      [_vm._v("Date visite n°2 :")]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          span: _vm.dialogDetailWidth[1]
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-date"
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("toDate")(
                                                _vm.row.oppo_datevisite2
                                              )
                                            )
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            : _vm._e(),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: _vm.dialogDetailWidth[0] } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.columns["nb_documents"].labelLong
                                    )
                                  )
                                ]
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.dialogDetailWidth[1] } },
                                [_vm._v(_vm._s(_vm.row.nb_documents))]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: _vm.dialogDetailWidth[0] } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.columns["nb_suivis"].labelLong)
                                  )
                                ]
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.dialogDetailWidth[1] } },
                                [_vm._v(_vm._s(_vm.row.nb_suivis))]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: _vm.dialogDetailWidth[0] } },
                                [_vm._v("Travaux effectués")]
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.dialogDetailWidth[1] } },
                                [
                                  _vm.row.travaux && _vm.row.travaux.length > 0
                                    ? _c(
                                        "div",
                                        _vm._l(_vm.row.travaux, function(
                                          item,
                                          index
                                        ) {
                                          return _c(
                                            "div",
                                            {
                                              key: index,
                                              staticStyle: {
                                                "margin-bottom": "6px"
                                              }
                                            },
                                            [
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("toDateTime")(
                                                      item.date
                                                    )
                                                  )
                                                )
                                              ]),
                                              _c("br"),
                                              _vm._v(
                                                " " + _vm._s(item.note) + " "
                                              )
                                            ]
                                          )
                                        }),
                                        0
                                      )
                                    : _vm._e()
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }