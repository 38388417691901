"use strict";

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.object.to-string");

require("/var/www/lacroix_dev/extranet/node_modules/@vue/babel-preset-app/node_modules/core-js/modules/es.array.iterator.js");

require("/var/www/lacroix_dev/extranet/node_modules/@vue/babel-preset-app/node_modules/core-js/modules/es.promise.js");

require("/var/www/lacroix_dev/extranet/node_modules/@vue/babel-preset-app/node_modules/core-js/modules/es.object.assign.js");

require("/var/www/lacroix_dev/extranet/node_modules/@vue/babel-preset-app/node_modules/core-js/modules/es.promise.finally.js");

var _vue = _interopRequireDefault(require("vue"));

require("normalize.css/normalize.css");

require("element-ui/lib/theme-chalk/display.css");

var _elementUi = _interopRequireDefault(require("element-ui"));

require("element-ui/lib/theme-chalk/reset.css");

require("element-ui/lib/theme-chalk/index.css");

var _fr = _interopRequireDefault(require("element-ui/lib/locale/lang/fr"));

var _vueDataTables = _interopRequireDefault(require("vue-data-tables"));

var _vueDynamicFormComponent = _interopRequireDefault(require("vue-dynamic-form-component"));

var _vueImageUploadResize = _interopRequireDefault(require("vue-image-upload-resize"));

var _elementUiElTableDraggable = _interopRequireDefault(require("element-ui-el-table-draggable"));

var _vueScrollto = _interopRequireDefault(require("vue-scrollto"));

require("@/styles/index.scss");

var _i18n = _interopRequireDefault(require("./i18n"));

var _App = _interopRequireDefault(require("./App"));

var _store = _interopRequireDefault(require("./store"));

var _router = _interopRequireDefault(require("./router"));

require("@/icons");

require("@/permission");

require("@/helpers/directives");

require("@/helpers/filters");

var _vueWindowSize = _interopRequireDefault(require("vue-window-size"));

// A modern alternative to CSS resets
// lang i18n
// import DataTables and DataTablesServer together
// global css
// icon
// permission control
// all directives
// all filters
// Resize plugin
_vue.default.use(_vueWindowSize.default); // set ElementUI lang to FR


_vue.default.use(_elementUi.default, {
  locale: _fr.default
});

_vue.default.use(_vueDataTables.default);

_vue.default.use(_vueDynamicFormComponent.default);

_vue.default.use(_vueImageUploadResize.default);

_vue.default.use(_vueScrollto.default, {
  container: 'body',
  duration: 500,
  easing: 'ease',
  offset: -60,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
});

_vue.default.component('ElTableDraggable', _elementUiElTableDraggable.default);

_vue.default.config.disableNoTranslationWarning = true;
_vue.default.config.productionTip = false;
_vue.default.prototype.$env = {
  isDev: process.env.NODE_ENV === 'development',
  isStage: process.env.NODE_ENV === 'staging',
  isProd: process.env.NODE_ENV === 'production',
  showAppDebug: process.env.NODE_ENV === 'development' && process.env.VUE_APP_SHOW_DEBUG && process.env.VUE_APP_SHOW_DEBUG === 'true',
  presetAppLogin: process.env.NODE_ENV === 'development' && process.env.VUE_APP_PRESET_LOGIN && process.env.VUE_APP_PRESET_LOGIN === 'true'
};
_vue.default.prototype.$apiEndpoint = process.env.VUE_APP_BASE_API;
_vue.default.prototype.$remotePublicUrl = process.env.VUE_APP_REMOTE_PUBLIC;
_vue.default.prototype.$remoteFacturePublicUrl = process.env.VUE_APP_REMOTE_PUBLIC + '/uploads/factures/';

_vue.default.prototype.$loader = function (value) {
  var _this = this;

  return new Promise(function (resolve) {
    var options = {
      fullscreen: true,
      lock: true,
      // background: 'transparent',
      background: 'rgba(0, 0, 0, 0.8)',
      text: 'Chargement en cours...',
      target: 'section#appMain'
    };

    if (value) {
      _elementUi.default.Loading.service(options);
    } else {
      _this.$loading().close();
    }

    resolve();
  });
}; // eslint-disable-next-line


new _vue.default({
  el: '#app',
  i18n: _i18n.default,
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});