var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "p401" }, [
      _c("div", { staticClass: "center" }, [
        _c("h1", [_vm._v("404")]),
        _c("h2", [_vm._v("Page non trouvée")]),
        _c("p", [
          _vm._v(
            "La page demandée n'existe pas, ou n'existe plus ou a été déplacée"
          )
        ]),
        _c("span", { staticClass: "btn" }, [
          _c("a", { attrs: { href: "" } }, [_vm._v("ACCUEIL")])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }