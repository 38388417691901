"use strict";

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.concat");

require("core-js/modules/es.array.find");

require("core-js/modules/es.array.for-each");

require("core-js/modules/es.array.includes");

require("core-js/modules/es.array.index-of");

require("core-js/modules/es.array.map");

require("core-js/modules/es.array.some");

require("core-js/modules/es.number.constructor");

require("core-js/modules/es.object.keys");

require("core-js/modules/es.object.values");

require("core-js/modules/es.string.includes");

require("core-js/modules/web.dom-collections.for-each");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/objectSpread2"));

var _vuex = require("vuex");

var _utils = require("@/helpers/utils");

var _datatableMixin = _interopRequireDefault(require("@/mixins/datatableMixin"));

var _exportListMixin = _interopRequireDefault(require("@/mixins/exportListMixin"));

var _moment = _interopRequireDefault(require("moment"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'InterventionAllList',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  components: {},
  // 6. Composition
  // --
  mixins: [_datatableMixin.default, _exportListMixin.default],
  // 7. Interface
  // --
  props: {
    externalsFilters: {
      type: Object,
      required: false,
      default: function _default() {}
    },
    hideReturnBtn: {
      type: Boolean,
      required: false,
      default: false
    },
    limit: {
      type: Number,
      required: false,
      default: null
    },
    showColumnFilters: {
      type: Boolean,
      required: false,
      default: false
    },
    showFilters: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      columns: {
        comp_name: {
          prop: 'comp_name',
          label: 'Site'
        },
        oppo_opened: {
          prop: 'oppo_opened',
          label: 'Date',
          width: 150
        },
        oppo_reference: {
          prop: 'oppo_reference',
          label: 'Réf.',
          labelLong: 'Référence',
          width: 100
        },
        oppo_description: {
          prop: 'oppo_description',
          label: 'Description'
        },
        oppo_responsable: {
          prop: 'oppo_responsable',
          label: 'Resp. dossier',
          labelLong: 'Responsable dossier',
          width: 150
        },
        door_matricule: {
          prop: 'door_matricule',
          label: 'Matricule',
          width: 125
        },
        addr_city: {
          prop: 'addr_city',
          label: 'Installation',
          width: 150
        },
        agence: {
          prop: 'agence',
          label: 'Agence',
          width: 125
        },
        oppo_service: {
          prop: 'oppo_service',
          label: 'Catégorie',
          width: 150
        },
        oppo_stage: {
          prop: 'oppo_stage',
          label: 'Étape',
          width: 200
        },
        door_type: {
          prop: 'door_type',
          label: 'Type porte',
          width: 150
        },
        // oppo_status: { prop: 'oppo_status', label: 'Statut', width: 150 },
        // oppo_datevisite1: { prop: 'oppo_datevisite1', label: 'Visite 1', labelLong: 'Date visite n°1', width: 100 },
        // oppo_datevisite2: { prop: 'oppo_datevisite2', label: 'Visite 2', labelLong: 'Date visite n°2', width: 100 },
        nb_documents: {
          prop: 'nb_documents',
          label: 'Docs',
          labelLong: 'Nb Documents',
          width: 100
        },
        nb_suivis: {
          prop: 'nb_suivis',
          label: 'Suivis',
          labelLong: 'Nb Suivis',
          width: 100
        },
        drapeaux_praxedo: {
          prop: 'drapeaux_praxedo',
          label: 'Drapeaux'
        }
      },
      dataTableServer: true,
      dataTableFilters: {
        page: 1,
        pageSize: 20
      },
      dataTotal: 0,
      filters: [{
        value: '',
        prop: ['oppo_opened', 'oppo_reference', 'oppo_description', 'oppo_responsable', 'porte'],
        filterFn: function filterFn(row, filter) {
          // return filter.value.includes(row.agence.chan_description)
          // console.log('row', row)
          return Object.keys(row).some(function (prop) {
            // console.log('My prop', prop)
            switch (prop) {
              case 'porte':
                // console.log('filtre sur la porte : matricule')
                return row.porte !== null && String(row.porte.door_matricule).toLowerCase().indexOf(filter.value) > -1;

              /*
              case 'tva':
                return String(toPercentage(row[prop].taux)).indexOf(filter.value) > -1
              case 'numero':
              case 'libelle':
                return String(row[prop]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1
              */

              case 'oppo_opened':
                if (!row[prop] || typeof row[prop] === 'undefined') {
                  return false;
                }

                return (0, _moment.default)(String(row[prop])).format('DD/MM/YYYY').indexOf(filter.value) > -1;

              /*
              case 'montant':
              case 'montant_regle':
              case 'mt_a_percevoir_ttc':
                return String(toCurrencyFilter(row[prop])).indexOf(filter.value.toLowerCase()) > -1
              case 'nb_reglement':
                return String(row[prop]).indexOf(filter.value.toLowerCase()) > -1
              */

              default:
                // return String(row[prop]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1
                return false;
            }
          });
        }
      }, // Agence
      {
        value: '',
        prop: ['agence'],
        filterFn: function filterFn(row, filter) {
          return filter.value.includes(row.agence.chan_description);
        }
      }, // Catégorie (oppo_service et oppo_service_value)
      {
        value: '',
        prop: ['oppo_service'],
        filterFn: function filterFn(row, filter) {
          return filter.value.includes(row.oppo_service_value);
        }
      },
      /*
      // Statut (oppo_status et oppo_status_value)
      {
        value: '',
        prop: ['oppo_status'],
        filterFn: (row, filter) => {
          return filter.value.includes(row.oppo_status_value)
        }
      },
      */
      // Étape (oppo_stage et oppo_stage_value)
      {
        value: '',
        prop: ['oppo_stage'],
        filterFn: function filterFn(row, filter) {
          return filter.value.includes(row.oppo_stage_value);
        }
      }, // Filtre (>= date de début de période)
      {
        value: '',
        prop: ['oppo_opened'],
        filterFn: function filterFn(row, filter) {
          return Object.keys(row).some(function (prop) {
            switch (prop) {
              case 'oppo_opened':
                if (!row[prop] || typeof row[prop] === 'undefined') {
                  return false;
                }

                if (filter.value === null) {
                  return true;
                } // console.log(prop + ' : ' + moment(String(row[prop])).format('YYYY-MM-DD'))


                return (0, _moment.default)(String(row[prop]), 'YYYY-MM-DD').isSameOrAfter(filter.value);

              default:
                return false;
            }
          });
        }
      }, // Filtre (<= date de fin de période)
      {
        value: '',
        prop: ['oppo_opened'],
        filterFn: function filterFn(row, filter) {
          return Object.keys(row).some(function (prop) {
            // console.log(`filtre n°3 (index #2) : "${filter.value}"`)
            switch (prop) {
              case 'oppo_opened':
                if (!row[prop] || typeof row[prop] === 'undefined') {
                  return false;
                }

                if (filter.value === null) {
                  return true;
                } // console.log(prop + ' : ' + moment(String(row[prop])).format('YYYY-MM-DD'))


                return (0, _moment.default)(String(row[prop]), 'YYYY-MM-DD').isSameOrBefore(filter.value);

              default:
                return false;
            }
          });
        }
      }],
      filterAgence: [],
      filterCategorie: [],
      filterStage: [],
      filterStatus: [],
      // pour filtrer les factures par date
      filterDateMin: null,
      filterDateMax: null,
      mixinEvents: {
        export: 'INTERVENTION_EXPORT',
        exported: 'INTERVENTION_EXPORTED'
      }
    };
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)('crm', ['getInterventionsBy'])), {}, {
    data: function data() {
      var data = this.getInterventionsBy();
      this.generateFilters(data);
      return data;
    },
    dateFormat: function dateFormat() {
      return 'dd/MM/yyyy';
    },
    searchPayload: function searchPayload() {
      var payload = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.externalsFilters), {}, {
        dataTableFilters: this.dataTableFilters
      });
      return payload;
    }
  }),
  // 9. Évènements
  // --
  mounted: function mounted() {
    // Préparation des colonnes du dataTable
    this.prepareColumns(); // Chargement des données

    this.loadData(this.searchPayload);
  },
  watch: {
    'externalsFilters': function externalsFilters(newVal, oldVal) {
      // Relancer le chargement des données
      if (newVal.numero !== oldVal.numero || newVal.type !== oldVal.type || newVal.typePorte !== oldVal.typePorte || newVal.dateDebut !== oldVal.dateDebut || newVal.dateFin !== oldVal.dateFin) {
        // on réinitialise les filtres du dataTable pour une recherche externe
        // sinon on pourrait être bloquée en page n°2 d'un tuple n'ayant qu'une page
        this.dataTableFilters.page = 1;
        this.dataTableFilters.pageSize = 20;
        this.loadData(this.searchPayload);
      }
    }
  },
  // 10. Propriétés non réactives
  // --
  methods: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapActions)('crm', ['getAllIntervention'])), {}, {
    generateFilters: function generateFilters(data) {
      if (this.showFilters !== true) {
        return;
      } // ---
      // Initialisation des valeurs des filtres de colonnes


      var filterAgence = [];
      var filterCategorie = [];
      var filterStage = []; // const filterStatus = []

      data.forEach(function (row) {
        // Initialisation des valeurs du filtre pour la colonne "agence"
        if (!filterAgence.find(function (x) {
          return x.value === row.agence.chan_description;
        })) {
          var item = {
            text: row.agence.chan_description ? row.agence.chan_description : 'n/c',
            value: row.agence.chan_description
          };
          filterAgence.push(item);
        } // Initialisation des valeurs du filtre pour la colonne "catégorie"


        if (!filterCategorie.find(function (x) {
          return x.value === row.oppo_service_value;
        })) {
          var _item = {
            text: row.oppo_service ? row.oppo_service : 'n/c',
            value: row.oppo_service_value
          };
          filterCategorie.push(_item);
        }
        /*
        // Initialisation des valeurs du filtre pour la colonne "statut"
        if (!filterStatus.find(x => x.value === row.oppo_status_value)) {
          const item = {
            text: (row.oppo_status ? row.oppo_status : 'n/c'),
            value: row.oppo_status_value
          }
          filterStatus.push(item)
        }
        */
        // Initialisation des valeurs du filtre pour la colonne "stage"


        if (!filterStage.find(function (x) {
          return x.value === row.oppo_stage_value;
        })) {
          var _item2 = {
            text: row.oppo_stage ? row.oppo_stage : 'n/c',
            value: row.oppo_stage_value
          };
          filterStage.push(_item2);
        }
      }); // Tri alphabétique

      filterAgence.sort(_utils.sortSelectItems);
      filterCategorie.sort(_utils.sortSelectItems);
      filterStage.sort(_utils.sortSelectItems); // filterStatus.sort(sortSelectItems)
      // Assignation des filtres

      this.setFilter('agence', filterAgence);
      this.setFilter('oppo_service', filterCategorie);
      this.setFilter('oppo_stage', filterStage); // this.setFilter('oppo_status', filterStatus)
    },
    getSummaries: function getSummaries(param) {
      var columns = param.columns,
          data = param.data;
      var sums = [];
      columns.forEach(function (column, index) {
        if (column.property === 'oppo_opened') {
          sums[index] = 'Total';
          return;
        }

        if (!['nb_documents', 'nb_suivis'].includes(column.property)) {
          sums[index] = '';
          return;
        }

        var values = data.map(function (item) {
          return Number(item[column.property]);
        });
        sums[index] = (0, _utils.getTotal)(values);
      });
      return sums;
    },
    handleChangeDateMin: function handleChangeDateMin(date) {
      this.filterDateMin = date;
    },
    handleChangeDateMax: function handleChangeDateMax(date) {
      this.filterDateMax = date;
    },
    handleFilter: function handleFilter(value, row, column) {
      var property = column.property;

      switch (property) {
        // case 'tva':
        //   return toPercentage(row[property].taux) === value
        default:
          return row[property] === value;
      }
    },
    handleDetail: function handleDetail(row) {
      this.$router.push({
        name: 'InterventionView',
        params: {
          interventionId: row.oppo_opportunityid
        }
      });
      /*
      switch (this.$route.name) {
        case 'PorteView':
        case 'InterventionPorteModule':
          this.$router.push({ name: 'InterventionPorteView', params: { siteId: this.$route.params.siteId, porteId: this.$route.params.porteId, interventionId: row.oppo_opportunityid } })
          break
        case 'InterventionModule':
        default:
          this.$router.push({ name: 'InterventionSiteView', params: { siteId: this.$route.params.siteId, interventionId: row.oppo_opportunityid } })
          break
      }
      */
    },
    handleQueryChange: function handleQueryChange(payload) {
      // Synchronisation de la page active et du nombre d'item /page
      // this.currentPage = payload.page
      // this.pageSize = payload.pageSize
      // Tous les événements sauf l'initialisation du dataTable
      if (payload.type !== 'init') {
        // Changement du nbre d'item par page
        if (payload.type === 'size') {
          var modulo = this.dataTotal % payload.pageSize;
          var limit = (this.dataTotal - modulo) / payload.pageSize;
          limit += modulo > 0 ? 1 : 0; // Si la page actuelle n'existe plus dans la nouvelle pagination on ne fait pas d'appel API
          // c'est la dataTable qui va corriger seul pour faire l'appel correct

          if (limit < payload.page) {
            return;
          }
        }

        this.dataTableFilters = payload;
        this.loadData(this.searchPayload); // this.loadData({ dataTableFilters: payload })
        // this.loadData(payload)
        // EventBus.$emit('SITE_LIST_RELOAD', payload)
      }
    },
    loadData: function loadData() {
      var _this = this;

      var payload = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      // console.log('loadAllData: payload', payload)
      this.dataLoaded = false;
      this.$loader(true);
      this.getAllIntervention({
        filters: payload
      }).then(function (result) {
        console.log("Liste des ".concat(payload.dataTableFilters.pageSize, " interventions de la page ").concat(payload.dataTableFilters.page, " charg\xE9s sur ").concat(result.total, " au total"));
        _this.dataTotal = result.total;
        _this.dataLoaded = true;

        _this.$loader(false);
      }).catch(function (err) {
        console.error(err);

        _this.$loader(false);
      });
    },
    prepareColumns: function prepareColumns() {
      Object.values(this.columns).map(function (item, index) {
        item.key = index;
      });
    },
    setFilter: function setFilter(prop, filter) {
      switch (prop) {
        case 'agence':
          this.filterAgence = filter;
          break;

        /*
        case 'oppo_status':
          this.filterStatus = filter
          break
        */

        case 'oppo_stage':
          this.filterStage = filter;
          break;

        case 'oppo_service':
          this.filterCategorie = filter;
          break;
      }
    }
  }) // 11. Rendu
  // --

};
exports.default = _default2;