var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dataLoaded
    ? _c(
        "div",
        [
          !_vm.hideReturnBtn
            ? _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { icon: "el-icon-arrow-left", size: "mini" },
                          on: {
                            click: function($event) {
                              return _vm.$router.go(-1)
                            }
                          }
                        },
                        [_vm._v("Retour")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "data-tables",
            {
              ref: "dataTable",
              attrs: {
                data: _vm.data,
                filters: _vm.filters,
                layout: _vm.datatable.layout,
                "pagination-props": _vm.datatable.paginationProps,
                "table-props": Object.assign({}, _vm.datatable.tableProps, {
                  defaultSort: { prop: "case_createddate", order: "descending" }
                }),
                total: _vm.total
              },
              on: {
                "row-click": function($event) {
                  if ($event.target !== $event.currentTarget) {
                    return null
                  }
                  return _vm.handleCurrentChange($event)
                }
              }
            },
            [
              _vm.showFilters
                ? _c(
                    "el-row",
                    { attrs: { slot: "tool", gutter: 10 }, slot: "tool" },
                    [
                      _c(
                        "fieldset",
                        {
                          staticStyle: {
                            margin: "0 0 20px 0",
                            border: "1px solid #EBEEF5"
                          }
                        },
                        [
                          _c("legend", { staticStyle: { padding: "20px" } }, [
                            _vm._v(" Filtres ")
                          ]),
                          _c(
                            "el-col",
                            {
                              staticClass: "filter-item",
                              staticStyle: { "white-space": "nowrap" },
                              attrs: { align: "left", xs: 24, sm: 12, lg: 4 }
                            },
                            [
                              _c("div", [_vm._v("Porte (matricule) :")]),
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    "collapse-tags": "",
                                    multiple: "",
                                    placeholder: "Filtrer par porte",
                                    size: "mini"
                                  },
                                  model: {
                                    value: _vm.filters[1].value,
                                    callback: function($$v) {
                                      _vm.$set(_vm.filters[1], "value", $$v)
                                    },
                                    expression: "filters[1].value"
                                  }
                                },
                                _vm._l(_vm.filterPorte, function(item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.text,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            {
                              staticClass: "filter-item",
                              staticStyle: { "white-space": "nowrap" },
                              attrs: { align: "left", xs: 24, sm: 12, lg: 4 }
                            },
                            [
                              _c("div", [_vm._v("Statut :")]),
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    "collapse-tags": "",
                                    multiple: "",
                                    placeholder: "Filtrer par statut",
                                    size: "mini"
                                  },
                                  model: {
                                    value: _vm.filters[2].value,
                                    callback: function($$v) {
                                      _vm.$set(_vm.filters[2], "value", $$v)
                                    },
                                    expression: "filters[2].value"
                                  }
                                },
                                _vm._l(_vm.filterStatus, function(item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.text,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            {
                              staticClass: "filter-item",
                              staticStyle: { "white-space": "nowrap" },
                              attrs: { align: "left", xs: 24, sm: 12, lg: 4 }
                            },
                            [
                              _c("div", [_vm._v("Du :")]),
                              _c("el-date-picker", {
                                attrs: {
                                  format: _vm.dateFormat,
                                  clearable: "",
                                  placeholder: "",
                                  size: "mini",
                                  type: "date",
                                  "value-format": "yyyy-MM-dd",
                                  "picker-options": { firstDayOfWeek: 1 }
                                },
                                on: { change: _vm.handleChangeDateMin },
                                model: {
                                  value: _vm.filters[4].value,
                                  callback: function($$v) {
                                    _vm.$set(_vm.filters[4], "value", $$v)
                                  },
                                  expression: "filters[4].value"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            {
                              staticClass: "filter-item",
                              staticStyle: { "white-space": "nowrap" },
                              attrs: { align: "left", xs: 24, sm: 12, lg: 4 }
                            },
                            [
                              _c("div", [_vm._v("au :")]),
                              _c("el-date-picker", {
                                attrs: {
                                  format: _vm.dateFormat,
                                  clearable: "",
                                  placeholder: "",
                                  size: "mini",
                                  type: "date",
                                  "value-format": "yyyy-MM-dd",
                                  "picker-options": { firstDayOfWeek: 1 }
                                },
                                on: { change: _vm.handleChangeDateMax },
                                model: {
                                  value: _vm.filters[5].value,
                                  callback: function($$v) {
                                    _vm.$set(_vm.filters[5], "value", $$v)
                                  },
                                  expression: "filters[5].value"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            {
                              staticClass: "filter-item",
                              staticStyle: { "white-space": "nowrap" },
                              attrs: {
                                align:
                                  _vm.windowWidth < 1200 ? "left" : "right",
                                xs: 24,
                                sm: 12,
                                lg: 4
                              }
                            },
                            [
                              _c("div", [
                                _c(
                                  "span",
                                  { staticClass: "hidden-sm-and-up" },
                                  [_vm._v("Recherche (toutes colonnes) :")]
                                ),
                                _c("br")
                              ]),
                              _c("el-input", {
                                attrs: {
                                  clearable: "",
                                  placeholder: "Rechercher",
                                  size: "mini"
                                },
                                model: {
                                  value: _vm.filters[0].value,
                                  callback: function($$v) {
                                    _vm.$set(_vm.filters[0], "value", $$v)
                                  },
                                  expression: "filters[0].value"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e(),
              _vm.total > 0
                ? _c(
                    "div",
                    [
                      _c("el-table-column", {
                        key: _vm.columns["case_createddate"].key,
                        attrs: {
                          label: _vm.columns["case_createddate"].label,
                          prop: _vm.columns["case_createddate"].prop,
                          width: _vm.colWidth("case_createddate"),
                          sortable: "custom"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("toDateTime")(
                                          scope.row["case_createddate"]
                                        )
                                      )
                                    )
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          939612389
                        )
                      }),
                      _c("el-table-column", {
                        key: _vm.columns["case_referenceid"].key,
                        attrs: {
                          label: _vm.columns["case_referenceid"].label,
                          prop: _vm.columns["case_referenceid"].prop,
                          width: _vm.colWidth("case_referenceid"),
                          sortable: "custom"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(scope.row["case_referenceid"])
                                    )
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1164512916
                        )
                      }),
                      _c("el-table-column", {
                        key: _vm.columns["user_assigned"].key,
                        attrs: {
                          label: _vm.columns["user_assigned"].label,
                          prop: _vm.columns["user_assigned"].prop,
                          width: _vm.colWidth("user_assigned"),
                          sortable: "custom"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("div", [
                                    _vm._v(_vm._s(scope.row["user_assigned"]))
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          856641047
                        )
                      }),
                      _c("el-table-column", {
                        key: _vm.columns["case_description"].key,
                        attrs: {
                          label: _vm.columns["case_description"].label,
                          prop: _vm.columns["case_description"].prop,
                          width: _vm.colWidth("case_description"),
                          sortable: "custom"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(scope.row["case_description"])
                                    )
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1605147316
                        )
                      }),
                      _c("el-table-column", {
                        key: _vm.columns["case_status"].key,
                        attrs: {
                          filters: _vm.showColumnFilters
                            ? _vm.filterStatus
                            : undefined,
                          "filter-method": _vm.showColumnFilters
                            ? _vm.handleFilter
                            : undefined,
                          label: _vm.columns["case_status"].label,
                          prop: _vm.columns["case_status"].prop,
                          width: _vm.colWidth("case_status"),
                          sortable: "custom"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("div", [
                                    _vm._v(_vm._s(scope.row["case_status"]))
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1775655398
                        )
                      }),
                      _c("el-table-column", {
                        key: _vm.columns["door_matricule"].key,
                        attrs: {
                          filters: _vm.showColumnFilters
                            ? _vm.filterPorte
                            : undefined,
                          "filter-method": _vm.showColumnFilters
                            ? _vm.handleFilter
                            : undefined,
                          label: _vm.columns["door_matricule"].label,
                          prop: _vm.columns["door_matricule"].prop,
                          width: _vm.colWidth("door_matricule"),
                          sortable: "custom"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  scope.row.door_matricule
                                    ? _c("div", [
                                        _vm._v(_vm._s(scope.row.door_matricule))
                                      ])
                                    : scope.row.porte
                                    ? _c("div", [
                                        _vm._v(
                                          _vm._s(scope.row.porte.door_matricule)
                                        )
                                      ])
                                    : _vm._e()
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2239530552
                        )
                      }),
                      _c("el-table-column", {
                        key: _vm.columns.addr_city.key,
                        attrs: {
                          label: _vm.columns.addr_city.label,
                          prop: _vm.columns.addr_city.prop,
                          width: _vm.colWidth("addr_city"),
                          sortable: "custom"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("div", [
                                    _vm._v(_vm._s(scope.row.addr_city))
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1324310842
                        )
                      }),
                      _c("el-table-column", {
                        key: _vm.columns["nb_historiques"].key,
                        attrs: {
                          label: _vm.columns["nb_historiques"].label,
                          prop: _vm.columns["nb_historiques"].prop,
                          width: _vm.colWidth("nb_historiques"),
                          sortable: "custom"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("div", [
                                    _vm._v(_vm._s(scope.row["nb_historiques"]))
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2950515210
                        )
                      }),
                      _c("el-table-column", {
                        key: _vm.columns["nb_documents"].key,
                        attrs: {
                          label: _vm.columns["nb_documents"].label,
                          prop: _vm.columns["nb_documents"].prop,
                          width: _vm.colWidth("nb_documents"),
                          sortable: "custom"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("div", [
                                    _vm._v(_vm._s(scope.row["nb_documents"]))
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          69658134
                        )
                      }),
                      _c("el-table-column", {
                        key: 11,
                        attrs: {
                          width: _vm.windowWidth < 768 ? 25 : 100,
                          align: "right",
                          fixed: "right"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        icon: "el-icon-files",
                                        title: "Détail",
                                        type: "text"
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.$router.push({
                                            name: "TicketView",
                                            params: {
                                              ticketId: scope.row.case_caseid
                                            }
                                          })
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "hidden-xs-only" },
                                        [_vm._v("Détail")]
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2401690473
                        )
                      })
                    ],
                    1
                  )
                : _c("div", [_vm._v("Aucune donnée disponible.")])
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              staticClass: "dialog",
              attrs: { title: "Détail", visible: _vm.dialogDetail.visible },
              on: {
                "update:visible": function($event) {
                  return _vm.$set(_vm.dialogDetail, "visible", $event)
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "app-bloc-info" },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: _vm.dialogDetailWidth[0] } },
                                [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.columns["case_referenceid"].label
                                      )
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.dialogDetailWidth[1] } },
                                [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.row["case_referenceid"]))
                                  ])
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: _vm.dialogDetailWidth[0] } },
                                [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.columns["case_createddate"].label
                                      )
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.dialogDetailWidth[1] } },
                                [
                                  _c("strong", [
                                    _c("i", { staticClass: "el-icon-date" }),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("toDateTime")(
                                            _vm.row["case_createddate"]
                                          )
                                        )
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: _vm.dialogDetailWidth[0] } },
                                [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(_vm.columns["user_assigned"].label)
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.dialogDetailWidth[1] } },
                                [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.row["user_assigned"]))
                                  ])
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: _vm.dialogDetailWidth[0] } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.columns["case_description"].label
                                    )
                                  )
                                ]
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.dialogDetailWidth[1] } },
                                [_vm._v(_vm._s(_vm.row["case_description"]))]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: _vm.dialogDetailWidth[0] } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.columns["case_problemnote"].label
                                    )
                                  )
                                ]
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.dialogDetailWidth[1] } },
                                [
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.ln2br(_vm.row.case_problemnote)
                                      )
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: _vm.dialogDetailWidth[0] } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.columns["case_status"].label)
                                  )
                                ]
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.dialogDetailWidth[1] } },
                                [_vm._v(_vm._s(_vm.row["case_status"]))]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: _vm.dialogDetailWidth[0] } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.columns["door_matricule"].label)
                                  )
                                ]
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.dialogDetailWidth[1] } },
                                [
                                  _vm.row.door_matricule
                                    ? [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.row.door_matricule) +
                                            " "
                                        )
                                      ]
                                    : _vm.row.porte
                                    ? [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.row.porte.door_matricule
                                            ) +
                                            " "
                                        )
                                      ]
                                    : _vm._e()
                                ],
                                2
                              )
                            ],
                            1
                          ),
                          _vm.row.porte
                            ? _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    {
                                      attrs: { span: _vm.dialogDetailWidth[0] }
                                    },
                                    [_vm._v("Porte")]
                                  ),
                                  _c(
                                    "el-col",
                                    {
                                      attrs: { span: _vm.dialogDetailWidth[1] }
                                    },
                                    [_vm._v(_vm._s(_vm.row.porte.door_name))]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: _vm.dialogDetailWidth[0] } },
                                [_vm._v("Lieu d'installation")]
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.dialogDetailWidth[1] } },
                                [_vm._v(_vm._s(_vm.row.addr_city))]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: _vm.dialogDetailWidth[0] } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.columns["nb_historiques"].labelLong
                                    )
                                  )
                                ]
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.dialogDetailWidth[1] } },
                                [_vm._v(_vm._s(_vm.row.nb_historiques))]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: _vm.dialogDetailWidth[0] } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.columns["nb_documents"].labelLong
                                    )
                                  )
                                ]
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.dialogDetailWidth[1] } },
                                [_vm._v(_vm._s(_vm.row.nb_documents))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }