"use strict";

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/objectSpread2"));

var _StatInfos = _interopRequireDefault(require("../statistique/StatInfos"));

var _SiteModule = _interopRequireDefault(require("../site/SiteModule"));

var _vuex = require("vuex");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'DashboardModule',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  components: {
    StatInfos: _StatInfos.default,
    SiteModule: _SiteModule.default
  },
  // 6. Composition
  // --
  // 7. Interface
  // --
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {};
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)('crm', ['getStatsBy'])), {}, {
    stat: function stat() {
      return null; // this.getStatsBy(null)
    }
  }),
  // 9. Évènements
  // --
  mounted: function mounted() {
    this.forceSiteReloading();
  },
  // 10. Propriétés non réactives
  // --
  methods: (0, _objectSpread2.default)({}, (0, _vuex.mapActions)('crm', ['forceSiteReloading'])) // 11. Rendu
  // --

};
exports.default = _default;