"use strict";

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/objectSpread2"));

var _vuex = require("vuex");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'NotificationList',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  // 6. Composition
  // --
  // 7. Interface (interface du composant)
  // --
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      columns: [{
        prop: 'message',
        label: 'Message'
      }, {
        prop: 'date_creation',
        label: 'Date'
      }],
      filterReadStatus: [{
        text: 'Lu',
        value: 'read'
      }, {
        text: 'Non Lu',
        value: 'unread'
      }],
      filters: [{
        value: '',
        prop: ['message']
      }],
      multipleSelection: [],
      refresh: 0,
      total: 0
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['notifications', 'user'])),
  // 9. Évènements
  // --
  // 10. Propriétés non réactives
  // --
  methods: {
    colWidth: function colWidth(index) {
      // permet de gérer indépendamment la largeur de chaque colonne en fonction de leur index
      if (index === 1) {
        return 200;
      }
    },
    filterHandler: function filterHandler(value, row, column) {
      var property = column.property;

      switch (value) {
        case 'read':
          return row[property] !== null;

        case 'unread':
          return row[property] === null;
      }
    },
    getTargetLink: function getTargetLink(uri) {
      if (uri.substring(0, 2) === '#/') {
        return '_self';
      }

      return '_blank';
    },
    handleDeleteSelection: function handleDeleteSelection() {
      var _this = this;

      var msg = this.multipleSelection.length > 1 ? 'les lignes sélectionnées' : 'la ligne sélectionnée';
      this.$confirm('Voulez-vous supprimer ' + msg + ' ?', 'Confirmer la suppression', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Annuler',
        type: 'warning'
      }).then(function () {
        _this.$store.dispatch('notifications/markasdeleted', _this.multipleSelection);

        _this.refresh++;
      });
    },
    handleMarkAllAsRead: function handleMarkAllAsRead() {
      this.$store.dispatch('notifications/markallasread', this.user.id);
      this.refesh++;
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    }
  } // 11. Rendu
  // --

};
exports.default = _default;