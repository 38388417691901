"use strict";

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAll = getAll;
exports.deleteAll = deleteAll;

var _request = _interopRequireDefault(require("@/helpers/request"));

function getAll() {
  return (0, _request.default)({
    url: '/sys_sqllog',
    method: 'get'
  });
}

function deleteAll() {
  return (0, _request.default)({
    url: '/sys_sqllog',
    method: 'delete'
  });
}