"use strict";

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

require("core-js/modules/es.array.concat");

require("core-js/modules/es.array.for-each");

require("core-js/modules/web.dom-collections.for-each");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var apiUserHasCompany = _interopRequireWildcard(require("@/api/user_has_company"));

var _utils = require("@/helpers/utils");

var _vue = _interopRequireDefault(require("vue"));

var _vueSelectSides = _interopRequireDefault(require("vue-select-sides"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
_vue.default.use(_vueSelectSides.default, {
  locale: 'fr_FR'
});

var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'CrmCompanySelector',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  components: {
    vueSelectSides: _vueSelectSides.default
  },
  // 6. Composition
  // --
  // 7. Interface
  // --
  props: {
    // Filtre global pour ne voir que les liaisons d'un utilisateur
    user: Object
  },
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      dataLoaded: false,
      list: [],
      order: 'asc',
      selected: []
    };
  },
  // 9. Évènements
  // --
  created: function created() {
    var _this = this;

    this.$loader(true);
    apiUserHasCompany.getAllWithLinkByUser(this.$route.params.id).then(function (results) {
      results.recordset.forEach(function (x) {
        if (x.selected === true) {
          // console.log(`x.comp_companyid: '${x.comp_companyid}'`)
          _this.selected.push(x.comp_companyid);
        }

        _this.list.push({
          value: x.comp_companyid,
          label: "".concat(x.comp_codecomptable, " - ").concat(x.comp_name, " - ").concat((0, _utils.formatAddress)(x, 'text-w-separator'))
        });
      });
      _this.dataLoaded = true;

      _this.$loader(false);
    }).catch(function (err) {
      _this.$message(err);

      _this.$loader(false);
    });
  },
  // 10. Propriétés non réactives
  // --
  methods: {
    saveCrmLinks: function saveCrmLinks() {
      var _this2 = this;

      this.$loader(true);
      apiUserHasCompany.saveLinksForUser(this.selected, this.user.id).then(function (results) {
        _this2.$message('Mise à jour effectuée avec succès');

        _this2.$loader(false);
      }).catch(function (error) {
        _this2.$message(error);

        _this2.$loader(false);
      });
    }
  } // 11. Rendu
  // --

};
exports.default = _default;