"use strict";

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.object.to-string");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard"));

var routes = [{
  path: '/crm-channel',
  name: 'CrmChannel',
  order: 20,
  meta: {
    title: 'Sage CRM : Channel',
    icon: 'crm-channel'
  },
  redirect: {
    name: 'CrmChannelModule'
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/layout/empty'));
    });
  },
  children: [{
    path: '/crm/crm-channel',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/crm/crm_channel/CrmChannelModule'));
      });
    },
    name: 'CrmChannelModule',
    meta: {
      title: 'CRM Channels',
      activeMenu: '/crm/crm-channel'
    }
  }]
}];
var _default = routes;
exports.default = _default;