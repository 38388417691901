"use strict";

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAll = getAll;
exports.getAllType = getAllType;
exports.getAllByCompanyId = getAllByCompanyId;
exports.getAllByPorteId = getAllByPorteId;
exports.getAllByInterventionId = getAllByInterventionId;
exports.getAllByTicketId = getAllByTicketId;
exports.getById = getById;
exports.checkAccessById = checkAccessById;

var _request = _interopRequireDefault(require("@/helpers/request"));

function getAll(payload) {
  var filters = payload.filters;
  return (0, _request.default)({
    url: '/crm-library',
    method: 'post',
    data: {
      filters: filters
    },
    // `timeout` specifies the number of milliseconds before the request times out.
    // If the request takes longer than `timeout`, the request will be aborted.
    timeout: 30 * 1000,
    // 30s
    // `maxContentLength` defines the max size of the http response content in bytes allowed
    maxContentLength: 15 * 1000 * 1000 // 15Mb

  });
}

function getAllType(payload) {
  var filters = payload.filters;
  return (0, _request.default)({
    url: '/crm-library/type',
    method: 'post',
    data: {
      filters: filters
    },
    // `timeout` specifies the number of milliseconds before the request times out.
    // If the request takes longer than `timeout`, the request will be aborted.
    timeout: 30 * 1000,
    // 30s
    // `maxContentLength` defines the max size of the http response content in bytes allowed
    maxContentLength: 15 * 1000 * 1000 // 15Mb

  });
}

function getAllByCompanyId(companyId) {
  return (0, _request.default)({
    url: '/crm-library/company/' + companyId,
    method: 'get',
    // `timeout` specifies the number of milliseconds before the request times out.
    // If the request takes longer than `timeout`, the request will be aborted.
    timeout: 30 * 1000,
    // 30s
    // `maxContentLength` defines the max size of the http response content in bytes allowed
    maxContentLength: 15 * 1000 * 1000 // 15Mb

  });
}

function getAllByPorteId(porteId) {
  return (0, _request.default)({
    url: '/crm-library/porte/' + porteId,
    method: 'get'
  });
}

function getAllByInterventionId(interventionId) {
  return (0, _request.default)({
    url: '/crm-library/intervention/' + interventionId,
    method: 'get'
  });
}

function getAllByTicketId(ticketId) {
  return (0, _request.default)({
    url: '/crm-library/ticket/' + ticketId,
    method: 'get'
  });
}

function getById(id) {
  return (0, _request.default)({
    url: '/crm-library/' + id,
    method: 'get'
  });
}

function checkAccessById(id) {
  return (0, _request.default)({
    url: '/crm-library/check-access/' + id,
    method: 'get'
  });
}
/*
export function getByFilename (filename) {
  return request({
    url: '/crm-library/by-name/' + filename,
    method: 'get'
  })
}
*/