var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h1", [_vm._v("Gestion des Permissions")]),
      _c(
        "el-row",
        { attrs: { slot: "tool", gutter: 10 }, slot: "tool" },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-circle-plus-outline",
                    size: "mini",
                    type: "primary"
                  },
                  on: {
                    click: function($event) {
                      return _vm.$router.push({ name: "PermissionNew" })
                    }
                  }
                },
                [_vm._v(" Nouveau ")]
              ),
              _vm.multipleSelection.length > 0
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-delete",
                        size: "mini",
                        type: "danger"
                      },
                      on: { click: _vm.handleDeleteSelection }
                    },
                    [_vm._v(" Supprimer la sélection ")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "data-tables",
        {
          ref: "dataTable",
          attrs: {
            data: _vm.data,
            layout: _vm.datatable.layout,
            "pagination-props": _vm.datatable.paginationProps,
            "table-props": Object.assign({}, _vm.datatable.tableProps, {
              defaultSort: { prop: "name", order: "ascending" }
            }),
            total: _vm.total
          },
          on: {
            "row-click": function($event) {
              if ($event.target !== $event.currentTarget) {
                return null
              }
              return _vm.handleCurrentChange($event)
            },
            "selection-change": _vm.handleSelectionChange
          }
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "45" } }),
          _c("el-table-column", {
            attrs: { width: "70" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-button", {
                      attrs: { icon: "el-icon-delete", type: "text" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.handleDelete(scope.$index, scope.row)
                        }
                      }
                    }),
                    _c("el-button", {
                      attrs: { icon: "el-icon-edit", type: "text" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.$router.push({
                            name: "PermissionEdit",
                            params: { id: scope.row.id }
                          })
                        }
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _vm._l(_vm.genericColumns, function(item) {
            return _c("el-table-column", {
              key: item.key,
              attrs: {
                label: item.label,
                prop: item.prop,
                width: _vm.colWidth(item.prop),
                sortable: "custom"
              }
            })
          })
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: { title: "Détail", visible: _vm.dialogDetail.visible },
          on: {
            "update:visible": function($event) {
              return _vm.$set(_vm.dialogDetail, "visible", $event)
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "app-bloc-info" },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    _vm._l(_vm.dialogDetail.columns, function(item) {
                      return _c(
                        "el-row",
                        { key: item.key },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: _vm.dialogDetail.colWidth[0] } },
                            [
                              item.dialog && item.dialog.is_strong
                                ? [_c("strong", [_vm._v(_vm._s(item.label))])]
                                : [_vm._v(_vm._s(item.label))]
                            ],
                            2
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: _vm.dialogDetail.colWidth[1] } },
                            [
                              item.dialog && item.dialog.is_strong
                                ? [
                                    _c("strong", [
                                      _vm._v(_vm._s(_vm.row[item.prop]))
                                    ])
                                  ]
                                : [_vm._v(_vm._s(_vm.row[item.prop]))]
                            ],
                            2
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }