"use strict";

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAll = getAll;
exports.getById = getById;

var _request = _interopRequireDefault(require("@/helpers/request"));

function getAll(payload) {
  var filters = payload ? payload.filters : {};
  return (0, _request.default)({
    url: '/crm-company',
    method: 'post',
    data: {
      filters: filters
    },
    // `timeout` specifies the number of milliseconds before the request times out.
    // If the request takes longer than `timeout`, the request will be aborted.
    timeout: 30 * 1000,
    // 30s
    // `maxContentLength` defines the max size of the http response content in bytes allowed
    maxContentLength: 15 * 1000 * 1000 // 15Mb

  });
}

function getById(id) {
  return (0, _request.default)({
    url: '/crm-company/' + id,
    method: 'get'
  });
}