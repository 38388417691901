"use strict";

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/objectSpread2"));

var _vuex = require("vuex");

var _StatInfos = _interopRequireDefault(require("../statistique/StatInfos"));

var _InterventionList = _interopRequireDefault(require("../intervention/InterventionList"));

var _DocumentList = _interopRequireDefault(require("../document/DocumentList"));

var _TicketList = _interopRequireDefault(require("../ticket/TicketList"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'PorteView',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  components: {
    StatInfos: _StatInfos.default,
    InterventionList: _InterventionList.default,
    DocumentList: _DocumentList.default,
    TicketList: _TicketList.default
  },
  // 6. Composition
  // --
  // 7. Interface
  // --
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {};
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)('crm', ['getSite', 'getPorte', 'getInterventionsBy', 'getDocumentsBy', 'getTicketsBy', 'getStatsBy'])), {}, {
    site: function site() {
      return this.getSite;
    },
    porte: function porte() {
      return this.getPorte;
    },
    interventions: function interventions() {
      return this.getInterventionsBy(this.porte.door_porteid, 'porte');
    },
    documents: function documents() {
      return this.getDocumentsBy(this.porte.door_porteid, 'porte');
    },
    tickets: function tickets() {
      return this.getTicketsBy(this.porte.door_porteid, 'porte');
    },
    stat: function stat() {
      return this.getStatsBy(this.porte.door_porteid, 'porte');
    }
  }),
  // 9. Évènements
  // --
  // 10. Propriétés non réactives
  // --
  methods: {} // 11. Rendu
  // --

};
exports.default = _default;