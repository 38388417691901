"use strict";

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var api = _interopRequireWildcard(require("@/api/sys_params"));

var _adminListMixin = _interopRequireDefault(require("@/mixins/adminListMixin"));

var _DialogDetail = _interopRequireDefault(require("@/components/DialogDetail"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'ParametreList',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  components: {
    DialogDetail: _DialogDetail.default
  },
  // 6. Composition
  // --
  mixins: [_adminListMixin.default],
  // 7. Interface (interface du composant)
  // --
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      api: api,
      columns: {
        libelle: {
          key: 0,
          prop: 'libelle',
          label: 'Libellé'
        },
        code: {
          key: 1,
          prop: 'code',
          label: 'Code',
          width: 300
        },
        valeur: {
          key: 2,
          prop: 'valeur',
          label: 'Valeur',
          width: 200
        }
      },
      filters: [{
        value: '',
        prop: ['libelle', 'code', 'valeur']
      }]
    };
  },
  // 9. Évènements
  // --
  // 10. Propriétés non réactives
  // --
  methods: {
    getHandleDeleteMessage: function getHandleDeleteMessage(index, row) {
      console.log('[ParametreList] getHandleDeleteMessage');
      return "Voulez-vous supprimer le param\xE8tre ".concat(row.code, " ?");
    }
  } // 11. Rendu
  // --

};
exports.default = _default;