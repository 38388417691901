var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h1", [_vm._v("Gestion des utilisateurs")]),
      _c(
        "el-row",
        { attrs: { slot: "tool", gutter: 10 }, slot: "tool" },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-circle-plus-outline",
                    size: "mini",
                    type: "primary"
                  },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.$router.push({ name: "UserNew" })
                    }
                  }
                },
                [_vm._v(" Nouvel utilisateur ")]
              ),
              _vm.multipleSelection.length > 0
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-delete",
                        size: "mini",
                        type: "danger"
                      },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.handleDeleteSelection($event)
                        }
                      }
                    },
                    [_vm._v(" Supprimer la sélection ")]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "el-col",
            {
              staticStyle: { "white-space": "nowrap" },
              attrs: { span: 12, align: "right" }
            },
            [
              _c("el-input", {
                staticStyle: { width: "150px" },
                attrs: {
                  clearable: "",
                  placeholder: "Rechercher",
                  size: "mini"
                },
                model: {
                  value: _vm.filters[0].value,
                  callback: function($$v) {
                    _vm.$set(_vm.filters[0], "value", $$v)
                  },
                  expression: "filters[0].value"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "data-tables",
        {
          ref: "dataTable",
          attrs: {
            data: _vm.data,
            filters: _vm.filters,
            layout: _vm.datatable.layout,
            "pagination-props": _vm.datatable.paginationProps,
            "table-props": Object.assign({}, _vm.datatable.tableProps, {
              defaultSort: { prop: "name", order: "ascending" }
            }),
            total: _vm.total
          },
          on: {
            "row-click": function($event) {
              if ($event.target !== $event.currentTarget) {
                return null
              }
              return _vm.handleCurrentChange($event)
            },
            "selection-change": _vm.handleSelectionChange
          }
        },
        [
          _c("el-table-column", {
            attrs: { type: "selection", width: "45", selectable: _vm.canDelete }
          }),
          _c("el-table-column", {
            attrs: { width: "70" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-button", {
                      attrs: {
                        icon: "el-icon-delete",
                        type: "text",
                        disabled: !_vm.canDelete(scope.row, scope.$index)
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.handleDelete(scope.$index, scope.row)
                        }
                      }
                    }),
                    _c("el-button", {
                      attrs: {
                        icon: "el-icon-edit",
                        type: "text",
                        disabled: !_vm.canUpdate(scope.row, scope.$index)
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.$router.push({
                            name: "UserEdit",
                            params: { id: scope.row.id }
                          })
                        }
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _vm._l(_vm.genericColumns, function(item) {
            return _c("el-table-column", {
              key: item.key,
              attrs: {
                label: item.label,
                prop: item.prop,
                width: _vm.colWidth(item.prop),
                sortable: "custom"
              }
            })
          }),
          _c("el-table-column", {
            attrs: {
              prop: "role",
              label: "Fonction",
              width: "180",
              filters: _vm.filterRole,
              "filter-method": _vm.filterHandler
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "enabled",
              label: "Actif",
              width: "80",
              align: "center",
              filters: _vm.filterActif,
              "filter-method": _vm.filterHandler
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "nb_sites",
              label: "Nb Sites",
              width: "100",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.permission && _vm.hasFullAccess(scope.row)
                      ? _c("div", [_vm._v("tous")])
                      : _c("div", [
                          _vm._v(" " + _vm._s(scope.row.nb_sites) + " ")
                        ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { align: "right", fixed: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.permission && !_vm.hasFullAccess(scope.row)
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  icon: "el-icon-office-building",
                                  type: "text",
                                  disabled: !_vm.canUpdate(
                                    scope.row,
                                    scope.$index
                                  )
                                },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.$router.push({
                                      name: "UserCrmLinkManager",
                                      params: { id: scope.row.id }
                                    })
                                  }
                                }
                              },
                              [_vm._v(" Liaison CRM ")]
                            )
                          ],
                          1
                        )
                      : _c("div")
                  ]
                }
              }
            ])
          })
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: { title: "Détail", visible: _vm.dialogDetail.visible },
          on: {
            "update:visible": function($event) {
              return _vm.$set(_vm.dialogDetail, "visible", $event)
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "app-bloc-info" },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: _vm.dialogDetail.colWidth[0] } },
                            [_vm._v("Utilisateur")]
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: _vm.dialogDetail.colWidth[1] } },
                            [
                              _vm._v(
                                _vm._s(_vm.row.firstname) +
                                  " " +
                                  _vm._s(_vm.row.lastname)
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: _vm.dialogDetail.colWidth[0] } },
                            [_vm._v("Email")]
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: _vm.dialogDetail.colWidth[1] } },
                            [_vm._v(_vm._s(_vm.row.email))]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: _vm.dialogDetail.colWidth[0] } },
                            [_vm._v("Fonction")]
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: _vm.dialogDetail.colWidth[1] } },
                            [_vm._v(_vm._s(_vm.row.role))]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: _vm.dialogDetail.colWidth[0] } },
                            [_vm._v("Liaison CRM")]
                          ),
                          _vm.row.permission && _vm.hasFullAccess(_vm.row)
                            ? _c(
                                "el-col",
                                {
                                  attrs: { span: _vm.dialogDetail.colWidth[1] }
                                },
                                [
                                  _vm._v(
                                    " accès à tous les sites (CRM Company) "
                                  )
                                ]
                              )
                            : _c(
                                "el-col",
                                {
                                  attrs: { span: _vm.dialogDetail.colWidth[1] }
                                },
                                [
                                  _vm.row.companies
                                    ? _c("div", [
                                        _vm._v(
                                          " accès à " +
                                            _vm._s(_vm.row.companies.length) +
                                            " sites (CRM Company) : "
                                        ),
                                        _c(
                                          "ul",
                                          _vm._l(_vm.row.companies, function(
                                            company
                                          ) {
                                            return _c(
                                              "li",
                                              { key: company.comp_company_id },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      company.comp_codecomptable
                                                    ) +
                                                    " - " +
                                                    _vm._s(company.comp_name) +
                                                    " "
                                                )
                                              ]
                                            )
                                          }),
                                          0
                                        )
                                      ])
                                    : _c("div", [
                                        _vm._v(
                                          " accès à aucun site (CRM Company) "
                                        )
                                      ])
                                ]
                              )
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: _vm.dialogDetail.colWidth[0] } },
                            [_vm._v("Actif")]
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: _vm.dialogDetail.colWidth[1] } },
                            [_vm._v(_vm._s(_vm.row.enabled))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }