"use strict";

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

require("core-js/modules/es.function.name");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var api = _interopRequireWildcard(require("@/api/agence"));

var _ChannelSelectList = _interopRequireDefault(require("@/components/ChannelSelectList"));

var _adminFormMixin = _interopRequireDefault(require("@/mixins/adminFormMixin"));

var _ButtonReturn = _interopRequireDefault(require("@/components/ButtonReturn"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'AgenceForm',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  components: {
    ButtonReturn: _ButtonReturn.default,
    ChannelSelectList: _ChannelSelectList.default
  },
  // 6. Composition
  // --
  mixins: [_adminFormMixin.default],
  // 7. Interface (interface du composant)
  // --
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      api: api,
      // Route de retour après validation du formulaire
      route: {
        list: 'AgenceList'
      },
      rules: {
        chan_channelid: [{
          required: true,
          message: 'Champ obligatoire'
        }],
        name: [{
          required: true,
          message: 'Champ obligatoire'
        }]
      }
    };
  },
  // 9. Évènements
  // --
  // 10. Propriétés non réactives
  // --
  methods: {
    getData: function getData() {
      var data = {
        name: this.formData.name,
        chan_channelid: this.formData.chan_channelid,
        // Champ non modifiable (issu de la CRM)
        // chan_description: this.formData.chan_description,
        address: this.formData.address,
        opening_time: this.formData.opening_time
      };
      return data;
    }
  }
};
exports.default = _default;