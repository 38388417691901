"use strict";

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/objectSpread2"));

var _vuex = require("vuex");

var _StatInfos = _interopRequireDefault(require("../statistique/StatInfos"));

var _TicketHistoriqueList = _interopRequireDefault(require("./TicketHistoriqueList"));

var _DocumentList = _interopRequireDefault(require("../document/DocumentList"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'TicketView',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  components: {
    StatInfos: _StatInfos.default,
    DocumentList: _DocumentList.default,
    TicketHistoriqueList: _TicketHistoriqueList.default
  },
  // 6. Composition
  // --
  // 7. Interface
  // --
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {};
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)('crm', ['getSite', 'getTicket', 'getDocumentsBy', 'getTicketHistoriquesBy', 'getStatsBy'])), {}, {
    site: function site() {
      return this.getSite;
    },
    ticket: function ticket() {
      return this.getTicket;
    },
    documents: function documents() {
      return this.getDocumentsBy(this.ticket.case_caseid, 'ticket');
    },
    ticketHistoriques: function ticketHistoriques() {
      return this.getTicketHistoriquesBy(this.ticket.case_caseid, 'ticket');
    },
    stat: function stat() {
      return this.getStatsBy(this.ticket.case_caseid, 'ticket');
    }
  }),
  // 9. Évènements
  // --
  // 10. Propriétés non réactives
  // --
  methods: {} // 11. Rendu
  // --

};
exports.default = _default;