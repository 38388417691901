"use strict";

var _interopRequireWildcard = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.filter");

require("core-js/modules/es.number.constructor");

require("core-js/modules/es.number.to-fixed");

var _vue = _interopRequireDefault(require("vue"));

var utils = _interopRequireWildcard(require("@/helpers/utils"));

var _moment = _interopRequireDefault(require("moment"));

require("moment/locale/fr");

_moment.default.locale('fr');

_vue.default.filter('toCurrency', function (value) {
  if (value === '' || typeof value === 'undefined') return;
  if (value === 0) value = Math.abs(value); // pour éviter d'afficher le signe - quand value = 0

  var formatter = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
  });
  return formatter.format(value);
});

_vue.default.filter('toCurrencyWithZero', function (value) {
  if (typeof value === 'undefined') return;
  if (value === 0) value = Math.abs(value); // pour éviter d'afficher le signe - quand value = 0

  var formatter = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
  });
  return formatter.format(value);
});

_vue.default.filter('toPercentage', function (value) {
  if (!isNaN(value)) {
    return Number(value * 100).toFixed(2) + ' %';
  }
});

_vue.default.filter('toPercentageFormat', function (value) {
  if (!isNaN(value)) {
    return Number(value) + ' %';
  }
});

_vue.default.filter('toDate', function (value) {
  if (value && typeof value !== 'undefined') {
    return (0, _moment.default)(String(value)).format('DD/MM/YYYY');
  }
});

_vue.default.filter('toDateTime', function (value) {
  return utils.toDateTime(value);
});

_vue.default.filter('toShortDate', function (value) {
  if (value && typeof value !== 'undefined') {
    return (0, _moment.default)(String(value)).format('DD/MM/YY');
  }
});

_vue.default.filter('toDuration', function (value) {
  return utils.toDuration(value);
});
/************************************
 *  Formattage de durée spécifiquement pour le temps de suivi d'intervention
 ************************************
 *
 */


_vue.default.filter('toSuiviDuration', function (value) {
  return utils.toSuiviDuration(value);
});
/*
Vue.filter('toInterventionSuiviDuration', function (row) {
  return utils.toInterventionSuiviDuration(row)
})
*/


_vue.default.filter('formatSiret', function (value) {
  return utils.formatSiret(value);
});

_vue.default.filter('formatAddress', function (row) {
  return utils.formatAddress(row);
}); // Le filtre utilise la fonction getLabelContactType déjà implémentée dans utils


_vue.default.filter('getLabelContactType', function (value) {
  return utils.getLabelContactType(value);
});

_vue.default.filter('ln2br', function (value) {
  return utils.ln2br(value);
});