"use strict";

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var api = _interopRequireWildcard(require("@/api/crm_company"));

var _adminListMixin = _interopRequireDefault(require("@/mixins/adminListMixin"));

var _DialogDetail = _interopRequireDefault(require("@/components/DialogDetail"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'CrmCompanyList',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  components: {
    DialogDetail: _DialogDetail.default
  },
  // 6. Composition
  // --
  mixins: [_adminListMixin.default],
  // 7. Interface (interface du composant)
  // --
  props: {
    // Filtre global pour ne voir que les liaisons d'un utilisateur
    user: Object
  },
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      api: api,
      columns: {
        comp_companyid: {
          key: 0,
          prop: 'comp_companyid',
          label: 'CRM ID',
          width: 125,
          dialog: {
            is_strong: true
          }
        },
        comp_name: {
          key: 1,
          prop: 'comp_name',
          label: 'Libellé'
        },
        comp_codecomptable: {
          key: 2,
          prop: 'comp_codecomptable',
          label: 'Code comptable',
          width: 125
        },
        comp_payeur: {
          key: 3,
          prop: 'comp_payeur',
          label: 'Client payeur',
          width: 125
        }
      },
      filters: [{
        value: '',
        prop: ['comp_name', 'comp_codecomptable', 'comp_payeur']
      }]
    };
  },
  // 9. Évènements
  // --
  // 10. Propriétés non réactives
  // --
  methods: {
    loadData: function loadData() {
      var _this = this;

      this.$loader(true);
      this.data = [];
      this.api.getAll(this.user ? this.user.id : null).then(function (results) {
        _this.data = results.recordset;
        _this.data.total = results.length;
      }).catch(function (error) {
        _this.$message(error);
      }).then(function (_) {
        _this.$loader(false);
      });
    }
  } // 11. Rendu
  // --

};
exports.default = _default;