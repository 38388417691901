var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar }
      }),
      _c("breadcrumb", { staticClass: "breadcrumb-container" }),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _c(
            "el-dropdown",
            { staticClass: "avatar-container", attrs: { trigger: "click" } },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _c("div", { staticClass: "avatar-text" }, [
                  _vm._v(
                    _vm._s(_vm.user.firstname) + " " + _vm._s(_vm.user.lastname)
                  ),
                  _c("br"),
                  _c("small", [_vm._v(_vm._s(_vm.permission.name))])
                ]),
                _c("i", { staticClass: "el-icon-caret-bottom" })
              ]),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "user-dropdown",
                  attrs: { slot: "dropdown" },
                  slot: "dropdown"
                },
                [
                  _c(
                    "li",
                    { staticStyle: { "list-style": "none" } },
                    [
                      _c(
                        "el-card",
                        { staticClass: "box-card", attrs: { shadow: "never" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "clearfix",
                              attrs: { slot: "header" },
                              slot: "header"
                            },
                            [
                              _c(
                                "el-row",
                                [
                                  _c("el-col", { attrs: { span: 11 } }, [
                                    _vm.logo
                                      ? _c("img", {
                                          staticClass: "box-card-logo",
                                          attrs: { src: _vm.logo }
                                        })
                                      : _vm._e()
                                  ]),
                                  _c("el-col", { attrs: { span: 13 } }, [
                                    _c("strong", [_vm._v("Groupe Lacroix")]),
                                    _c("br"),
                                    _c("small", [
                                      _vm._v(" ZAC de la Glacière"),
                                      _c("br"),
                                      _vm._v(
                                        " 6 Impasse Edmond-Audran, 31200 TOULOUSE"
                                      ),
                                      _c("br"),
                                      _vm._v(" Tél : +33 (0)826 555 053"),
                                      _c("br")
                                    ])
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "el-row",
                                [
                                  _c("el-col", { attrs: { span: 14 } }, [
                                    _c("span", [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(_vm.user.firstname) +
                                            " " +
                                            _vm._s(_vm.user.lastname)
                                        )
                                      ])
                                    ]),
                                    _c("br"),
                                    _c("span", [
                                      _c("small", [
                                        _vm._v(_vm._s(_vm.user.email))
                                      ])
                                    ]),
                                    _c("br"),
                                    _c("span", [
                                      _c("small", [
                                        _vm._v(_vm._s(_vm.permission.name))
                                      ])
                                    ]),
                                    _c("br"),
                                    _c("span", [
                                      _c("small", [
                                        _vm._v(_vm._s(_vm.user.societe_name))
                                      ])
                                    ])
                                  ]),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 10 } },
                                    [
                                      _vm.$store.state.settings.sidebarLogo
                                        ? _c("el-image", {
                                            attrs: {
                                              src:
                                                _vm.$store.state.settings
                                                  .sidebarLogo,
                                              fit: "fill"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-dropdown-item",
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/profil" } },
                        [
                          _c("svg-icon", { attrs: { "icon-class": "user" } }),
                          _vm._v(" Profil")
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("el-dropdown-item", { attrs: { divided: "" } }, [
                    _c(
                      "span",
                      {
                        staticStyle: { display: "block" },
                        on: { click: _vm.logout }
                      },
                      [
                        _c("svg-icon", { attrs: { "icon-class": "logout" } }),
                        _vm._v(" Déconnexion")
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("search-link", { staticClass: "right-menu" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }