"use strict";

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/objectSpread2"));

var _vuex = require("vuex");

var _StatInfos = _interopRequireDefault(require("../statistique/StatInfos"));

var _TicketList = _interopRequireDefault(require("./TicketList"));

var _exportModuleMixin = _interopRequireDefault(require("@/mixins/exportModuleMixin"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'TicketModule',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  components: {
    StatInfos: _StatInfos.default,
    TicketList: _TicketList.default
  },
  // 6. Composition
  // --
  mixins: [_exportModuleMixin.default],
  // 7. Interface
  // --
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      mixinEvents: {
        export: 'TICKET_EXPORT',
        exported: 'TICKET_EXPORTED'
      }
    };
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)('crm', ['getSite', 'getStatsBy'])), {}, {
    site: function site() {
      return this.getSite;
    },
    stat: function stat() {
      return this.getStatsBy(this.site.comp_companyid, 'site.tickets');
    }
  }) // 9. Évènements
  // --
  // 10. Propriétés non réactives
  // --
  // 11. Rendu
  // --

};
exports.default = _default;