var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h1", [_vm._v("CRM : Channels")]),
      _c(
        "el-row",
        { attrs: { slot: "tool", gutter: 10 }, slot: "tool" },
        [
          _c(
            "el-col",
            {
              staticStyle: { "white-space": "nowrap" },
              attrs: { align: "right", span: 24 }
            },
            [
              _c("el-input", {
                staticStyle: { width: "150px" },
                attrs: {
                  clearable: "",
                  placeholder: "Rechercher",
                  size: "mini"
                },
                model: {
                  value: _vm.filters[0].value,
                  callback: function($$v) {
                    _vm.$set(_vm.filters[0], "value", $$v)
                  },
                  expression: "filters[0].value"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "data-tables",
        {
          ref: "dataTable",
          attrs: {
            data: _vm.data,
            filters: _vm.filters,
            layout: _vm.datatable.layout,
            "pagination-props": _vm.datatable.paginationProps,
            "table-props": Object.assign({}, _vm.datatable.tableProps, {
              defaultSort: { prop: "chan_description", order: "ascending" }
            }),
            total: _vm.total
          },
          on: {
            "row-click": function($event) {
              if ($event.target !== $event.currentTarget) {
                return null
              }
              return _vm.handleCurrentChange($event)
            },
            "selection-change": _vm.handleSelectionChange
          }
        },
        [
          _c(
            "el-table-column",
            { attrs: { label: "CRM", align: "center" } },
            [
              _c("el-table-column", {
                key: 0,
                attrs: {
                  sortable: "custom",
                  label: "ID",
                  prop: "chan_channelid",
                  width: _vm.colWidth(0)
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", [_vm._v(_vm._s(scope.row["chan_channelid"]))])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                key: 1,
                attrs: {
                  sortable: "custom",
                  label: "Description",
                  prop: "chan_description",
                  width: _vm.colWidth(1)
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", [
                          _vm._v(_vm._s(scope.row["chan_description"]))
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "el-table-column",
            { attrs: { label: "Agence", align: "center" } },
            [
              _c("el-table-column", {
                key: 3,
                attrs: {
                  sortable: "custom",
                  label: "Nom",
                  prop: "agence",
                  width: _vm.colWidth(3)
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.agence
                          ? _c("div", [
                              _vm._v(" " + _vm._s(scope.row.agence.name) + " ")
                            ])
                          : _vm._e()
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                key: 2,
                attrs: {
                  sortable: "custom",
                  label: "Créée",
                  prop: "agency_exists",
                  width: _vm.colWidth(2),
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(scope.row["agency_exists"])
                          }
                        })
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: { title: "Détail", visible: _vm.dialogDetail.visible },
          on: {
            "update:visible": function($event) {
              return _vm.$set(_vm.dialogDetail, "visible", $event)
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "app-bloc-info" },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-row",
                        { staticClass: "app-bloc-subtitle" },
                        [
                          _c("el-col", { attrs: { span: 24 } }, [
                            _c("strong", [_vm._v("CRM")])
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            {
                              attrs: {
                                span: _vm.dialogDetail.colWidth[0],
                                offset: 1
                              }
                            },
                            [_c("strong", [_vm._v("ID")])]
                          ),
                          _c(
                            "el-col",
                            {
                              attrs: { span: _vm.dialogDetail.colWidth[1] - 1 }
                            },
                            [
                              _c("strong", [
                                _vm._v(_vm._s(_vm.row.chan_channelid))
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            {
                              attrs: {
                                span: _vm.dialogDetail.colWidth[0],
                                offset: 1
                              }
                            },
                            [_c("strong", [_vm._v("Description")])]
                          ),
                          _c(
                            "el-col",
                            {
                              attrs: { span: _vm.dialogDetail.colWidth[1] - 1 }
                            },
                            [
                              _c("strong", [
                                _vm._v(_vm._s(_vm.row.chan_description))
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "app-bloc-subtitle" },
                        [
                          _c("el-col", { attrs: { span: 24 } }, [
                            _c("strong", [_vm._v("Agence")])
                          ])
                        ],
                        1
                      ),
                      _vm.row.agence
                        ? _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.dialogDetail.colWidth[0],
                                    offset: 1
                                  }
                                },
                                [_vm._v("Agence")]
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.dialogDetail.colWidth[1] - 1
                                  }
                                },
                                [_vm._v(_vm._s(_vm.row.agence.name))]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            {
                              attrs: {
                                span: _vm.dialogDetail.colWidth[0],
                                offset: 1
                              }
                            },
                            [_vm._v("Agence créée ?")]
                          ),
                          _c(
                            "el-col",
                            {
                              attrs: { span: _vm.dialogDetail.colWidth[1] - 1 }
                            },
                            [
                              _c("div", {
                                domProps: {
                                  innerHTML: _vm._s(_vm.row.agency_exists)
                                }
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }