var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.$env.showAppDebug
      ? _c("div", { staticClass: "app-debug" }, [
          _vm._v(" Document View"),
          _c("br"),
          _vm._v(
            " - Question : Rajouter des infos sur les liaisons (portes, interventions, sites ? "
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }