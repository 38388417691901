"use strict";

var _interopRequireWildcard = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.function.name");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/objectSpread2"));

var _vuex = require("vuex");

var apiSociete = _interopRequireWildcard(require("@/api/societe"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['user'])),
  mounted: function mounted() {
    var _this = this;

    if (this.user.id_societe !== undefined) {
      apiSociete.getById(this.user.id_societe).then(function (result) {
        _this.$store.state.settings.sidebarLogo = result[0].logo;
        _this.$store.state.settings.sidebarLogoSmall = result[0].logo_small;
        _this.$store.state.settings.siteTitle = result[0].name;
      }).catch(function (err) {
        console.log('Error in Sidebar mounted: ', err);
      });
    }
  }
};
exports.default = _default;