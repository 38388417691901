"use strict";

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/objectSpread2"));

var _TicketForm = _interopRequireDefault(require("./components/TicketForm"));

var _vuex = require("vuex");

//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'TicketNew',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  components: {
    TicketForm: _TicketForm.default
  },
  // 6. Composition
  // --
  // 7. Interface (interface du composant)
  // --
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      action: 'new',
      formData: {
        // Fichier à uploader
        fichier: null
      },
      title: 'Créer un ticket'
    };
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)('crm', ['getSite'])), {}, {
    site: function site() {
      return this.getSite;
    }
  }),
  // 9. Évènements
  // --
  created: function created() {
    this.formData.primary_company_id = this.site.comp_companyid;
    this.formData.primary_account_id = this.site.comp_primaryaccountid;

    if (this.$route.params.porteId) {
      this.formData.porte_id = parseInt(this.$route.params.porteId);
    }
  } // 10. Propriétés non réactives
  // --
  // 11. Rendu
  // --

};
exports.default = _default;