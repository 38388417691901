"use strict";

var _interopRequireWildcard = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.includes");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/objectSpread2"));

var _vuex = require("vuex");

var apiNotification = _interopRequireWildcard(require("@/api/notification"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'NotificationForm',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  // 6. Composition
  // --
  // 7. Interface (interface du composant)
  // --
  props: {
    action: {
      type: String,
      required: true,
      validator: function validator(value) {
        return ['new'].includes(value);
      }
    },
    formData: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true,
      validator: function validator(value) {
        return value.length > 0;
      }
    }
  },
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      rules: {
        type: [{
          required: true,
          message: 'Sélectionnez un type de notification'
        }],
        message: [{
          required: true,
          message: 'Champ obligatoire'
        }]
      }
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['user'])),
  // 9. Évènements
  // --
  // 10. Propriétés non réactives
  // --
  methods: {
    actionNew: function actionNew() {
      var _this = this;

      console.log('[NotificationForm] actionNew called !');
      this.$loader(true);
      var data = this.getData();
      apiNotification.insert(data).then(function (results) {
        _this.$message('Notification envoyée avec succès');

        _this.resetForm();

        _this.$loader(false);
      }).catch(function (error) {
        _this.$message(error);

        _this.$loader(false);
      });
    },
    getData: function getData() {
      return {
        id_user: this.user.id,
        type: this.formData.type,
        message: this.formData.message,
        url_link: this.formData.url_link
      };
    },
    resetForm: function resetForm() {
      this.$refs.ElForm.resetFields();
    },
    validateForm: function validateForm() {
      var _this2 = this;

      this.$refs.ElForm.validate(function (valid) {
        if (valid) {
          switch (_this2.action) {
            case 'new':
              _this2.actionNew();

              break;
          }
        }
      });
    }
  } // 11. Rendu
  // --

};
exports.default = _default;