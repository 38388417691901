"use strict";

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

require("core-js/modules/es.array.for-each");

require("core-js/modules/web.dom-collections.for-each");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var api = _interopRequireWildcard(require("@/api/crm_channel"));

var _adminListMixin = _interopRequireDefault(require("@/mixins/adminListMixin"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'CrmChannelList',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  // 6. Composition
  // --
  mixins: [_adminListMixin.default],
  // 7. Interface (interface du composant)
  // --
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      api: api,
      columns: {
        chan_channelid: {
          key: 0,
          prop: 'chan_channelid',
          label: 'ID',
          width: 100,
          dialog: {
            is_strong: true
          }
        },
        chan_description: {
          key: 1,
          prop: 'chan_description',
          label: 'Description',
          width: 125
        },
        agence: {
          key: 2,
          prop: 'agence',
          label: 'Agence',
          width: 150
        },
        agency_exists: {
          key: 3,
          prop: 'agency_exists',
          label: 'Agence créée',
          width: 150
        }
      },
      filters: [{
        value: '',
        prop: ['chan_description']
      }]
    };
  },
  // 9. Évènements
  // --
  // 10. Propriétés non réactives
  // --
  methods: {
    loadData: function loadData() {
      var _this = this;

      this.$loader(true);
      this.data = [];
      this.api.getAll().then(function (results) {
        results.recordset.forEach(function (row) {
          row.agency_exists = row.agence !== undefined ? '<span style="color: darkgreen">✓</span>' : '<span style="color: red">✘</span>';
        });
        _this.data = results.recordset; // this.data.total = results.length
      }).catch(function (error) {
        _this.$message(error);
      }).then(function (_) {
        _this.$loader(false);
      });
    }
  } // 11. Rendu
  // --

};
exports.default = _default;