"use strict";

var _interopRequireWildcard = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.function.name");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/objectSpread2"));

var _vuex = require("vuex");

var apiPermission = _interopRequireWildcard(require("@/api/permission"));

var _ButtonReturn = _interopRequireDefault(require("@/components/ButtonReturn"));

var _AclTree = _interopRequireDefault(require("@/components/AclTree"));

var _PermissionsSelectList = _interopRequireDefault(require("@/components/PermissionsSelectList"));

var _SysRoleSelectList = _interopRequireDefault(require("@/components/SysRoleSelectList"));

var _adminFormMixin = _interopRequireDefault(require("@/mixins/adminFormMixin"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'PermissionForm',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  components: {
    ButtonReturn: _ButtonReturn.default,
    AclTree: _AclTree.default,
    PermissionsSelectList: _PermissionsSelectList.default,
    SysRoleSelectList: _SysRoleSelectList.default
  },
  // 6. Composition
  // --
  mixins: [_adminFormMixin.default],
  // 7. Interface (interface du composant)
  // --
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    var _this = this;

    var validRole = function validRole(rule, value, callback) {
      if (_this.formData.key_role === '') {
        callback(new Error('Champ obligatoire'));
      }

      callback();
    };

    return {
      route: {
        list: 'PermissionList'
      },
      rules: {
        key_role: {
          required: true,
          message: 'Champ obligatoire',
          validator: validRole
        }
      }
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)([// 'sidebar',
  'user', 'permission'])),
  // 9. Évènements
  // --
  // 10. Propriétés non réactives
  // --
  methods: {
    actionEdit: function actionEdit() {
      var _this2 = this;

      console.log('[PermissionForm] actionEdit called !');
      this.$loader(true);
      var data = this.getData();
      apiPermission.update(this.formData.id, data).then(function (results) {
        _this2.$message('Mise à jour effectuée avec succès');

        if (_this2.user.key_role === _this2.formData.key_role) {
          _this2.$store.commit('permission/SET_ACL', _this2.formData.acl);

          _this2.$store.commit('permission/SET_RULES', _this2.formData.rules);

          _this2.$store.dispatch('permission/generateRoutes');
        }

        _this2.$loader(false);

        _this2.$router.push({
          name: _this2.route.list
        });
      }).catch(function (error) {
        _this2.$message(error);

        _this2.$loader(false);
      });
    },
    actionNew: function actionNew() {
      var _this3 = this;

      console.log('[PermissionForm] actionNew called !');
      this.$loader(true);
      var data = this.getData();
      apiPermission.insert(data).then(function (results) {
        _this3.$message('Création effectuée avec succès');

        _this3.resetForm();

        if (_this3.user.key_role === _this3.formData.key_role) {
          _this3.$store.commit('permission/SET_ACL', _this3.formData.acl);

          _this3.$store.commit('permission/SET_RULES', _this3.formData.rules);

          _this3.$store.dispatch('permission/generateRoutes');
        }

        _this3.$loader(false);

        _this3.$router.push({
          name: _this3.route.list
        });
      }).catch(function (error) {
        _this3.$message(error);

        _this3.$loader(false);
      });
    },
    getData: function getData() {
      var data = {
        name: this.formData.name,
        key_role: this.formData.key_role,
        acl: this.formData.acl,
        rules: this.formData.rules
      };
      return data;
    },
    onUpdateSysRole: function onUpdateSysRole(selection) {
      if (selection && selection.id > 0) {
        this.formData.name = selection.role;
        this.formData.key_role = selection.key;
      } else {
        this.formData.name = '';
        this.formData.key_role = '';
      }
    }
  } // 11. Rendu
  // --

};
exports.default = _default;