"use strict";

var _interopRequireWildcard = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/objectSpread2"));

var validate = _interopRequireWildcard(require("@/helpers/validate"));

var _vuex = require("vuex");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Login',
  data: function data() {
    var validateEmail = function validateEmail(rule, value, callback) {
      if (value === '') {
        callback(new Error('Adresse email obligatoire'));
      } else if (!validate.validEmail(value)) {
        callback(new Error('Adresse email non valide'));
      } else {
        callback();
      }
    };

    var validatePassword = function validatePassword(rule, value, callback) {
      if (value.length === 0) {
        callback(new Error('Mot de passe obligatoire'));
      } else {
        callback();
      }
    };

    return {
      loginForm: {
        email: this.$env.presetAppLogin === true ? 'superadmin@caplaser.fr' : '',
        password: this.$env.presetAppLogin === true ? 'Qw1tQjGKsT7' : ''
      },
      loginRules: {
        email: [{
          required: true,
          validator: validateEmail
        }],
        password: [{
          required: true,
          validator: validatePassword
        }]
      },
      loading: false,
      passwordType: 'password',
      redirect: undefined
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['gitInfo'])),
  created: function created() {
    // On utilise le store pour trouver les infos de versionning du projet
    this.$store.dispatch('app/getGitInfo');
  },
  watch: {
    $route: {
      handler: function handler(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true
    }
  },
  methods: {
    showPwd: function showPwd() {
      var _this = this;

      this.passwordType = this.passwordType === 'password' ? '' : 'password';
      this.$nextTick(function () {
        _this.$refs.password.focus();
      });
    },
    handleLogin: function handleLogin() {
      var _this2 = this;

      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          _this2.$loader(true);

          _this2.$store.dispatch('user/login', _this2.loginForm).then(function () {
            _this2.$router.push({
              path: _this2.redirect || '/'
            });

            _this2.$loader(false);
          }).catch(function () {
            _this2.$loader(false);
          });
        } else {
          console.log('error submit!!');
        }

        return false;
      });
    }
  }
};
exports.default = _default;