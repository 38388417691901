"use strict";

var _interopRequireWildcard = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

require("core-js/modules/es.array.find");

require("core-js/modules/es.array.join");

require("core-js/modules/es.array.map");

require("core-js/modules/es.object.keys");

require("core-js/modules/es.regexp.exec");

require("core-js/modules/es.string.split");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var apiSysPermissions = _interopRequireWildcard(require("@/api/sys_permissions"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'PermissionsSelectList',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  // 6. Composition
  // --
  // 7. Interface
  // --
  props: ['rules'],
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      data: [],
      list: [],
      selection: []
    };
  },
  // 9. Évènements
  // --
  beforeMount: function beforeMount() {
    this.getAll();
  },
  mounted: function mounted() {
    var _this = this;

    this.list = [];

    if (this.rules) {
      var rules = JSON.parse(this.rules);

      if (rules) {
        Object.keys(rules).map(function (key) {
          _this.list[key] = rules[key].split(',');
        });
      }
    }
  },
  // 10. Propriétés non réactives
  // --
  methods: {
    getAll: function getAll() {
      var _this2 = this;

      apiSysPermissions.getAll().then(function (results) {
        _this2.data = results;
      }).catch(function (error) {
        _this2.$message(error);
      });
    },
    itemList: function itemList(key) {
      var array = this.data.find(function (x) {
        return x.key === key;
      });

      if (array.rules) {
        return array.rules.split(',');
      } else {
        return [];
      }
    },
    onChange: function onChange(keyname, row) {
      var _this3 = this;

      var selection = {};
      Object.keys(this.list).map(function (key) {
        var rules = _this3.list[key].join(',');

        if (rules) {
          selection[key] = rules;
        }
      });
      this.$emit('update:rules', JSON.stringify(selection));
    }
  } // 11. Rendu
  // --

};
exports.default = _default;