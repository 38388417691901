"use strict";

var _interopRequireWildcard = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

require("core-js/modules/es.array.find");

require("core-js/modules/es.number.constructor");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var apiSociete = _interopRequireWildcard(require("@/api/societe"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'SocieteSelectList',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  // 6. Composition
  // --
  // 7. Interface
  // --
  props: {
    idSocieteSelected: {
      type: Number
    },
    societe: {
      type: Object
    }
  },
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      societes: [],
      idSociete: this.idSocieteSelected
    };
  },
  // 9. Évènements
  // --
  beforeMount: function beforeMount() {
    this.getAll();
  },
  // 10. Propriétés non réactives
  // --
  methods: {
    getAll: function getAll() {
      var _this = this;

      apiSociete.getAll().then(function (results) {
        _this.societes = results;

        var societe = _this.societes.find(function (x) {
          return x.id === _this.idSocieteSelected;
        });

        if (societe) {
          _this.$emit('update:societe', societe);
        }
      }).catch(function (error) {
        _this.$message(error);
      });
    },
    onClear: function onClear() {
      this.$emit('update:id-societe-selected', null);
      this.$emit('update:societe', null);
    },
    onChange: function onChange(index) {
      if (index > 0) {
        this.$emit('update:id-societe-selected', index);
        this.$emit('update:societe', this.societes.find(function (x) {
          return x.id === index;
        }));
      }
    }
  } // 11. Rendu
  // --

};
exports.default = _default;