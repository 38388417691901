var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", [
        _vm._v(
          "Intervention " +
            _vm._s(
              _vm.intervention.oppo_reference
                ? _vm.intervention.oppo_reference
                : "n/c"
            ) +
            " - " +
            _vm._s(_vm._f("toDateTime")(_vm.intervention.oppo_opened))
        )
      ]),
      _c(
        "el-card",
        { staticClass: "app-bloc-info" },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { md: 12, xs: 24 } },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { xs: 24, md: 8 } }, [
                        _c("strong", [_vm._v("Date")])
                      ]),
                      _c("el-col", { attrs: { xs: 24, md: 16 } }, [
                        _c("strong", [
                          _c("i", { staticClass: "el-icon-date" }),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("toDateTime")(
                                  _vm.intervention.oppo_opened
                                )
                              )
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { xs: 24, md: 8 } }, [
                        _c("strong", [_vm._v("Référence")])
                      ]),
                      _c("el-col", { attrs: { xs: 24, md: 16 } }, [
                        _c("strong", [
                          _vm._v(_vm._s(_vm.intervention.oppo_reference))
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { xs: 24, md: 8 } }, [
                        _vm._v("Catégorie")
                      ]),
                      _c("el-col", { attrs: { xs: 24, md: 16 } }, [
                        _vm._v(_vm._s(_vm.intervention.oppo_service))
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { xs: 24, md: 8 } }, [
                        _vm._v("Étape")
                      ]),
                      _c("el-col", { attrs: { xs: 24, md: 16 } }, [
                        _vm._v(_vm._s(_vm.intervention.oppo_stage))
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { md: 12, xs: 24 } },
                [
                  _vm.intervention.agence
                    ? _c(
                        "el-row",
                        [
                          _c("el-col", { attrs: { xs: 24, md: 8 } }, [
                            _vm._v("Agence")
                          ]),
                          _c("el-col", { attrs: { xs: 24, md: 16 } }, [
                            _vm._v(
                              _vm._s(_vm.intervention.agence.chan_description)
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.intervention.porte
                    ? _c(
                        "el-row",
                        [
                          _c("el-col", { attrs: { xs: 24, md: 8 } }, [
                            _vm._v("Matricule")
                          ]),
                          _c("el-col", { attrs: { xs: 24, md: 16 } }, [
                            _vm._v(
                              _vm._s(_vm.intervention.porte.door_matricule)
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.intervention.oppo_service_value === "ctrtagence"
                    ? [
                        _c(
                          "el-row",
                          [
                            _c("el-col", { attrs: { xs: 24, md: 8 } }, [
                              _vm._v("Date visite n°1")
                            ]),
                            _c("el-col", { attrs: { xs: 24, md: 16 } }, [
                              _c("i", { staticClass: "el-icon-date" }),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("toDate")(
                                      _vm.intervention.oppo_datevisite1
                                    )
                                  )
                              )
                            ])
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          [
                            _c("el-col", { attrs: { xs: 24, md: 8 } }, [
                              _vm._v("Date visite n°2")
                            ]),
                            _c("el-col", { attrs: { xs: 24, md: 16 } }, [
                              _c("i", { staticClass: "el-icon-date" }),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("toDate")(
                                      _vm.intervention.oppo_datevisite2
                                    )
                                  )
                              )
                            ])
                          ],
                          1
                        )
                      ]
                    : _vm._e()
                ],
                2
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { xs: 24, md: 4 } }, [
                        _vm._v("Description")
                      ]),
                      _c("el-col", { attrs: { xs: 24, md: 20 } }, [
                        _vm._v(_vm._s(_vm.intervention.oppo_description))
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { xs: 24, md: 4 } }, [
                        _vm._v("Note")
                      ]),
                      _c("el-col", { attrs: { xs: 24, md: 20 } }, [
                        _vm._v(_vm._s(_vm.intervention.oppo_note))
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }