"use strict";

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.includes");

require("core-js/modules/es.function.name");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _createForOfIteratorHelper2 = _interopRequireDefault(require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/createForOfIteratorHelper"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'AclTree',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  // 6. Composition
  // --
  // 7. Interface
  // --
  props: ['acl'],
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      checkStrictly: false,
      defaultProps: {
        children: 'children',
        label: 'title'
      },
      routes: []
    };
  },
  computed: {
    routesData: function routesData() {
      return this.routes;
    }
  },
  // 9. Évènements
  // --
  created: function created() {
    this.routes = this.generateRoutes(this.$router.options.routes);
  },
  // 10. Propriétés non réactives
  // --
  methods: {
    // Reshape the routes structure so that it looks the same as the sidebar
    generateRoutes: function generateRoutes(routes) {
      var basePath = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '/';
      var res = [];

      var _iterator = (0, _createForOfIteratorHelper2.default)(routes),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var route = _step.value;
          // skip some route
          var excludeFromTreeAcl = ['Login', 'PasswordReminder', 'PasswordReset', '404', '401', '*'];
          var treeAcl = route.meta && route.meta.treeAcl === 'exclude' || false; // console.log(route.path, route.name, excludeFromTreeAcl, treeAcl)

          if (excludeFromTreeAcl.includes(route.name) || treeAcl) {
            continue;
          }

          var data = {
            path: route.name,
            title: route.meta && route.meta.title
          }; // recursive child routes

          if (route.children) {
            data.children = this.generateRoutes(route.children, data.path);
          }

          res.push(data);
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      return res;
    },
    handleCheck: function handleCheck() {
      var checkedKeys = this.$refs.tree.getCheckedKeys(); // const checkedHalfKeys = this.$refs.tree.getHalfCheckedKeys()
      // const selection = checkedKeys // checkedKeys.concat(checkedHalfKeys)

      var selection = checkedKeys; // console.log(selection.join(','))

      this.$emit('update:acl', JSON.stringify(selection));
    }
  } // 11. Rendu
  // --

};
exports.default = _default;