var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.stat.nb >= 0
    ? _c(
        "el-col",
        {
          staticStyle: { "text-align": "center" },
          attrs: { xs: _vm.span.xs, sm: _vm.span.sm, lg: _vm.span.lg }
        },
        [
          _vm.stat.isScrollable
            ? _c(
                "a",
                {
                  directives: [
                    {
                      name: "scroll-to",
                      rawName: "v-scroll-to",
                      value: "#" + _vm.stat.anchor,
                      expression: "`#${stat.anchor}`"
                    }
                  ],
                  attrs: { href: "#" }
                },
                [
                  _c(
                    "el-badge",
                    {
                      staticClass: "badgeItem",
                      attrs: { value: _vm.stat.nb, type: "primary" }
                    },
                    [
                      _c(
                        "icon-base",
                        {
                          staticClass: "statIcon",
                          attrs: {
                            width: _vm.dynamicIcons.big.width,
                            height: _vm.dynamicIcons.big.height,
                            "icon-name": "write"
                          }
                        },
                        [
                          _c("item", {
                            attrs: {
                              icon: _vm.stat.icon,
                              title: _vm.stat.title
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("br"),
                  _vm._v(" " + _vm._s(_vm.stat.title) + " ")
                ],
                1
              )
            : [
                _c(
                  "el-badge",
                  {
                    staticClass: "badgeItem",
                    attrs: { value: _vm.stat.nb, type: "primary" }
                  },
                  [
                    _c(
                      "icon-base",
                      {
                        staticClass: "statIcon",
                        attrs: {
                          width: _vm.dynamicIcons.big.width,
                          height: _vm.dynamicIcons.big.height,
                          "icon-name": "write"
                        }
                      },
                      [
                        _c("item", {
                          attrs: { icon: _vm.stat.icon, title: _vm.stat.title }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("br"),
                _vm._v(" " + _vm._s(_vm.stat.title) + " ")
              ]
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }