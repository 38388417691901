var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    { staticStyle: { "margin-bottom": "20px" } },
    [
      _c(
        "el-row",
        _vm._l(_vm.dynamicStats, function(stat) {
          return _c("stat-infos-item", { key: stat.key, attrs: { stat: stat } })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }