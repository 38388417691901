"use strict";

require("core-js/modules/es.array.find");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _utils = require("@/helpers/utils");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'ContactTypeSelectList',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  // 6. Composition
  // --
  // 7. Interface
  // --
  props: {
    selectedValue: {
      type: String,
      validator: function validator(value) {
        var contactType = (0, _utils.getListContactType)();

        for (var idx in contactType) {
          var row = contactType[idx];

          if (value === row.value) {
            return true;
          }
        }

        return false;
      }
    },
    selectedItem: {
      type: Object
    }
  },
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      data: (0, _utils.getListContactType)(),
      value: this.selectedValue
    };
  },
  // 9. Évènements
  // --
  // beforeMount () {
  //   this.getAll()
  // },
  // 10. Propriétés non réactives
  // --
  methods: {
    /*
    getAll () {
      this.data = [
        { index: 0, id: 'COMMERCIAL', label: 'Commercial' },
        { index: 1, id: 'MAINTENANCE', label: 'Maintenance' }
      ]
      const contactType = this.data.find(x => x.id === this.selectedValue)
      if (contactType) {
        this.$emit('update:selected-item', contactType)
      }
    },
    */
    onClear: function onClear() {
      this.$emit('update:selected-value', null);
      this.$emit('update:selected-item', null);
    },
    onChange: function onChange(index) {
      var contactType = this.data.find(function (x) {
        return x.value === index;
      });

      if (contactType) {
        this.$emit('update:selected-value', contactType.value);
        this.$emit('update:selected-item', contactType);
      }
    }
  } // 11. Rendu
  // --

};
exports.default = _default;