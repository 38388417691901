"use strict";

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/objectSpread2"));

var _vuex = require("vuex");

var _StatInfos = _interopRequireDefault(require("../statistique/StatInfos"));

var _PorteList = _interopRequireDefault(require("../porte/PorteList"));

var _InterventionList = _interopRequireDefault(require("../intervention/InterventionList"));

var _DocumentList = _interopRequireDefault(require("../document/DocumentList"));

var _TicketList = _interopRequireDefault(require("../ticket/TicketList"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'SiteView',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  components: {
    StatInfos: _StatInfos.default,
    PorteList: _PorteList.default,
    InterventionList: _InterventionList.default,
    DocumentList: _DocumentList.default,
    TicketList: _TicketList.default
  },
  // 6. Composition
  // --
  // 7. Interface
  // --
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {};
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)('crm', ['getSite', 'getPortesBy', 'getInterventionsBy', 'getDocumentsBy', 'getTicketsBy', 'getStatsBy'])), {}, {
    site: function site() {
      return this.getSite;
    },
    portes: function portes() {
      return this.getPortesBy(this.site.comp_companyid, 'site'); // return this.getPortesBy(this.$route.params.siteId, 'site')
    },
    interventions: function interventions() {
      return this.getInterventionsBy(this.site.comp_companyid, 'site'); // return this.getInterventionsBy(this.$route.params.siteId, 'site')
    },
    documents: function documents() {
      return this.getDocumentsBy(this.site.comp_companyid, 'site'); // return this.getDocumentsBy(this.$route.params.siteId, 'site')
    },
    tickets: function tickets() {
      return this.getTicketsBy(this.site.comp_companyid, 'site'); // return this.getTicketsBy(this.$route.params.siteId, 'site')
    },
    stat: function stat() {
      return this.getStatsBy(this.site.comp_companyid, 'site'); // return this.getStatsBy(this.$route.params.siteId, 'site')
    }
  }),
  // 9. Évènements
  // --
  // 10. Propriétés non réactives
  // --
  methods: {} // 11. Rendu
  // --

};
exports.default = _default;