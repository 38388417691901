var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { staticStyle: { margin: "10px 0 10px 0" } },
        [
          _c("el-col", { attrs: { span: 10 } }, [
            _c("h1", [
              _vm._v(
                "Gestion des liaisons CRM pour " +
                  _vm._s(_vm.user.firstname) +
                  " " +
                  _vm._s(_vm.user.lastname)
              )
            ])
          ]),
          _c(
            "el-col",
            { staticStyle: { "text-align": "right" }, attrs: { span: 14 } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-check",
                    size: "mini",
                    type: "primary"
                  },
                  on: { click: _vm.saveCrmLinks }
                },
                [_vm._v(" Enregistrer ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "ex-lib" },
        [
          _vm.dataLoaded
            ? _c("vue-select-sides", {
                attrs: {
                  list: _vm.list,
                  "order-by": _vm.order,
                  type: "mirror"
                },
                model: {
                  value: _vm.selected,
                  callback: function($$v) {
                    _vm.selected = $$v
                  },
                  expression: "selected"
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }