"use strict";

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.concat");

require("core-js/modules/es.array.reduce");

require("core-js/modules/es.object.to-string");

require("core-js/modules/es.object.values");

require("core-js/modules/es.regexp.exec");

require("core-js/modules/es.string.replace");

require("core-js/modules/web.dom-collections.iterator");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resetRouter = resetRouter;
exports.default = exports.Routes = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard"));

var _toConsumableArray2 = _interopRequireDefault(require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/toConsumableArray"));

var _vue = _interopRequireDefault(require("vue"));

var _vueRouter = _interopRequireDefault(require("vue-router"));

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */
function routesLoader(root, files) {
  var routes = files.keys().reduce(function (routes, path) {
    var name = path.replace(/^\.\/(.*)\.\w+$/, '$1');
    var value = files(path);
    routes[root + '_' + name] = value.default;
    return routes;
  }, {});
  return [].concat.apply([], Object.values(routes));
}

var crmRoutes = routesLoader('crm', require.context('./routes/crm', true, /\.js$/));
var adminRoutes = routesLoader('admin', require.context('./routes/admin', true, /\.js$/));
var appRoutes = routesLoader('app', require.context('./routes/app', true, /\.js$/)); // tri des menus en fonction de la clé "order"

crmRoutes.sort(function (a, b) {
  return a.order - b.order;
});
adminRoutes.sort(function (a, b) {
  return a.order - b.order;
});
appRoutes.sort(function (a, b) {
  return a.order - b.order;
});
var Routes = [{
  path: '/login',
  name: 'Login',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/login'));
    });
  },
  hidden: true
}, {
  path: '/password-reminder',
  name: 'PasswordReminder',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/password_reminder'));
    });
  },
  hidden: true
}, {
  path: '/password-reset/:pwdResetToken',
  name: 'PasswordReset',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/password_reset'));
    });
  },
  hidden: true
}, {
  path: '/404',
  name: '404',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/404'));
    });
  },
  hidden: true
}, {
  path: '/401',
  name: '401',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/401'));
    });
  },
  hidden: true
}, {
  path: '/',
  name: '/',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/layout'));
    });
  },
  redirect: {
    name: 'Home'
  },
  hidden: true,
  children: [{
    path: '/',
    name: 'Home',
    redirect: {
      name: 'UserDashboard'
    },
    // component: () => import('@/views/home/HomeModule'),
    meta: {
      title: 'Accueil',
      activeMenu: 'Home',
      breadcrumb: false
    }
  }]
}, {
  path: '/admin',
  name: 'Admin',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/layout'));
    });
  },
  redirect: 'noRedirect',
  meta: {
    title: 'Administration',
    icon: 'admin'
  },
  children: (0, _toConsumableArray2.default)(adminRoutes)
}, {
  path: '/crm',
  name: 'CRM',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/layout'));
    });
  },
  redirect: 'noRedirect',
  meta: {
    title: 'CRM',
    icon: 'crm'
  },
  children: (0, _toConsumableArray2.default)(crmRoutes)
}].concat((0, _toConsumableArray2.default)(appRoutes), [// 404 page must be placed at the end !!!
{
  path: '*',
  name: '*',
  redirect: '/404',
  hidden: true
}]); // ===========================

exports.Routes = Routes;

_vue.default.use(_vueRouter.default);

var createRouter = function createRouter() {
  return new _vueRouter.default({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: Routes
  });
};

var router = createRouter(); // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465

function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
} // const router = new Router({ appRoutes })


var _default = router;
exports.default = _default;