"use strict";

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.object.to-string");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard"));

var routes = [{
  path: '/admin/societe',
  name: 'AdminSociete',
  order: 30,
  meta: {
    title: 'Gestion des sociétés',
    icon: 'societe'
  },
  redirect: {
    name: 'SocieteList'
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/layout/empty'));
    });
  },
  children: [{
    path: '',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/admin/societe/SocieteList'));
      });
    },
    name: 'SocieteList',
    meta: {
      title: 'Sociétés',
      activeMenu: '/admin/societe'
    }
  }, {
    path: 'new',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/admin/societe/SocieteNew'));
      });
    },
    name: 'SocieteNew',
    meta: {
      title: 'Ajouter une société',
      activeMenu: '/admin/societe'
    }
  }, {
    path: 'edit/:id(\\d+)',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/admin/societe/SocieteEdit'));
      });
    },
    name: 'SocieteEdit',
    meta: {
      title: 'Modifier une société',
      activeMenu: '/admin/societe'
    }
  }]
}];
var _default = routes;
exports.default = _default;