"use strict";

require("core-js/modules/es.array.concat");

require("core-js/modules/es.array.filter");

require("core-js/modules/es.array.index-of");

require("core-js/modules/es.array.join");

require("core-js/modules/es.array.map");

require("core-js/modules/es.array.splice");

require("core-js/modules/es.object.values");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  // 6. Composition
  // --
  // 7. Interface
  // --
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      // Charger le gestionnaire d'API depuis le module utilisant le mixin
      // les méthodes ci-dessous doivent être implémentées
      api: {
        bulkDelete: function bulkDelete(ids) {
          console.log('[Mixins] adminListMixin -> api.bulkDelete called !');
        },
        deleteById: function deleteById(id) {
          console.log('[Mixins] adminListMixin -> api.deleteById called !');
        },
        getAll: function getAll() {
          console.log('[Mixins] adminListMixin -> api.getAll called !');
        }
      },
      // A définir dasns le module utlisant le mixin
      columns: {},
      data: [],
      dataLoaded: false,
      dialogDetail: {
        columns: {
          generic: [],
          specific: []
        },
        colWidth: [4, 20],
        visible: false
      },
      multipleSelection: [],
      row: {}
    };
  },
  computed: {
    datatable: function datatable() {
      var pageSizes = [10, 20, 50, 100];

      if (this.total >= 250) {
        pageSizes.push(250);
      }

      if (this.total >= 500) {
        pageSizes.push(500);
      }

      return {
        layout: "tool, ".concat(this.total > 10 ? 'pagination, ' : '', " table, ").concat(this.total > 10 ? 'pagination' : ''),
        paginationProps: {
          background: true,
          pageSizes: pageSizes
        },
        tableProps: {
          stripe: true
        }
      };
    },
    total: function total() {
      return this.data.length;
    },
    // Liste des colonnes qui peuvent être affichées dans un template de datatable générique
    // sans formattage  particulier
    genericColumns: function genericColumns() {
      return Object.values(this.columns).filter(function (x) {
        return !x.datatable || !x.datatable.is_specific || x.datatable.is_specific !== true;
      });
    }
  },
  // 9. Évènements
  // --
  created: function created() {
    console.log('[Mixins] adminListMixin created !'); // Liste des colonnes qui peuvent être affichées dans la popup de visualisation depuis le datatable
    // sans formattage  particulier

    this.dialogDetail.columns.generic = Object.values(this.columns).filter(function (x) {
      return (!x.datatable || !x.datatable.is_specific || x.datatable.is_specific !== true) && (!x.dialog || !x.dialog.hidden || x.dialog.hidden !== true);
    }); // Liste des colonnes qui nécessitent un formattage particulier pour être affichées dans la popup de visualisation depuis le datatable

    this.dialogDetail.columns.specific = Object.values(this.columns).filter(function (x) {
      return x.dialog && typeof x.dialog.format === 'function' && (!x.dialog || !x.dialog.hidden || x.dialog.hidden !== true);
    }); // this.dialogDetail.columns.specific = Object.values(this.columns).filter(x => (x.datatable && x.datatable.is_specific && x.datatable.is_specific === true && typeof x.datatable.format === 'function') && (!x.dialog || !x.dialog.hidden || x.dialog.hidden !== true))
  },
  mounted: function mounted() {
    console.log('mounted --> Load data');
    this.loadData();
  },
  // 10. Propriétés non réactives
  // --
  methods: {
    // permet de gérer indépendamment la largeur de chaque colonne en fonction de leur index
    colWidth: function colWidth(index) {
      if (this.columns[index] === undefined) {
        return;
      }

      return this.columns[index].width;
    },
    // A implémenter dans le module utilisant le mixin
    getHandleDeleteMessage: function getHandleDeleteMessage(index, row) {
      console.log('[Mixins] adminListMixin -> getHandleDeleteMessage');
    },
    handleDelete: function handleDelete(index, row) {
      var _this = this;

      this.$confirm(this.getHandleDeleteMessage(index, row), 'Confirmer la suppression', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Annuler',
        type: 'warning'
      }).then(function () {
        _this.$loader(true);

        _this.api.deleteById(row.id).then(function (results) {
          _this.data.splice(_this.data.indexOf(row), 1);

          _this.$message('Suppression effectuée avec succès');

          _this.$loader(false);
        }).catch(function (err) {
          _this.$message(err);

          _this.$loader(false);
        });
      }).catch(function (err) {
        if (err === 'cancel') {
          console.info("Bouton 'annuler' cliqué");
        } else {
          console.log('err', err);
        }
      });
    },
    handleDeleteSelection: function handleDeleteSelection() {
      var _this2 = this;

      var msg = this.multipleSelection.length > 1 ? 'les lignes sélectionnées' : 'la ligne sélectionnée';
      this.$confirm("Voulez-vous supprimer ".concat(msg, " ?"), 'Confirmer la suppression', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Annuler',
        type: 'warning'
      }).then(function () {
        _this2.$loader(true);

        var selection = _this2.multipleSelection.map(function (u) {
          return u.id;
        }).join(',');

        _this2.api.bulkDelete(selection).then(function (results) {
          _this2.$message('Suppression effectuée avec succès');

          _this2.loadData();

          _this2.$loader(false);
        }).catch(function (error) {
          _this2.$message(error);

          _this2.$loader(false);
        });
      }).catch(function (err) {
        if (err === 'cancel') {
          console.info("Bouton 'annuler' cliqué");
        } else {
          console.log('err', err);
        }
      });
    },
    handleCurrentChange: function handleCurrentChange(val) {
      console.log('[Mixins] adminListMixin -> handleCurrentChange');
      this.row = val;
      this.dialogDetail.visible = true;
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // A surcharger au besoin dans le module utilisant le mixin
    loadData: function loadData() {
      var _this3 = this;

      console.log('[Mixins] adminListMixin -> loadData');
      this.$loader(true);
      this.data = [];
      this.api.getAll().then(function (results) {
        _this3.data = results;
      }).catch(function (error) {
        _this3.$message(error);
      }).then(function (_) {
        _this3.$loader(false);
      });
    }
  } // 11. Rendu
  // --

};
exports.default = _default;