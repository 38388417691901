"use strict";

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.concat");

require("core-js/modules/es.array.filter");

require("core-js/modules/es.array.for-each");

require("core-js/modules/es.function.name");

require("core-js/modules/web.dom-collections.for-each");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/objectSpread2"));

var _vuex = require("vuex");

var _HeaderBloc = _interopRequireDefault(require("../../commons/HeaderBloc"));

var _InterventionInfos = _interopRequireDefault(require("./InterventionInfos"));

var _utils = require("@/helpers/utils");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'InterventionIndex',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  components: {
    HeaderBloc: _HeaderBloc.default,
    InterventionInfos: _InterventionInfos.default
  },
  // 6. Composition
  // --
  // 7. Interface
  // --
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      dataLoaded: true
    };
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)('crm', ['getSite', 'getIntervention', 'getForceInterventionModuleReload'])), {}, {
    site: function site() {
      return this.getSite;
    },
    intervention: function intervention() {
      return this.getIntervention;
    },
    forceReload: function forceReload() {
      return this.intervention.oppo_opportunityid !== parseInt(this.$route.params.interventionId) || this.getForceInterventionModuleReload === true;
    }
  }),
  // 9. Évènements
  // --
  beforeMount: function beforeMount() {
    var _this = this;

    if (this.forceReload === true) {
      this.dataLoaded = false;
      console.log('[InterventionIndex] Force reloading intervention #' + this.$route.params.interventionId);
      this.$loader(true);
      this.$store.dispatch('crm/getInterventionById', {
        id: this.$route.params.interventionId
      }).then(function (result) {
        _this.$loader(false);

        var matched = _this.$route.matched.filter(function (item) {
          return item.meta && item.meta.title;
        }); // Modification dynamique du breadcrumb


        matched.forEach(function (item) {
          switch (item.name) {
            case 'InterventionIndex':
              item.meta.title = "Intervention ".concat(_this.intervention.oppo_reference ? _this.intervention.oppo_reference : 'n/c', " - ").concat((0, _utils.toDateTime)(_this.intervention.oppo_opened));
              console.info("MAJ du breadcumb : Fiche d'une intervention --> ".concat(item.meta.title));
              break;
          }
        });
        _this.dataLoaded = true;
      }, function (reject) {
        console.warn('[InterventionIndex]', reject.message);

        _this.$router.push({
          name: 'Dashboard'
        });

        _this.$loader(false);
      }).catch(function (err) {
        console.error('[InterventionIndex] err', err);

        _this.$router.push({
          name: 'Dashboard'
        });

        _this.$loader(false);
      });
    }
  },
  // 10. Propriétés non réactives
  // --
  methods: {} // 11. Rendu
  // --

};
exports.default = _default;