"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'DialogDetail',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  // 6. Composition
  // --
  props: {
    data: {
      type: Object,
      required: true,
      // Les objets et tableaux par défaut doivent être retournés depuis
      // une fonction de fabrique
      default: function _default() {
        return {
          columns: [],
          colWidth: [4, 20],
          visible: false
        };
      }
    },
    row: {
      type: Object,
      required: true,
      // Les objets et tableaux par défaut doivent être retournés depuis
      // une fonction de fabrique
      default: function _default() {
        return {};
      }
    }
  },
  // 7. Interface (interface du composant)
  // --
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {};
  } // 9. Évènements
  // --
  // 10. Propriétés non réactives
  // --
  // 11. Rendu
  // --

};
exports.default = _default2;