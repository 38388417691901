"use strict";

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.object.to-string");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard"));

var routes = [{
  path: '/admin/permission',
  name: 'AdminPermission',
  order: 20,
  meta: {
    title: 'Gestion des permissions',
    icon: 'permission'
  },
  redirect: {
    name: 'PermissionList'
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/layout/empty'));
    });
  },
  children: [{
    path: '',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/admin/permission/PermissionList'));
      });
    },
    name: 'PermissionList',
    meta: {
      title: 'Permissions',
      activeMenu: '/admin/permission'
    }
  }, {
    path: 'new',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/admin/permission/PermissionNew'));
      });
    },
    name: 'PermissionNew',
    meta: {
      title: 'Ajouter une permission',
      activeMenu: '/admin/permission'
    }
  }, {
    path: 'edit/:id(\\d+)',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/admin/permission/PermissionEdit'));
      });
    },
    name: 'PermissionEdit',
    meta: {
      title: 'Modifier une permission',
      activeMenu: '/admin/permission'
    }
  }]
}];
var _default = routes;
exports.default = _default;