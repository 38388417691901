"use strict";

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getImageLogo = getImageLogo;

var _request = _interopRequireDefault(require("@/helpers/request"));

function getImageLogo() {
  return (0, _request.default)({
    url: '/utils/image-logo',
    method: 'get'
  });
}