"use strict";

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.object.to-string");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard"));

var routes = [{
  path: '/admin/contact',
  name: 'AdminContact',
  order: 55,
  meta: {
    title: 'Gestion des Contacts',
    icon: 'contact'
  },
  redirect: {
    name: 'ContactList'
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/layout/empty'));
    });
  },
  children: [{
    path: '',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/admin/contact/ContactList'));
      });
    },
    name: 'ContactList',
    meta: {
      title: 'Contacts',
      activeMenu: '/admin/contact'
    }
  }, {
    path: 'new',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/admin/contact/ContactNew'));
      });
    },
    name: 'ContactNew',
    meta: {
      title: 'Ajouter un contact',
      activeMenu: '/admin/contact'
    }
  }, {
    path: 'edit/:id(\\d+)',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/admin/contact/ContactEdit'));
      });
    },
    name: 'ContactEdit',
    meta: {
      title: 'Modifier un contact',
      activeMenu: '/admin/contact'
    }
  }]
}];
var _default = routes;
exports.default = _default;