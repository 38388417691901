"use strict";

var _interopRequireWildcard = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

require("core-js/modules/es.array.find");

require("core-js/modules/es.number.constructor");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var apiCrmPorte = _interopRequireWildcard(require("@/api/crm_porte"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'PorteSelectList',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  // 6. Composition
  // --
  // 7. Interface
  // --
  props: {
    idPorteSelected: {
      type: Number
    },
    idCompany: {
      type: Number,
      required: true
    },
    porte: {
      type: Object
    }
  },
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      portes: [],
      idPorte: this.idPorteSelected
    };
  },
  // 9. Évènements
  // --
  beforeMount: function beforeMount() {
    this.getAll();
  },
  // 10. Propriétés non réactives
  // --
  methods: {
    getAll: function getAll() {
      var _this = this;

      apiCrmPorte.getAllByCompanyId(this.idCompany).then(function (results) {
        _this.portes = results.recordset;

        var porte = _this.portes.find(function (x) {
          return x.door_porteid === _this.idPorteSelected;
        });

        if (porte) {
          _this.$emit('update:porte', porte);
        }
      }).catch(function (error) {
        _this.$message(error);
      });
    },
    onClear: function onClear() {
      this.$emit('update:id-porte-selected', null);
      this.$emit('update:porte', null);
    },
    onChange: function onChange(index) {
      if (index > 0) {
        this.$emit('update:id-porte-selected', index);
        this.$emit('update:porte', this.portes.find(function (x) {
          return x.door_porteid === index;
        }));
      }
    }
  } // 11. Rendu
  // --

};
exports.default = _default;