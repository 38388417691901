"use strict";

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.concat");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAllWithLinkByUser = getAllWithLinkByUser;
exports.getLinkedToUser = getLinkedToUser;
exports.getCompanyIdsLinkedToUser = getCompanyIdsLinkedToUser;
exports.saveLinksForUser = saveLinksForUser;
exports.exportLinkedToUser = exportLinkedToUser;

var _request = _interopRequireDefault(require("@/helpers/request"));

/*
export function getAllByUser (userId) {
  return request({
    url: `/user-has-company/user/${userId}`,
    method: 'get',

    // `timeout` specifies the number of milliseconds before the request times out.
    // If the request takes longer than `timeout`, the request will be aborted.
    timeout: 30 * 1000, // 30s

    // `maxContentLength` defines the max size of the http response content in bytes allowed
    maxContentLength: 15 * 1000 * 1000 // 15Mb
  })
}
*/
function getAllWithLinkByUser(userId) {
  return (0, _request.default)({
    url: "/user-has-company/with-link-by-user/".concat(userId),
    method: 'get',
    // `timeout` specifies the number of milliseconds before the request times out.
    // If the request takes longer than `timeout`, the request will be aborted.
    timeout: 30 * 1000,
    // 30s
    // `maxContentLength` defines the max size of the http response content in bytes allowed
    maxContentLength: 15 * 1000 * 1000 // 15Mb

  });
}

function getLinkedToUser(payload) {
  var userId = payload.userId || -1;
  var filters = payload.filters;
  return (0, _request.default)({
    url: "/user-has-company/linked-to-user/".concat(userId),
    method: 'post',
    data: {
      filters: filters
    }
  });
}

function getCompanyIdsLinkedToUser() {
  return (0, _request.default)({
    url: '/user-has-company/company-ids-linked-to-user',
    method: 'get'
  });
}

function saveLinksForUser(companyIds, userId) {
  return (0, _request.default)({
    url: "/user-has-company/save-links-for-user/".concat(userId),
    method: 'post',
    data: {
      companyIds: companyIds
    }
  });
}

function exportLinkedToUser(userId) {
  var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'pdf';
  return (0, _request.default)({
    url: "/user-has-company/export-linked-to-user/".concat(userId, "/").concat(format),
    method: 'get',
    // `timeout` specifies the number of milliseconds before the request times out.
    // If the request takes longer than `timeout`, the request will be aborted.
    timeout: 30 * 1000,
    // 30s
    // `maxContentLength` defines the max size of the http response content in bytes allowed
    maxContentLength: 15 * 1000 * 1000 // 15Mb

  });
}