var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-card",
        { staticClass: "app-bloc-info" },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { md: 12, xs: 24 } },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { xs: 24, md: 8 } }, [
                        _vm._v("Société")
                      ]),
                      _c("el-col", { attrs: { xs: 24, md: 16 } }, [
                        _vm._v(
                          _vm._s(_vm.site.comp_name ? _vm.site.comp_name : "")
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { xs: 24, md: 8 } }, [
                        _vm._v("Nom abrégé")
                      ]),
                      _c("el-col", { attrs: { xs: 24, md: 16 } }, [
                        _vm._v(
                          _vm._s(
                            _vm.site.comp_nomabrege
                              ? _vm.site.comp_nomabrege
                              : ""
                          )
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { xs: 24, md: 8 } }, [
                        _vm._v("Code Comptable")
                      ]),
                      _c("el-col", { attrs: { xs: 24, md: 16 } }, [
                        _vm._v(
                          _vm._s(
                            _vm.site.comp_codecomptable
                              ? _vm.site.comp_codecomptable
                              : ""
                          )
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { xs: 24, md: 8 } }, [
                        _vm._v("Client payeur")
                      ]),
                      _c("el-col", { attrs: { xs: 24, md: 16 } }, [
                        _vm._v(
                          _vm._s(
                            _vm.site.comp_payeur ? _vm.site.comp_payeur : ""
                          )
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { xs: 24, md: 8 } }, [
                        _vm._v("Référence")
                      ]),
                      _c("el-col", { attrs: { xs: 24, md: 16 } }, [
                        _vm._v(
                          _vm._s(
                            _vm.site.comp_reference
                              ? _vm.site.comp_reference
                              : ""
                          )
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { xs: 24, md: 8 } }, [
                        _vm._v("Siret")
                      ]),
                      _c("el-col", { attrs: { xs: 24, md: 16 } }, [
                        _vm._v(
                          _vm._s(_vm._f("formatSiret")(_vm.site.comp_siret))
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { md: 12, xs: 24 } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { xs: 24, md: 8 } },
                        [
                          _vm.site.addr_intitule
                            ? [_vm._v(_vm._s(_vm.site.addr_intitule))]
                            : [_vm._v("Adresse")]
                        ],
                        2
                      ),
                      _c("el-col", {
                        attrs: { xs: 24, md: 16 },
                        domProps: {
                          innerHTML: _vm._s(_vm.formatAddress(_vm.site))
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }