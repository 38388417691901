"use strict";

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.concat");

require("core-js/modules/es.array.filter");

require("core-js/modules/es.array.for-each");

require("core-js/modules/es.function.name");

require("core-js/modules/es.regexp.exec");

require("core-js/modules/es.string.replace");

require("core-js/modules/web.dom-collections.for-each");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/toConsumableArray"));

var _objectSpread2 = _interopRequireDefault(require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/objectSpread2"));

var _pathToRegexp = _interopRequireDefault(require("path-to-regexp"));

var _eventBus = require("@/helpers/event-bus.js");

var _vuex = require("vuex");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      levelList: null,
      EventBus: _eventBus.EventBus
    };
  },
  watch: {
    $route: function $route() {
      this.getBreadcrumb();
    }
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['breadcrumb'])), (0, _vuex.mapGetters)('crm', ['getSite'])),
  created: function created() {
    this.getBreadcrumb();
  },
  methods: {
    getBreadcrumb: function getBreadcrumb() {
      // only show routes with meta.title
      var matched = this.$route.matched.filter(function (item) {
        return item.meta && item.meta.title;
      });
      /*
      // Modification dynamique du breadcrumb
      matched.forEach(item => {
        console.log('item', item.name)
        switch (item.name) {
          case 'SiteIndex':
            item.meta.title = this.getSite.comp_name
            break
        }
      })
      */
      // const arrRoutes = this.generateArr(this.$router.options.routes)
      // const parent = arrRoutes.find(x => x.path === prev)
      // matched.splice(matched.length - 1, 0, parent)
      // console.log('matched', matched)
      // console.log('arrRoutes', arrRoutes)

      /*
      const first = matched[0]
       // HACK for nested routes to display parent menu in breadcrumb
       const path = matched[matched.length - 1].path
       let prev = path.split('/:')[0]
      prev = prev.split('/')
      prev.pop()
      prev = prev.join('/')
      if (prev && prev !== first.path) {
        const arrRoutes = this.generateArr(this.$router.options.routes)
        const parent = arrRoutes.find(x => x.path === prev)
        matched.splice(matched.length - 1, 0, parent)
      }
      */
      // ======

      this.levelList = matched.filter(function (item) {
        return item.meta && item.meta.title && item.meta.breadcrumb !== false;
      });
    },
    pathCompile: function pathCompile(path) {
      // To solve this problem https://github.com/PanJiaChen/vue-element-admin/issues/561
      var params = this.$route.params;

      var toPath = _pathToRegexp.default.compile(path);

      return toPath(params);
    },
    handleLink: function handleLink(item) {
      var redirect = item.redirect,
          name = item.name;
      var params = this.$route.params;

      if (redirect) {
        this.$router.replace(redirect, function () {});
        return;
      }

      this.$router.push({
        name: name,
        params: params
      }); // this.$router.push(this.pathCompile(path))
    },
    generateArr: function generateArr(routes) {
      var _this = this;

      var data = [];
      routes.forEach(function (route) {
        data.push(route);

        if (route.children) {
          var temp = _this.generateArr(route.children);

          if (temp.length > 0) {
            data = [].concat((0, _toConsumableArray2.default)(data), (0, _toConsumableArray2.default)(temp));
          }
        }
      });
      return data;
    }
  }
};
exports.default = _default;