var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      false
        ? _c(
            "div",
            [
              _c("h2", [_vm._v("Statistiques")]),
              _c("stat-infos", {
                attrs: { stat: _vm.stat, "scrollable-items": ["sites"] }
              })
            ],
            1
          )
        : _vm._e(),
      _c("site-module")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }