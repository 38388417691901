var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", [
        _vm._v(
          "Document " +
            _vm._s(
              _vm.document.libr_filename ? _vm.document.libr_filename : "n/c"
            ) +
            " - " +
            _vm._s(_vm.document.libr_type)
        )
      ]),
      _c(
        "el-card",
        { staticClass: "app-bloc-info" },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { xs: 24, md: 2 } }, [
                        _vm._v("Note")
                      ]),
                      _c("el-col", { attrs: { xs: 24, md: 22 } }, [
                        _vm._v(_vm._s(_vm.document.libr_note))
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { xs: 24, md: 2 } }, [
                        _vm._v("Type")
                      ]),
                      _c("el-col", { attrs: { xs: 24, md: 22 } }, [
                        _vm._v(_vm._s(_vm.document.libr_type))
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { xs: 24, md: 2 } }, [
                        _vm._v("Fichier")
                      ]),
                      _c("el-col", { attrs: { xs: 24, md: 22 } }, [
                        _vm.document.libr_filename
                          ? _c("div", [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "el-button el-button--text app-button-no-padding",
                                  attrs: { title: "voir le fichier" },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.handleLinkToFile(_vm.document)
                                    }
                                  }
                                },
                                [
                                  _c("i", { staticClass: "el-icon-document" }),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.document.libr_filename) +
                                      " "
                                  )
                                ]
                              )
                            ])
                          : _c("div", [_c("span", [_vm._v("n/c")])])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }