"use strict";

var _interopRequireWildcard = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var validate = _interopRequireWildcard(require("@/helpers/validate"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'PasswordReminder',
  data: function data() {
    var validateEmail = function validateEmail(rule, value, callback) {
      if (value === '') {
        callback(new Error('Adresse email obligatoire'));
      } else if (!validate.validEmail(value)) {
        callback(new Error('Adresse email non valide'));
      } else {
        callback();
      }
    };

    return {
      reminderForm: {
        email: ''
      },
      reminderRules: {
        email: [{
          required: true,
          validator: validateEmail
        }]
      },
      loading: false
    };
  },
  methods: {
    handlePasswordReminder: function handlePasswordReminder() {
      var _this = this;

      this.$refs.reminderForm.validate(function (valid) {
        if (valid) {
          _this.$loader(true);

          _this.$store.dispatch('user/passwordReminder', _this.reminderForm).then(function (results) {
            _this.reminderForm.email = '';

            _this.$message(_this.$i18n.t(results.message)); // this.$message(results.message)


            _this.$loader(false);
          }).catch(function () {
            _this.$loader(false);
          });
        } else {
          console.log('error submit!!');
        }

        return false;
      });
    }
  }
};
exports.default = _default;