"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _eventBus = require("@/helpers/event-bus.js");

//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'AppMain',
  data: function data() {
    return {
      refresh: 0
    };
  },
  computed: {
    key: function key() {
      return this.$route.path;
    }
  },
  mounted: function mounted() {
    var _this = this;

    _eventBus.EventBus.$on('REFRESH_ROUTER', function () {
      console.log('REFRESH_ROUTER > AppMain');
      _this.refresh++;
    });
  },
  beforeDestroy: function beforeDestroy() {
    _eventBus.EventBus.$off('REFRESH_ROUTER');
  }
};
exports.default = _default;