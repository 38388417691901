var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.$env.showAppDebug
        ? _c("div", { staticClass: "app-debug" }, [_vm._v("Site View")])
        : _vm._e(),
      _c("h2", [_vm._v("Statistiques")]),
      _c("stat-infos", {
        attrs: {
          stat: _vm.stat,
          "scrollable-items": [
            "portes",
            "interventions",
            "documents",
            "tickets"
          ]
        }
      }),
      _c(
        "el-row",
        { attrs: { id: "anchor-table-porte" } },
        [
          _c("el-col", { attrs: { xs: 24, sm: 8, lg: 12 } }, [
            _c("h2", [_vm._v("Portes")])
          ]),
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 16, lg: 12, align: "right" } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-d-arrow-right",
                    type: "primary",
                    size: "mini"
                  },
                  on: {
                    click: function($event) {
                      return _vm.$router.push({
                        name: "PorteSiteModule",
                        params: { siteId: _vm.site.comp_companyid }
                      })
                    }
                  }
                },
                [_vm._v(" Voir toutes les portes ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-card",
        { staticStyle: { "margin-bottom": "20px" } },
        [
          _c("porte-list", {
            attrs: { limit: 10, hideReturnBtn: true, showFilters: false }
          })
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { id: "anchor-table-intervention" } },
        [
          _c("el-col", { attrs: { xs: 24, sm: 8, lg: 12 } }, [
            _c("h2", [_vm._v("Interventions")])
          ]),
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 16, lg: 12, align: "right" } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-d-arrow-right",
                    type: "primary",
                    size: "mini"
                  },
                  on: {
                    click: function($event) {
                      return _vm.$router.push({
                        name: "InterventionSiteModule",
                        params: { siteId: _vm.site.comp_companyid }
                      })
                    }
                  }
                },
                [_vm._v(" Voir toutes les interventions ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-card",
        { staticStyle: { "margin-bottom": "20px" } },
        [
          _c("intervention-list", {
            attrs: { limit: 10, hideReturnBtn: true, showFilters: false }
          })
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { id: "anchor-table-document" } },
        [
          _c("el-col", { attrs: { xs: 24, sm: 8, lg: 12 } }, [
            _c("h2", [_vm._v("Documents")])
          ]),
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 16, lg: 12, align: "right" } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-d-arrow-right",
                    type: "primary",
                    size: "mini"
                  },
                  on: {
                    click: function($event) {
                      return _vm.$router.push({
                        name: "DocumentSiteModule",
                        params: { siteId: _vm.site.comp_companyid }
                      })
                    }
                  }
                },
                [_vm._v(" Voir tous les documents ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-card",
        { staticStyle: { "margin-bottom": "20px" } },
        [
          _c("document-list", {
            attrs: { limit: 10, hideReturnBtn: true, showFilters: false }
          })
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { id: "anchor-table-ticket" } },
        [
          _c("el-col", { attrs: { xs: 24, sm: 8, lg: 12 } }, [
            _c("h2", [_vm._v("Tickets")])
          ]),
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 16, lg: 12, align: "right" } },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "can",
                      rawName: "v-can:ticket",
                      value: "creer",
                      expression: "'creer'",
                      arg: "ticket"
                    }
                  ],
                  attrs: {
                    icon: "el-icon-circle-plus-outline",
                    type: "primary",
                    size: "mini"
                  },
                  on: {
                    click: function($event) {
                      return _vm.$router.push({
                        name: "TicketSiteNew",
                        params: { siteId: _vm.site.comp_companyid }
                      })
                    }
                  }
                },
                [_vm._v(" Nouveau ticket ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-d-arrow-right",
                    type: "primary",
                    size: "mini"
                  },
                  on: {
                    click: function($event) {
                      return _vm.$router.push({
                        name: "TicketSiteModule",
                        params: { siteId: _vm.site.comp_companyid }
                      })
                    }
                  }
                },
                [_vm._v(" Voir tous les tickets ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-card",
        { staticStyle: { "margin-bottom": "20px" } },
        [
          _c("ticket-list", {
            attrs: { limit: 10, hideReturnBtn: true, showFilters: false }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }