var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dataLoaded
    ? _c(
        "div",
        [
          _vm.$env.showAppDebug
            ? _c("div", { staticClass: "app-debug" }, [_vm._v("Porte Index")])
            : _vm._e(),
          _c("header-bloc", { attrs: { site: _vm.site } }),
          _c("porte-infos", { attrs: { porte: _vm.porte } }),
          _c(
            "transition",
            { attrs: { name: "fade-transform", mode: "out-in" } },
            [_c("router-view", { key: "porte-view-" + _vm.$route.path })],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }