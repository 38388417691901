var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.$env.showAppDebug
        ? _c("div", { staticClass: "app-debug" }, [
            _vm._v("Intervention Module")
          ])
        : _vm._e(),
      _c("h2", [_vm._v("Statistiques")]),
      _c("stat-infos", {
        attrs: { stat: _vm.stat, "scrollable-items": ["documents"] }
      }),
      _c("div", [
        _c(
          "div",
          [
            _c(
              "el-row",
              { attrs: { id: "anchor-table-document" } },
              [
                _c(
                  "el-col",
                  { attrs: { xs: 24, sm: 8, lg: 12, align: "left" } },
                  [_c("h2", [_vm._v("Documents")])]
                ),
                _c(
                  "el-col",
                  { attrs: { xs: 24, sm: 16, lg: 12, align: "right" } },
                  [
                    _c(
                      "el-dropdown",
                      {
                        attrs: { size: "mini", type: "primary" },
                        on: { command: _vm.handleCommand }
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "primary",
                              loading: _vm.btnExportLoading.pdf
                            }
                          },
                          [
                            _vm._v(" Export PDF"),
                            _c("i", {
                              staticClass: "el-icon-arrow-down el-icon--right"
                            })
                          ]
                        ),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _c(
                              "el-dropdown-item",
                              {
                                attrs: {
                                  command: { format: "pdf", vue: "active" }
                                }
                              },
                              [_vm._v("Vue active")]
                            ),
                            _c(
                              "el-dropdown-item",
                              {
                                attrs: {
                                  command: { format: "pdf", vue: "all" }
                                }
                              },
                              [_vm._v("Toutes les données")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-dropdown",
                      {
                        attrs: { size: "mini", type: "primary" },
                        on: { command: _vm.handleCommand }
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "primary",
                              loading: _vm.btnExportLoading.excel
                            }
                          },
                          [
                            _vm._v(" Export Excel"),
                            _c("i", {
                              staticClass: "el-icon-arrow-down el-icon--right"
                            })
                          ]
                        ),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _c(
                              "el-dropdown-item",
                              {
                                attrs: {
                                  command: { format: "excel", vue: "active" }
                                }
                              },
                              [_vm._v("Vue active")]
                            ),
                            _c(
                              "el-dropdown-item",
                              {
                                attrs: {
                                  command: { format: "excel", vue: "all" }
                                }
                              },
                              [_vm._v("Toutes les données")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "el-card",
              { staticStyle: { "margin-bottom": "20px" } },
              [_c("document-list", { attrs: { hideReturnBtn: true } })],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }