"use strict";

var _interopRequireWildcard = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.symbol");

require("core-js/modules/es.symbol.description");

require("core-js/modules/es.array.includes");

require("core-js/modules/es.array.join");

require("core-js/modules/es.function.name");

require("core-js/modules/es.number.constructor");

require("core-js/modules/es.number.parse-float");

require("core-js/modules/es.number.to-fixed");

require("core-js/modules/es.object.to-string");

require("core-js/modules/es.string.includes");

require("core-js/modules/web.dom-collections.iterator");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/objectSpread2"));

var _vuex = require("vuex");

var apiCrmCases = _interopRequireWildcard(require("@/api/crm_cases"));

var _TicketTypeDemandeSelectList = _interopRequireDefault(require("@/components/TicketTypeDemandeSelectList"));

var _PorteSelectList = _interopRequireDefault(require("@/components/PorteSelectList"));

var _constantes = require("@/helpers/constantes");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'TicketForm',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  components: {
    TicketTypeDemandeSelectList: _TicketTypeDemandeSelectList.default,
    PorteSelectList: _PorteSelectList.default
  },
  // 6. Composition
  // --
  // 7. Interface (interface du composant)
  // --
  props: {
    action: {
      type: String,
      required: true,
      validator: function validator(value) {
        return ['new', 'edit'].includes(value);
      }
    },
    formData: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true,
      validator: function validator(value) {
        return value.length > 0;
      }
    }
  },
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    var _this = this;

    var validFichier = function validFichier(rule, value, callback, source, options) {
      if (_this.formData.fichier) {
        var maxUploadSize = _constantes.LIBRARY.MAX_UPLOAD_SIZE / 1024 / 1000; // converti en Mo

        var fileSize = Number.parseFloat(_this.formData.fichier.size / 1024 / 1000).toFixed(2); // converti en Mo

        var allowedType = _constantes.LIBRARY.ALLOWED_TYPE;

        if (allowedType !== '*' && !allowedType.includes(_this.formData.fichier.type)) {
          callback(new Error(_this.$i18n.t('helpdesk.file.restricted_allowed_filetypes', {
            fileTypes: allowedType.join(', ')
          })));
        } else if (fileSize > maxUploadSize) {
          callback(new Error(_this.$i18n.t('helpdesk.file.file_too_big', {
            fileSize: "".concat(fileSize, " Mo"),
            maxFileSize: "".concat(maxUploadSize, " Mo")
          })));
        }
      }

      callback();
    };

    return {
      // Constantes
      CONST: {
        LIBRARY: _constantes.LIBRARY
      },
      rules: {
        type_demande: [{
          required: true,
          message: 'Champ obligatoire'
        }],
        description: [{
          required: true,
          message: 'Champ obligatoire'
        }],
        fichier: [{
          validator: validFichier
        }]
      },
      showField: {
        autreTypeDemande: false,
        porteId: true
      }
    };
  },
  computed: {
    // Taille de fichier converti en Mo
    fileSize: function fileSize() {
      var fileSize = '';

      if (this.formData.fichier) {
        fileSize = Number.parseFloat(this.formData.fichier.size / 1024 / 1000).toFixed(2) + 'Mo';
      }

      return fileSize;
    }
  },
  // 9. Évènements
  // --
  mounted: function mounted() {
    this.showField.porteId = !(this.$route.params.porteId && parseInt(this.$route.params.porteId) > 0);
  },
  watch: {
    'formData.type_demande': function formDataType_demande(newVal, oldVal) {
      // Afficher / Masquer le champ 'autre type de demande'
      if ([newVal, oldVal].includes(_constantes.TICKET.TYPE_DEMANDE.INFORMATION.values.AUTRE.value)) {
        if (newVal === _constantes.TICKET.TYPE_DEMANDE.INFORMATION.values.AUTRE.value) {
          this.showField.autreTypeDemande = true;
        } else if (oldVal === _constantes.TICKET.TYPE_DEMANDE.INFORMATION.values.AUTRE.value) {
          this.showField.autreTypeDemande = false;
          this.formData.autre_type_demande = '';
        }
      } // TODO Afficher / Masquer la liste de sélection de 'porte'

    }
  },
  // 10. Propriétés non réactives
  // --
  methods: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapActions)('crm', ['forceSiteReloading', 'forcePorteReloading'])), {}, {
    actionNew: function actionNew() {
      var _this2 = this;

      var data = this.getFormData();
      this.$loader(true);
      apiCrmCases.insert(data).then(function (results) {
        _this2.$message('Création effectuée avec succès');

        _this2.resetForm();

        _this2.$loader(false);

        switch (_this2.$route.name) {
          case 'TicketSiteNew':
            // Forcer le reload des tickets uniquement pour le site actuel
            _this2.forceSiteReloading().then(function (result) {
              _this2.$router.go(-1);
            });

            break;

          case 'TicketPorteNew':
            // Forcer le reload des tickets uniquement pour la porte actuelle
            _this2.forcePorteReloading().then(function (result) {
              _this2.$router.go(-1);
            });

            break;
        }
      }).catch(function (error) {
        if (error) {
          _this2.$message(error);
        }

        _this2.$loader(false);
      });
    },
    getFormData: function getFormData() {
      var formData = new FormData();

      if (this.formData.fichier) {
        formData.append('fichier', this.formData.fichier);
      }

      formData.append('primary_company_id', this.formData.primary_company_id);
      formData.append('primary_account_id', this.formData.primary_account_id);

      if (this.formData.porte_id) {
        formData.append('porte_id', this.formData.porte_id);
      }

      formData.append('type_demande', this.formData.type_demande);

      if (this.formData.autre_type_demande) {
        formData.append('autre_type_demande', this.formData.autre_type_demande);
      }

      if (this.formData.titre) {
        formData.append('titre', this.formData.titre);
      }

      formData.append('description', this.formData.description);
      return formData;
    },
    onFileChange: function onFileChange(event) {
      this.formData.fichier = event.target.files[0];
      console.log('this.formData.fichier.type', this.formData.fichier.type);
    },
    onFileRemove: function onFileRemove() {
      this.formData.fichier = null;
      this.$refs.fichierUploader.value = '';
    },
    resetForm: function resetForm() {
      this.$refs.ElForm.resetFields();
    },
    validateForm: function validateForm() {
      var _this3 = this;

      this.$refs.ElForm.validate(function (valid) {
        if (valid) {
          switch (_this3.action) {
            case 'new':
              _this3.actionNew();

              break;
          }
        }
      });
    }
  }) // 11. Rendu
  // --

};
exports.default = _default;