var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 2 } },
            [
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-arrow-left", size: "mini" },
                  on: {
                    click: function($event) {
                      return _vm.$router.go(-1)
                    }
                  }
                },
                [_vm._v("Retour")]
              )
            ],
            1
          ),
          _c(
            "el-col",
            {
              staticClass: "ctn-app-site-top-info",
              attrs: {
                xs: 12,
                sm: 16,
                align: _vm.windowWidth < 768 ? "left" : "center"
              }
            },
            [
              _c("h2", { staticClass: "app-nom-site" }, [
                _vm._v(_vm._s(_vm.site.comp_name))
              ])
            ]
          ),
          _c(
            "el-col",
            {
              staticClass: "ctn-app-site-top-info",
              attrs: { xs: 12, sm: 6, align: "right" }
            },
            [
              _c("h2", { staticClass: "app-num-site" }, [
                _vm._v(_vm._s(_vm.site.comp_codecomptable))
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }