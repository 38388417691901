var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { attrs: { slot: "tool", gutter: 10 }, slot: "tool" },
        [
          _vm.showBtnReturn
            ? _c(
                "el-button",
                {
                  attrs: { size: "mini", icon: "el-icon-arrow-left" },
                  on: { click: _vm.handleReturn }
                },
                [_vm._v(" Retour ")]
              )
            : _vm._e()
        ],
        1
      ),
      _vm.modeView == "list" ? _c("div", [_c("crm-company-list")], 1) : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }