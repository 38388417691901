"use strict";

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var api = _interopRequireWildcard(require("@/api/sys_params"));

var _adminFormMixin = _interopRequireDefault(require("@/mixins/adminFormMixin"));

var _ButtonReturn = _interopRequireDefault(require("@/components/ButtonReturn"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'ParametreForm',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  components: {
    ButtonReturn: _ButtonReturn.default
  },
  // 6. Composition
  // --
  mixins: [_adminFormMixin.default],
  // 7. Interface (interface du composant)
  // --
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      // Gestionnaire d'API du module (pour pouvoir utiliser le mixin)
      api: api,
      // Route de retour après validation du formulaire
      route: {
        list: 'ParametreList'
      },
      rules: {
        code: [{
          required: true,
          message: 'Champ obligatoire'
        }],
        libelle: [{
          required: true,
          message: 'Champ obligatoire'
        }],
        valeur: [{
          required: true,
          message: 'Champ obligatoire'
        }]
      }
    };
  },
  // 9. Évènements
  // --
  // 10. Propriétés non réactives
  // --
  methods: {
    getData: function getData() {
      return {
        libelle: this.formData.libelle,
        code: this.formData.code,
        valeur: this.formData.valeur
      };
    }
  } // 11. Rendu
  // --

};
exports.default = _default;