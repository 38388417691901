"use strict";

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAll = getAll;
exports.getAllByCompanyId = getAllByCompanyId;
exports.getAllByPorteId = getAllByPorteId;
exports.getById = getById;
exports.update = update;
exports.insert = insert;

var _request = _interopRequireDefault(require("@/helpers/request"));

function getAll() {
  return (0, _request.default)({
    url: '/crm-cases',
    method: 'get',
    // `timeout` specifies the number of milliseconds before the request times out.
    // If the request takes longer than `timeout`, the request will be aborted.
    timeout: 30 * 1000,
    // 30s
    // `maxContentLength` defines the max size of the http response content in bytes allowed
    maxContentLength: 15 * 1000 * 1000 // 15Mb

  });
}

function getAllByCompanyId(companyId) {
  return (0, _request.default)({
    url: '/crm-cases/company/' + companyId,
    method: 'get',
    // `timeout` specifies the number of milliseconds before the request times out.
    // If the request takes longer than `timeout`, the request will be aborted.
    timeout: 30 * 1000,
    // 30s
    // `maxContentLength` defines the max size of the http response content in bytes allowed
    maxContentLength: 15 * 1000 * 1000 // 15Mb

  });
}

function getAllByPorteId(porteId) {
  return (0, _request.default)({
    url: '/crm-cases/porte/' + porteId,
    method: 'get'
  });
}

function getById(id) {
  return (0, _request.default)({
    url: '/crm-cases/' + id,
    method: 'get'
  });
}

function update(id, data) {
  return (0, _request.default)({
    url: '/crm-cases/' + id,
    method: 'put',
    data: data
  });
}

function insert(data) {
  return (0, _request.default)({
    url: '/crm-cases',
    method: 'post',
    data: data,
    // `timeout` specifies the number of milliseconds before the request times out.
    // If the request takes longer than `timeout`, the request will be aborted.
    timeout: 30 * 1000,
    // 30s
    // `maxBodyLength` (Node only option) defines the max size of the http request content in bytes allowed
    maxBodyLength: 6 * 1000 * 1000 // 6Mb

  });
}