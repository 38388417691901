"use strict";

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

require("core-js/modules/es.function.name");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var api = _interopRequireWildcard(require("@/api/societe"));

var _adminFormMixin = _interopRequireDefault(require("@/mixins/adminFormMixin"));

var _ButtonReturn = _interopRequireDefault(require("@/components/ButtonReturn"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'SocieteForm',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  components: {
    ButtonReturn: _ButtonReturn.default
  },
  // 6. Composition
  // --
  mixins: [_adminFormMixin.default],
  // 7. Interface (interface du composant)
  // --
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      // Gestionnaire d'API du module (pour pouvoir utiliser le mixin)
      api: api,
      // Gestions des images à uploader
      hasImage: false,
      hasImageSmall: false,
      image: null,
      imageSmall: null,
      // Route de retour après validation du formulaire
      route: {
        list: 'SocieteList'
      },
      rules: {
        name: [{
          required: true,
          message: 'Champ obligatoire'
        }],
        shortname: [{
          required: true,
          message: 'Champ obligatoire'
        }]
      }
    };
  },
  // 9. Évènements
  // --
  // 10. Propriétés non réactives
  // --
  methods: {
    getData: function getData() {
      console.log('[SocieteForm] getData');
      var data = {
        name: this.formData.name,
        shortname: this.formData.shortname,
        actif: this.formData.actif
      };

      if (this.hasImage) {
        data.logo = this.image;
      }

      if (this.hasImageSmall) {
        data.logo_small = this.imageSmall;
      }

      return data;
    },
    setImage: function setImage(output) {
      this.hasImage = true;
      this.image = output;
      this.formData.logo = this.image;
    },
    setImageSmall: function setImageSmall(output) {
      this.hasImageSmall = true;
      this.imageSmall = output;
      this.formData.logo_small = this.imageSmall;
    }
  }
};
exports.default = _default;