var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-breadcrumb",
        { staticClass: "app-breadcrumb", attrs: { separator: "/" } },
        [
          _c("el-breadcrumb-item", [
            _vm.$route.name === "Dashboard"
              ? _c("span", { staticClass: "no-redirect" }, [_vm._v("Accueil")])
              : _c(
                  "a",
                  {
                    staticStyle: { color: "#fff" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.$router.push({ name: "/" })
                      }
                    }
                  },
                  [_vm._v("Accueil")]
                )
          ]),
          _c(
            "transition-group",
            { attrs: { name: "breadcrumb" } },
            _vm._l(_vm.levelList, function(item, index) {
              return _c("el-breadcrumb-item", { key: item.path }, [
                item.redirect === "noRedirect" ||
                index == _vm.levelList.length - 1
                  ? _c("span", { staticClass: "no-redirect" }, [
                      _vm._v(_vm._s(item.meta.title))
                    ])
                  : _c(
                      "a",
                      {
                        staticClass: "redirect",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.handleLink(item)
                          }
                        }
                      },
                      [_vm._v(_vm._s(item.meta.title))]
                    )
              ])
            }),
            1
          ),
          _vm.breadcrumb
            ? _c(
                "div",
                { staticStyle: { display: "inline" } },
                _vm._l(_vm.breadcrumb.path, function(item, index) {
                  return _c(
                    "div",
                    { key: index, staticStyle: { display: "inline" } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "el-breadcrumb__separator",
                          attrs: { role: "presentation" }
                        },
                        [_vm._v("/")]
                      ),
                      item.redirect === "noRedirect" ||
                      index == _vm.breadcrumb.path.length - 1
                        ? _c("span", { staticClass: "no-redirect" }, [
                            _vm._v(_vm._s(item.title))
                          ])
                        : _c(
                            "a",
                            {
                              staticStyle: { color: "#fff" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.EventBus.$emit(
                                    "TAB_MENU",
                                    item.key,
                                    item.args
                                  )
                                }
                              }
                            },
                            [_vm._v(_vm._s(item.title))]
                          )
                    ]
                  )
                }),
                0
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }