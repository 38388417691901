"use strict";

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAllByInterventionId = getAllByInterventionId;
exports.getById = getById;

var _request = _interopRequireDefault(require("@/helpers/request"));

function getAllByInterventionId(companyId) {
  return (0, _request.default)({
    url: '/crm-opportunity-progress/opportunity/' + companyId,
    method: 'get'
  });
}

function getById(id) {
  return (0, _request.default)({
    url: '/crm-opportunity-progress/' + id,
    method: 'get'
  });
}