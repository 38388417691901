"use strict";

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.object.to-string");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard"));

var defaultActiveMenu = '/dashboard';
var routes = [{
  path: '/document',
  name: 'Document',
  order: 24,
  meta: {
    title: 'Gestion des documents',
    icon: 'site'
  },
  redirect: {
    name: 'DocumentModule'
  },
  hidden: true,
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/layout'));
    });
  },
  children: [// DOCUMENTS
  // ----------------
  // Liste des documents
  {
    path: 'list',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/app/document/DocumentModule'));
      });
    },
    name: 'DocumentModule',
    meta: {
      title: 'Liste des documents',
      activeMenu: defaultActiveMenu
    }
  }, // Fiche d'un Document (accès URL raccourcie)
  {
    path: ':documentId(\\d+)',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/app/document/DocumentIndex'));
      });
    },
    name: 'DocumentIndex',
    redirect: {
      name: 'DocumentView'
    },
    meta: {
      title: 'Fiche d\'un document',
      activeMenu: defaultActiveMenu
    },
    children: [// Contenu par défaut
    {
      path: '',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/app/document/DocumentView'));
        });
      },
      name: 'DocumentView',
      meta: {
        title: 'Vue détaillée d\'un document',
        activeMenu: defaultActiveMenu
      }
    }]
  }]
}];
var _default = routes;
exports.default = _default;