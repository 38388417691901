"use strict";

require("core-js/modules/es.array.concat");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  // 6. Composition
  // --
  // 7. Interface
  // --
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      dataLoaded: false,
      // Data table Server
      dataTableServer: false,
      dialogDetail: {
        visible: false // colWidth: [4, 20]

      },
      row: []
    };
  },
  computed: {
    datatable: function datatable() {
      var pageSizes = [10, 20, 50, 100];

      if (this.total >= 250) {
        pageSizes.push(250);
      }

      if (this.total >= 500) {
        pageSizes.push(500);
      }

      return {
        layout: "tool, ".concat(this.total > 10 ? 'pagination, ' : '', " table, ").concat(this.total > 10 ? 'pagination' : ''),
        paginationProps: {
          background: true,
          pageSizes: pageSizes,
          small: this.isMobile,
          layout: this.isSmartphone ? 'prev, next, sizes, ->, total' : 'prev, pager, next, sizes, ->, total'
        },
        tableProps: {
          stripe: true,
          maxHeight: this.windowWidth < 768 ? '400' : '750'
        }
      };
    },
    dialogDetailWidth: function dialogDetailWidth() {
      var colWidth = [4, 20];

      if (this.isMobile) {
        colWidth = [24, 24];
      }

      return colWidth;
    },
    isComputer: function isComputer() {
      return this.windowWidth >= 1200;
    },
    isMobile: function isMobile() {
      return this.windowWidth < 992;
    },
    isSmartphone: function isSmartphone() {
      return this.windowWidth < 576;
    },
    total: function total() {
      if (this.dataTableServer) {
        // Pour le dataTableServer il faut implémenter dataTotal dans le composant utilisant le Mixein
        return this.dataTotal;
      }

      return this.data.length;
    }
  },
  // 9. Évènements
  // --
  created: function created() {
    console.log('[Mixins] Datatable Mixin loaded !');
  },
  // 10. Propriétés non réactives
  // --
  methods: {
    // permet de gérer indépendamment la largeur de chaque colonne en fonction de leur index
    colWidth: function colWidth(index) {
      if (this.columns[index] === undefined) {
        return;
      }

      return this.columns[index].width;
    },
    handleCurrentChange: function handleCurrentChange(val) {
      console.log('[Mixins] Datatable Mixin -> handleCurrentChange');
      this.row = val;
      this.dialogDetail.visible = true;
    }
  } // 11. Rendu
  // --

};
exports.default = _default;