var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.$env.showAppDebug
        ? _c("div", { staticClass: "app-debug" }, [_vm._v("Ticket View")])
        : _vm._e(),
      _c("h2", [_vm._v("Statistiques")]),
      _c("stat-infos", {
        attrs: {
          stat: _vm.stat,
          "scrollable-items": ["documents", "tickets-historiques"]
        }
      }),
      _c(
        "el-row",
        { attrs: { id: "anchor-table-document" } },
        [
          _c("el-col", { attrs: { xs: 24, sm: 8, lg: 12, align: "left" } }, [
            _c("h2", [_vm._v("Documents")])
          ]),
          _c("el-col", { attrs: { xs: 24, sm: 16, lg: 12, align: "right" } })
        ],
        1
      ),
      _c(
        "el-card",
        { staticStyle: { "margin-bottom": "20px" } },
        [
          _c("document-list", {
            attrs: { limit: null, hideReturnBtn: true, showFilters: true }
          })
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { id: "anchor-table-ticket-historique" } },
        [
          _c("el-col", { attrs: { xs: 24, sm: 8, lg: 12, align: "left" } }, [
            _c("h2", [_vm._v("Historique")])
          ]),
          _c("el-col", { attrs: { xs: 24, sm: 16, lg: 12, align: "right" } })
        ],
        1
      ),
      _c(
        "el-card",
        { staticStyle: { "margin-bottom": "20px" } },
        [
          _c("ticket-historique-list", {
            attrs: { limit: null, hideReturnBtn: true, showFilters: true }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }