"use strict";

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.concat");

require("core-js/modules/es.array.find");

require("core-js/modules/es.array.for-each");

require("core-js/modules/es.array.includes");

require("core-js/modules/es.array.index-of");

require("core-js/modules/es.array.map");

require("core-js/modules/es.array.some");

require("core-js/modules/es.number.constructor");

require("core-js/modules/es.object.keys");

require("core-js/modules/es.object.values");

require("core-js/modules/es.string.includes");

require("core-js/modules/web.dom-collections.for-each");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/objectSpread2"));

var _vuex = require("vuex");

var _utils = require("@/helpers/utils");

var _datatableMixin = _interopRequireDefault(require("@/mixins/datatableMixin"));

var _exportListMixin = _interopRequireDefault(require("@/mixins/exportListMixin"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'PorteAllList',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  components: {},
  // 6. Composition
  // --
  mixins: [_datatableMixin.default, _exportListMixin.default],
  // 7. Interface
  // --
  props: {
    externalsFilters: {
      type: Object,
      required: false,
      default: function _default() {}
    },
    hideReturnBtn: {
      type: Boolean,
      required: false,
      default: false
    },
    limit: {
      type: Number,
      required: false,
      default: null
    },
    showColumnFilters: {
      type: Boolean,
      required: false,
      default: false
    },
    showFilters: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      columns: {
        comp_name: {
          prop: 'comp_name',
          label: 'Site'
        },
        door_name: {
          prop: 'door_name',
          label: 'Nom'
        },
        door_type: {
          prop: 'door_type',
          label: 'Type',
          width: 150
        },
        statut: {
          prop: 'statut',
          label: '',
          width: 40
        },
        door_marque: {
          prop: 'door_marque',
          label: 'Marque',
          width: 150
        },
        door_matricule: {
          prop: 'door_matricule',
          label: 'Matricule',
          width: 150
        },
        addr_city: {
          prop: 'addr_city',
          label: 'Lieu d\'installation'
        },
        nb_interventions: {
          prop: 'nb_interventions',
          label: 'Interv.',
          labelLong: 'Nb Interventions',
          width: 100
        },
        nb_documents: {
          prop: 'nb_documents',
          label: 'Docs',
          labelLong: 'Nb Documents',
          width: 100
        },
        nb_tickets: {
          prop: 'nb_tickets',
          label: 'Tickets',
          labelLong: 'Nb Tickets',
          width: 100
        }
      },
      dataTableServer: true,
      dataTableFilters: {
        page: 1,
        pageSize: 20
      },
      dataTotal: 0,
      filters: [{
        value: '',
        prop: ['door_name', 'door_type', 'door_marque', 'door_matricule', 'addr_city', 'statut'],
        filterFn: function filterFn(row, filter) {
          // console.log('filter row ', row)
          return Object.keys(row).some(function (prop) {
            // console.log('filter on ', prop)
            switch (prop) {
              // case 'commande':
              //   return row[prop] !== null && String(row[prop].numero).toLowerCase().indexOf(filter.value) > -1
              // case 'tva':
              //   return String(toPercentage(row[prop].taux)).indexOf(filter.value) > -1
              case 'door_marque':
              case 'door_matricule':
              case 'door_name':
              case 'statut':
                return String(row[prop]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1;
              // case 'date_facture':
              //   if (!row[prop] || typeof row[prop] === 'undefined') {
              //     return false
              //   }
              //   return moment(String(row[prop])).format('DD/MM/YYYY').indexOf(filter.value) > -1
              // case 'montant':
              //   return String(toCurrencyFilter(row[prop])).indexOf(filter.value.toLowerCase()) > -1
              // case 'nb_reglement':
              //   return String(row[prop]).indexOf(filter.value.toLowerCase()) > -1

              default:
                return false;
            }
          });
        }
      }, {
        value: '',
        prop: ['door_marque'],
        filterFn: function filterFn(row, filter) {
          return filter.value.includes(row.door_marque);
        }
      }, {
        value: '',
        prop: ['addr_city'],
        filterFn: function filterFn(row, filter) {
          return filter.value.includes(row.addr_city);
        }
      }, {
        value: '',
        prop: ['statut'],
        filterFn: function filterFn(row, filter) {
          return filter.value.includes(row.statut);
        }
      }],
      filterLieu: [],
      filterMarque: [],
      filterStatut: [],
      mixinEvents: {
        export: 'PORTE_EXPORT',
        exported: 'PORTE_EXPORTED'
      }
    };
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)('crm', ['getPortesBy', 'getPorteMarqueList'])), {}, {
    data: function data() {
      var data = this.getPortesBy();
      this.generateFilters(data);
      return data;
    },
    dialogDetailWidth: function dialogDetailWidth() {
      var colWidth = [6, 18];

      if (this.isMobile) {
        colWidth = [24, 24];
      }

      return colWidth;
    },
    searchPayload: function searchPayload() {
      var payload = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.externalsFilters), {}, {
        dataTableFilters: this.dataTableFilters
      });
      return payload;
    }
  }),
  // 9. Évènements
  // --
  mounted: function mounted() {
    // Préparation des colonnes du dataTable
    this.prepareColumns(); // Chargement des données

    this.loadData(this.searchPayload);
  },
  watch: {
    'externalsFilters': function externalsFilters(newVal, oldVal) {
      // Relancer le chargement des données
      if (newVal.matricule !== oldVal.matricule || newVal.type !== oldVal.type || newVal.marque !== oldVal.marque || newVal.lieu !== oldVal.lieu) {
        // on réinitialise les filtres du dataTable pour une recherche externe
        // sinon on pourrait être bloquée en page n°2 d'un tuple n'ayant qu'une page
        this.dataTableFilters.page = 1;
        this.dataTableFilters.pageSize = 20;
        this.loadData(this.searchPayload);
      }
    }
  },
  // 10. Propriétés non réactives
  // --
  methods: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapActions)('crm', ['getAllPorte'])), {}, {
    couleurStatut: function couleurStatut(row) {
      return (0, _utils.couleurPucePorte)(row);
    },
    generateFilters: function generateFilters(data) {
      var _this = this;

      if (this.showFilters !== true) {
        return;
      } // ---
      // Initialisation des valeurs des filtres de colonnes


      var filterLieu = [];
      var filterMarque = [];
      var filterStatut = [];
      data.forEach(function (row, index) {
        // Initialisation des valeurs du filtre pour la colonne "lieu d'installation"
        if (!filterLieu.find(function (x) {
          return x.value === row.addr_city;
        })) {
          var item = {
            text: row.addr_city ? row.addr_city : 'n/c',
            value: row.addr_city
          };
          filterLieu.push(item);
        } // Initialisation des valeurs du filtre pour la colonne "marque"


        if (!filterMarque.find(function (x) {
          return x.value === row.door_marque;
        })) {
          var _item = {
            text: row.door_marque ? row.door_marque : 'n/c',
            value: row.door_marque
          };
          filterMarque.push(_item);
        } // Initialisation des valeurs du filtre pour la colonne "statut" (puce de couleur)


        var couleur = _this.couleurStatut(row);

        if (!filterStatut.find(function (x) {
          return x.value === couleur;
        })) {
          var _item2 = {
            text: couleur,
            value: couleur
          };
          filterStatut.push(_item2);
        }

        data[index].statut = couleur;
      }); // Tri alphabétique

      filterLieu.sort(_utils.sortSelectItems);
      filterMarque.sort(_utils.sortSelectItems);
      filterStatut.sort(_utils.sortSelectItems); // Assignation des filtres

      this.setFilter('lieu', filterLieu);
      this.setFilter('marque', filterMarque);
      this.setFilter('statut', filterStatut);
    },
    getSummaries: function getSummaries(param) {
      var columns = param.columns,
          data = param.data;
      var sums = [];
      columns.forEach(function (column, index) {
        if (column.property === 'door_name') {
          sums[index] = 'Total';
          return;
        }

        if (!['nb_interventions', 'nb_documents', 'nb_tickets'].includes(column.property)) {
          sums[index] = '';
          return;
        }

        var values = data.map(function (item) {
          return Number(item[column.property]);
        });
        sums[index] = (0, _utils.getTotal)(values);
      });
      return sums;
    },
    handleDetail: function handleDetail(row) {
      this.$router.push({
        name: 'PorteView',
        params: {
          porteId: row.door_porteid
        }
      });
    },
    handleFilter: function handleFilter(value, row, column) {
      var property = column.property; // console.log('handleFilter on property', property, 'with value', value, '(column:', column, ')')
      // console.log(`${property}: ${value} (type: ${typeof value})`)

      switch (property) {
        default:
          return row[property] === value;
      }
    },
    handleQueryChange: function handleQueryChange(payload) {
      // Synchronisation de la page active et du nombre d'item /page
      // this.currentPage = payload.page
      // this.pageSize = payload.pageSize
      // Tous les événements sauf l'initialisation du dataTable
      if (payload.type !== 'init') {
        // Changement du nbre d'item par page
        if (payload.type === 'size') {
          var modulo = this.dataTotal % payload.pageSize;
          var limit = (this.dataTotal - modulo) / payload.pageSize;
          limit += modulo > 0 ? 1 : 0; // Si la page actuelle n'existe plus dans la nouvelle pagination on ne fait pas d'appel API
          // c'est la dataTable qui va corriger seul pour faire l'appel correct

          if (limit < payload.page) {
            return;
          }
        }

        this.dataTableFilters = payload;
        this.loadData(this.searchPayload); // this.loadData({ dataTableFilters: payload })
        // this.loadData(payload)
        // EventBus.$emit('SITE_LIST_RELOAD', payload)
      }
    },
    loadData: function loadData() {
      var _this2 = this;

      var payload = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      // console.log('loadAllData: payload', payload)
      this.dataLoaded = false;
      this.$loader(true);
      this.getAllPorte({
        filters: payload
      }).then(function (result) {
        console.log("Liste des ".concat(payload.dataTableFilters.pageSize, " portes de la page ").concat(payload.dataTableFilters.page, " charg\xE9s sur ").concat(result.total, " au total"));
        _this2.dataTotal = result.total;
        _this2.dataLoaded = true;

        _this2.$loader(false);
      }).catch(function (err) {
        console.error(err);

        _this2.$loader(false);
      });
    },
    prepareColumns: function prepareColumns() {
      Object.values(this.columns).map(function (item, index) {
        item.key = index;
      });
    },
    setFilter: function setFilter(prop, filter) {
      switch (prop) {
        case 'lieu':
          this.filterLieu = filter;
          break;

        case 'marque':
          this.filterMarque = filter;
          break;

        case 'statut':
          this.filterStatut = filter;
          break;
      }
    }
  }) // 11. Rendu
  // --

};
exports.default = _default2;