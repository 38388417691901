"use strict";

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.object.to-string");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard"));

var routes = [{
  path: '/user-dashboard',
  name: 'UserDashboard',
  order: 10,
  meta: {
    title: 'Tableau de bord',
    icon: 'dashboard'
  },
  redirect: {
    name: 'Dashboard'
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/layout'));
    });
  },
  children: [{
    path: '/dashboard',
    name: 'Dashboard',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/app/dashboard/DashboardModule'));
      });
    },
    meta: {
      title: 'Tableau de bord',
      activeMenu: '/dashboard',
      breadcrumb: false
    }
  }]
}];
var _default = routes;
exports.default = _default;