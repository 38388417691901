"use strict";

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.concat");

require("core-js/modules/es.array.find");

require("core-js/modules/es.array.for-each");

require("core-js/modules/es.array.includes");

require("core-js/modules/es.array.index-of");

require("core-js/modules/es.array.last-index-of");

require("core-js/modules/es.array.map");

require("core-js/modules/es.array.some");

require("core-js/modules/es.number.constructor");

require("core-js/modules/es.object.keys");

require("core-js/modules/es.object.values");

require("core-js/modules/es.string.includes");

require("core-js/modules/web.dom-collections.for-each");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/objectSpread2"));

var _vuex = require("vuex");

var _library = require("@/helpers/library");

var _utils = require("@/helpers/utils");

var _datatableMixin = _interopRequireDefault(require("@/mixins/datatableMixin"));

var _exportListMixin = _interopRequireDefault(require("@/mixins/exportListMixin"));

var _moment = _interopRequireDefault(require("moment"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'DocumentAllList',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  components: {},
  // 6. Composition
  // --
  mixins: [_datatableMixin.default, _exportListMixin.default],
  // 7. Interface
  // --
  props: {
    externalsFilters: {
      type: Object,
      required: false,
      default: function _default() {}
    },
    hideReturnBtn: {
      type: Boolean,
      required: false,
      default: false
    },
    limit: {
      type: Number,
      required: false,
      default: null
    },
    showColumnFilters: {
      type: Boolean,
      required: false,
      default: false
    },
    showFilters: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      columns: {
        comp_name: {
          prop: 'comp_name',
          label: 'Site'
        },
        libr_createddate: {
          prop: 'libr_createddate',
          label: 'Date',
          width: 200
        },
        libr_note: {
          prop: 'libr_note',
          label: 'Note',
          width: 200
        },
        libr_filename: {
          prop: 'libr_filename',
          label: 'Fichier'
        },
        door_matricule: {
          prop: 'door_matricule',
          label: 'Matricule',
          width: 125
        },
        addr_city: {
          prop: 'addr_city',
          label: 'Installation',
          width: 150
        },
        category: {
          prop: 'category',
          label: 'Catégorie',
          width: 150
        },
        libr_type: {
          prop: 'libr_type',
          label: 'Type',
          width: 200
        }
      },
      dataTableServer: true,
      dataTableFilters: {
        page: 1,
        pageSize: 20
      },
      dataTotal: 0,
      filters: [{
        value: '',
        prop: ['libr_createddate', 'libr_note', 'libr_filename', 'category', 'libr_type', 'porte'],
        filterFn: function filterFn(row, filter) {
          return Object.keys(row).some(function (prop) {
            switch (prop) {
              case 'porte':
                // console.log('filtre sur la porte : matricule')
                return row.porte !== null && String(row.porte.door_matricule).toLowerCase().indexOf(filter.value) > -1;
              // case 'libr_type':
              //   return String(row[prop]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1

              case 'libr_createddate':
                if (!row[prop] || typeof row[prop] === 'undefined') {
                  return false;
                }

                return (0, _moment.default)(String(row[prop])).format('DD/MM/YYYY').indexOf(filter.value) > -1;
              // case 'montant':
              // case 'montant_regle':
              // case 'mt_a_percevoir_ttc':
              //   return String(toCurrencyFilter(row[prop])).indexOf(filter.value.toLowerCase()) > -1
              // case 'nb_reglement':
              //   return String(row[prop]).indexOf(filter.value.toLowerCase()) > -1

              default:
                return String(row[prop]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1;
              // return false
            }
          });
        }
      }, // Catégorie ('CRM' ou 'Gestion')
      {
        value: '',
        prop: ['category'],
        filterFn: function filterFn(row, filter) {
          return filter.value.includes(row.category);
        }
      }, // Type (libr_type et libr_type_value)
      {
        value: '',
        prop: ['libr_type'],
        filterFn: function filterFn(row, filter) {
          return filter.value.includes(row.libr_type_value);
        }
      }, // Filtre (>= date de début de période)
      {
        value: '',
        prop: ['libr_createddate'],
        filterFn: function filterFn(row, filter) {
          return Object.keys(row).some(function (prop) {
            switch (prop) {
              case 'libr_createddate':
                if (!row[prop] || typeof row[prop] === 'undefined') {
                  return false;
                }

                if (filter.value === null) {
                  return true;
                } // console.log(prop + ' : ' + moment(String(row[prop])).format('YYYY-MM-DD'))


                return (0, _moment.default)(String(row[prop]), 'YYYY-MM-DD').isSameOrAfter(filter.value);

              default:
                return false;
            }
          });
        }
      }, // Filtre (<= date de fin de période)
      {
        value: '',
        prop: ['libr_createddate'],
        filterFn: function filterFn(row, filter) {
          return Object.keys(row).some(function (prop) {
            // console.log(`filtre n°3 (index #2) : "${filter.value}"`)
            switch (prop) {
              case 'libr_createddate':
                if (!row[prop] || typeof row[prop] === 'undefined') {
                  return false;
                }

                if (filter.value === null) {
                  return true;
                } // console.log(prop + ' : ' + moment(String(row[prop])).format('YYYY-MM-DD'))


                return (0, _moment.default)(String(row[prop]), 'YYYY-MM-DD').isSameOrBefore(filter.value);

              default:
                return false;
            }
          });
        }
      }],
      filterCategory: [],
      filterType: [],
      // pour filtrer les factures par date
      filterDateMin: null,
      filterDateMax: null,
      mixinEvents: {
        export: 'DOCUMENT_EXPORT',
        exported: 'DOCUMENT_EXPORTED'
      }
    };
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)('crm', ['getDocumentsBy'])), {}, {
    data: function data() {
      var data = this.getDocumentsBy();
      this.generateFilters(data);
      return data;
    },
    searchPayload: function searchPayload() {
      var payload = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.externalsFilters), {}, {
        dataTableFilters: this.dataTableFilters
      });
      return payload;
    },
    dateFormat: function dateFormat() {
      return 'dd/MM/yyyy';
    }
  }),
  // 9. Évènements
  // --
  mounted: function mounted() {
    // Préparation des colonnes du dataTable
    this.prepareColumns(); // Chargement des données

    this.loadData(this.searchPayload);
  },
  watch: {
    'externalsFilters': function externalsFilters(newVal, oldVal) {
      // Relancer le chargement des données
      if (newVal.numero !== oldVal.numero || newVal.type !== oldVal.type || newVal.dateDebut !== oldVal.dateDebut || newVal.dateFin !== oldVal.dateFin) {
        // on réinitialise les filtres du dataTable pour une recherche externe
        // sinon on pourrait être bloquée en page n°2 d'un tuple n'ayant qu'une page
        this.dataTableFilters.page = 1;
        this.dataTableFilters.pageSize = 20;
        this.loadData(this.searchPayload);
      }
    }
  },
  // 10. Propriétés non réactives
  // --
  methods: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapActions)('crm', ['getAllDocument'])), {}, {
    generateFilters: function generateFilters(data) {
      if (this.showFilters !== true) {
        return;
      } // ---
      // Initialisation des valeurs des filtres de colonnes


      var filterCategory = [];
      var filterType = [];
      data.forEach(function (row) {
        // Initialisation des valeurs du filtre pour la colonne "catégorie"
        if (!filterCategory.find(function (x) {
          return x.value === row.category;
        })) {
          var _row$category;

          var item = {
            text: (_row$category = row.category) !== null && _row$category !== void 0 ? _row$category : 'n/c',
            value: row.category
          };
          filterCategory.push(item);
        } // Initialisation des valeurs du filtre pour la colonne "type"


        if (!filterType.find(function (x) {
          return x.value === row.libr_type_value;
        })) {
          var _row$libr_type;

          var _item = {
            text: (_row$libr_type = row.libr_type) !== null && _row$libr_type !== void 0 ? _row$libr_type : "n/c (".concat(row.libr_type_value, ")"),
            value: row.libr_type_value
          };
          filterType.push(_item);
        }
      }); // Tri alphabétique

      filterCategory.sort(_utils.sortSelectItems);
      filterType.sort(_utils.sortSelectItems); // Assignation des filtres

      this.setFilter('category', filterCategory);
      this.setFilter('type', filterType);
    },
    getLinkToFileLabel: function getLinkToFileLabel(file) {
      var pos = file.lastIndexOf('.') + 1;
      var ext = file.substring(pos);
      return ['pdf'].includes(ext) ? 'Visualiser' : 'Télécharger';
    },
    getLinkToFileIcon: function getLinkToFileIcon(file) {
      var pos = file.lastIndexOf('.') + 1;
      var ext = file.substring(pos);
      return ['pdf'].includes(ext) ? 'el-icon-document' : 'el-icon-download';
    },
    handleChangeDateMin: function handleChangeDateMin(date) {
      this.filterDateMin = date;
    },
    handleChangeDateMax: function handleChangeDateMax(date) {
      this.filterDateMax = date;
    },
    handleFilter: function handleFilter(value, row, column) {
      var property = column.property; // console.log('property: ', property, 'value: ', value, 'row[property]: ', row[property])

      switch (property) {
        default:
          return row[property] === value;
      }
    },
    handleLinkToFile: function handleLinkToFile(row) {
      window.open((0, _library.getDocumentIdUri)(row.libr_libraryid), '_LIBRARY');
    },
    handleQueryChange: function handleQueryChange(payload) {
      // Synchronisation de la page active et du nombre d'item /page
      // this.currentPage = payload.page
      // this.pageSize = payload.pageSize
      // Tous les événements sauf l'initialisation du dataTable
      if (payload.type !== 'init') {
        // Changement du nbre d'item par page
        if (payload.type === 'size') {
          var modulo = this.dataTotal % payload.pageSize;
          var limit = (this.dataTotal - modulo) / payload.pageSize;
          limit += modulo > 0 ? 1 : 0; // Si la page actuelle n'existe plus dans la nouvelle pagination on ne fait pas d'appel API
          // c'est la dataTable qui va corriger seul pour faire l'appel correct

          if (limit < payload.page) {
            return;
          }
        }

        this.dataTableFilters = payload;
        this.loadData(this.searchPayload); // this.loadData({ dataTableFilters: payload })
        // this.loadData(payload)
        // EventBus.$emit('SITE_LIST_RELOAD', payload)
      }
    },
    loadData: function loadData() {
      var _this = this;

      var payload = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      // console.log('loadAllData: payload', payload)
      this.dataLoaded = false;
      this.$loader(true);
      this.getAllDocument({
        filters: payload
      }).then(function (result) {
        console.log("Liste des ".concat(payload.dataTableFilters.pageSize, " documents de la page ").concat(payload.dataTableFilters.page, " charg\xE9s sur ").concat(result.total, " au total"));
        _this.dataTotal = result.total;
        _this.dataLoaded = true;

        _this.$loader(false);
      }).catch(function (err) {
        console.error(err);

        _this.$loader(false);
      });
    },
    prepareColumns: function prepareColumns() {
      Object.values(this.columns).map(function (item, index) {
        item.key = index;
      });
    },
    setFilter: function setFilter(prop, filter) {
      switch (prop) {
        case 'category':
          this.filterCategory = filter;
          break;

        case 'type':
          this.filterType = filter;
          break;
      }
    }
  }) // 11. Rendu
  // --

};
exports.default = _default2;