var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.dataLoaded
        ? _c("contact-form", {
            attrs: {
              action: _vm.action,
              "form-data": _vm.formData,
              title: _vm.title
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }