"use strict";

require("core-js/modules/es.array.includes");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  // 6. Composition
  // --
  // 7. Interface (interface du composant)
  // --
  props: {
    action: {
      type: String,
      required: true,
      validator: function validator(value) {
        return ['new', 'edit'].includes(value);
      }
    },
    formData: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true,
      validator: function validator(value) {
        return value.length > 0;
      }
    }
  },
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      // Charger le gestionnaire d'API depuis le module utilisant le mixin
      // les 2 méthodes ci-dessous doivent être implémentées
      api: {
        insert: function insert(data) {
          console.log('[Mixins] adminFormMixin -> api.deleteById called !');
        },
        update: function update(id, data) {
          console.log('[Mixins] adminFormMixin -> api.bulkDelete called !');
        }
      },
      // Définir la route de retour après validation du formulaire
      route: {
        list: 'Admin'
      }
    };
  },
  // 9. Évènements
  // --
  // 10. Propriétés non réactives
  // --
  methods: {
    actionEdit: function actionEdit() {
      var _this = this;

      console.log('[Mixins] adminFormMixin -> actionEdit called !');
      var data = this.getData();
      this.$loader(true);
      this.api.update(this.formData.id, data).then(function (results) {
        _this.$message('Mise à jour effectuée avec succès');

        _this.$loader(false);

        if (!_this.route || !_this.route.list) {
          console.error('Veuillez indiquer la route de retour dans le module');
        } else {
          _this.$router.push({
            name: _this.route.list
          });
        }
      }).catch(function (error) {
        if (error) {
          console.error(error); // this.$message(error)
        }

        _this.$loader(false);
      });
    },
    actionNew: function actionNew() {
      var _this2 = this;

      console.log('[Mixins] adminFormMixin -> actionNew called !');
      var data = this.getData();
      this.$loader(true);
      this.api.insert(data).then(function (results) {
        _this2.$message('Création effectuée avec succès');

        _this2.resetForm();

        _this2.$loader(false);

        if (!_this2.route || !_this2.route.list) {
          console.error('Veuillez indiquer la route de retour dans le module');
        } else {
          _this2.$router.push({
            name: _this2.route.list
          });
        }
      }).catch(function (error) {
        if (error) {
          console.error(error); // this.$message(error)
        }

        _this2.$loader(false);
      });
    },
    getData: function getData() {
      // Cette méthode doit être implémentée dans le module utilisant le mixin
      console.log('[Mixins] adminFormMixin -> getData');
    },
    resetForm: function resetForm() {
      this.$refs.ElForm.resetFields();
    },
    validateForm: function validateForm() {
      var _this3 = this;

      this.$refs.ElForm.validate(function (valid) {
        if (valid) {
          switch (_this3.action) {
            case 'new':
              _this3.actionNew();

              break;

            case 'edit':
              _this3.actionEdit();

              break;
          }
        }
      });
    }
  } // 11. Rendu
  // --

};
exports.default = _default;