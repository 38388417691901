"use strict";

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

require("core-js/modules/es.array.includes");

require("core-js/modules/es.number.constructor");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var apiUser = _interopRequireWildcard(require("@/api/user"));

var apiPermission = _interopRequireWildcard(require("@/api/permission"));

var validate = _interopRequireWildcard(require("@/helpers/validate"));

var _utils = require("@/helpers/utils");

var _SocieteSelectList = _interopRequireDefault(require("@/components/SocieteSelectList"));

var _ButtonReturn = _interopRequireDefault(require("@/components/ButtonReturn"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'UserForm',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  components: {
    ButtonReturn: _ButtonReturn.default,
    SocieteSelectList: _SocieteSelectList.default
  },
  // 6. Composition
  // --
  // 7. Interface (interface du composant)
  // --
  props: {
    action: {
      type: String,
      required: true,
      validator: function validator(value) {
        return ['new', 'edit'].includes(value);
      }
    },
    formData: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true,
      validator: function validator(value) {
        return value.length > 0;
      }
    }
  },
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    var _this = this;

    var validPwd1 = function validPwd1(rule, value, callback) {
      if (_this.passwordType !== '') {
        value = _this.formData.pwd1 || '';

        if (_this.action === 'edit' && _this.oldPassword === '') {
          callback();
        } else if (value === '' && _this.action === 'new') {
          callback(new Error('Mot de passe obligatoire'));
        } else if (value !== '' && !validate.isStrongPassword(value)) {
          callback(new Error('Le mot de passe ne respecte pas les règles de sécurité (8 caractères mini dont 1 minuscule, 1 majuscule, 1 chiffre)')); // callback(new Error('Le mot de passe ne respecte pas les règles de sécurité'))
        }
      }

      callback();
      _this.oldPassword = value;
    };

    var validPwd2 = function validPwd2(rule, value, callback) {
      if (_this.formData.pwd2 !== _this.formData.pwd1) {
        callback(new Error('Le mot de passe de confirmation n\'est pas correct'));
      }

      callback();
    };

    var validEmail = function validEmail(rule, value, callback) {
      value = value || '';

      if (_this.action === 'edit' && _this.oldEmail === '') {
        _this.oldEmail = value;
        callback();
      } else {
        if (!validate.validEmail(value)) {
          callback(new Error('Adresses email non valide'));
        } else {
          if (_this.oldEmail !== _this.formData.email) {
            apiUser.getByEmail(value).then(function (results) {
              if (results.length > 0) {
                callback(new Error('Adresse email déjà utilisée'));
              }

              callback();
            });
          } else {
            callback();
          }
        }
      }
    };

    return {
      route: {
        list: 'UserList'
      },
      rules: {
        firstname: [{
          required: true,
          message: 'Prénom non valide'
        }],
        lastname: [{
          required: true,
          message: 'Nom non valide'
        }],
        email: [{
          required: true,
          validator: validEmail,
          trigger: ['change', 'blur']
        }],
        pwd1: [{
          validator: validPwd1,
          required: this.action === 'new',
          trigger: ['change', 'blur']
        }],
        pwd2: [{
          validator: validPwd2,
          required: this.action === 'new',
          trigger: ['change', 'blur']
        }],
        key_role: [{
          required: true,
          message: 'Champ obligatoire'
        }]
      },
      roles: [],
      oldEmail: '',
      oldPassword: '',
      passwordType: 'password'
    };
  },
  // 9. Évènements
  // --
  created: function created() {
    var _this2 = this;

    apiPermission.getAll().then(function (results) {
      _this2.roles = results;
    }).catch(function (error) {
      _this2.$message(error);
    });
  },
  // 10. Propriétés non réactives
  // --
  methods: {
    resetForm: function resetForm() {
      this.$refs.ElForm.resetFields();
    },
    validateForm: function validateForm() {
      var _this3 = this;

      this.passwordType = 'password';
      this.$refs.ElForm.validate(function (valid) {
        if (valid) {
          var user = {
            id_societe: _this3.formData.id_societe,
            // id_societe: this.$store.getters.user.id_societe,
            firstname: _this3.formData.firstname,
            lastname: _this3.formData.lastname.toUpperCase(),
            email: _this3.formData.email,
            enabled: Number(_this3.formData.enabled) || 0,
            key_role: _this3.formData.key_role
          };
          var newPassword = _this3.formData.pwd1 || '';

          if (newPassword !== '') {
            user.password = newPassword;
          }

          switch (_this3.action) {
            case 'new':
              _this3.actionNew(user);

              break;

            case 'edit':
              _this3.actionEdit(user);

              break;
          }
        }
      });
    },
    actionNew: function actionNew(user) {
      var _this4 = this;

      this.$loader(true);
      apiUser.insert(user).then(function (results) {
        _this4.$message('Création effectuée avec succès');

        _this4.resetForm();

        _this4.$loader(false);

        _this4.$router.push({
          name: _this4.route.list
        }); // this.sendEmail()

      }).catch(function (error) {
        _this4.$message(error);

        _this4.$loader(false);
      });
    },
    actionEdit: function actionEdit(user) {
      var _this5 = this;

      this.$loader(true);
      apiUser.update(this.formData.id, user).then(function (results) {
        _this5.formData.pwd1 = '';
        _this5.formData.pwd2 = '';
        _this5.oldPassword = _this5.formData.pwd1;
        _this5.oldEmail = _this5.formData.email;

        _this5.$message('Mise à jour effectuée avec succès');

        _this5.$loader(false);

        _this5.$router.push({
          name: _this5.route.list
        });
      }).catch(function (error) {
        _this5.$message(error);

        _this5.$loader(false);
      });
    },
    genPassword: function genPassword() {
      this.passwordType = 'password';
      var newPwd = (0, _utils.generatePassword)();
      this.formData.pwd1 = newPwd;
      this.formData.pwd2 = newPwd;
      this.passwordType = '';
      this.$refs.pwd1.focus();
    }
  } // 11. Rendu
  // --

};
exports.default = _default;