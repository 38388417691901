"use strict";

var _interopRequireDefault = require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.object.to-string");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/www/lacroix_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard"));

var routes = [{
  path: '/admin/user',
  name: 'AdminUser',
  order: 40,
  meta: {
    title: 'Gestion des utilisateurs',
    icon: 'users'
  },
  redirect: {
    name: 'UserList'
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/layout/empty'));
    });
  },
  children: [{
    path: '',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/admin/user/UserList'));
      });
    },
    name: 'UserList',
    meta: {
      title: 'Utilisateurs',
      activeMenu: '/admin/user'
    }
  }, {
    path: 'new',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/admin/user/UserNew'));
      });
    },
    name: 'UserNew',
    meta: {
      title: 'Ajouter un utilisateur',
      activeMenu: '/admin/user'
    }
  }, {
    path: 'edit/:id(\\d+)',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/admin/user/UserEdit'));
      });
    },
    name: 'UserEdit',
    meta: {
      title: 'Modifier un utilisateur',
      activeMenu: '/admin/user'
    }
  }, {
    path: 'manage-link/:id(\\d+)',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/admin/user/UserCrmLinkManager'));
      });
    },
    name: 'UserCrmLinkManager',
    meta: {
      title: 'Lier un utilisateur à la CRM',
      activeMenu: '/admin/user'
    }
  }]
}];
var _default = routes;
exports.default = _default;