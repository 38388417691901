var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-tree", {
        ref: "tree",
        staticClass: "permission-tree",
        attrs: {
          "check-strictly": _vm.checkStrictly,
          data: _vm.routesData,
          "default-checked-keys": _vm.acl && JSON.parse(_vm.acl),
          props: _vm.defaultProps,
          "default-expand-all": "",
          "highlight-current": "",
          "node-key": "path",
          "show-checkbox": ""
        },
        on: { check: _vm.handleCheck }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }