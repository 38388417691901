var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dataLoaded
    ? _c(
        "div",
        [
          _vm.$env.showAppDebug
            ? _c("div", { staticClass: "app-debug" }, [
                _vm._v("Document Index")
              ])
            : _vm._e(),
          _c("header-bloc", { attrs: { site: _vm.site } }),
          _c("document-infos", { attrs: { document: _vm.document } }),
          _c(
            "transition",
            { attrs: { name: "fade-transform", mode: "out-in" } },
            [_c("router-view", { key: "document-view-" + _vm.$route.path })],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }